import React, { useContext, useEffect, useRef, useState } from 'react';
import './Billprint30.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons'
import { lightGreen } from '@mui/material/colors';
import Globalcontext from '../../Context/Globalcontext';
import Cowsmall from "../../imgs/cowsmall.gif";
import Grass from "../../imgs/grass.png";
import { LanRounded } from '@mui/icons-material';

// Register the font using the imported variable
Font.register({
    family: 'Lato-Italic',
    src: LatoItalicFont,
});

const Billprint = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [customerData, setCustomerData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [loadedPages, setLoadedPages] = useState(1);


    const loadMorePages = () => {
        setIsLoading(true);
        const pagesToLoad = 1; // Change this to load more pages

        setTimeout(() => {
            setLoadedPages(loadedPages + pagesToLoad);
            setIsLoading(false);
        }, 1000);
    };

    useEffect(() => {
        //alert(localStorage.getItem("customerData"));
        setTimeout(() => {
            if (JSON.parse(localStorage.getItem("customerData"))) {
                setCustomerData(JSON.parse(localStorage.getItem("customerData")));
                // Set loading to false when data is ready
                setIsLoading(false);
            }
        }, 200);
    }, []);



    const pageDataLength =parseFloat(Object.keys(customerData).length) / 2;
    const minutes = Math.floor(pageDataLength / 60);
    const seconds = pageDataLength % 60;

    return (
        <div className="billprintmain">
            <PDFViewer className='pdf'>
                <Document>
                    {Object.keys(customerData).map((kno, index) => (
                        <PageTemplate key={index} data={customerData[kno]} index={index} kno={kno} min = {minutes} sec = {seconds} pages = {parseFloat(pageDataLength) * 2 }/>
                    ))}
                </Document>
            </PDFViewer>

        </div>
    );
};

function PageTemplate({ data, index, kno,min,sec,pages }) {
    const [kapat, setKapat] = useState({});

    const [isGenerating, setIsGenerating] = useState(true);

    const generatePageContent = async () => {
        // Simulate page generation
        await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the duration as per your page generation time
        setIsGenerating(false); // Set isGenerating to false when the page is ready
    };

    useEffect(() => {
        generatePageContent();
    }, []);


    const getDatesInRange = (date1, date2) => {
        const dates = [];
        const currentDate = new Date(date1);

        while (currentDate <= new Date(date2)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const getTableDataWithZeros = (tableData, date1, date2, type, time) => {
        if (!Array.isArray(tableData)) {
            return Array.from({ length: getDatesInRange(date1, date2).length }, (_, index) => ({
                sr: '',
                date: getDatesInRange(date1, date2)[index],
                time: time,
                kno: '',
                type: type,
                liter: '0',
                fat: '0',
                snf: '0',
                rate: '0',
                amt: '0.00',
                date1: date1,
                date2: date2,
                kendra: '',
            }));
        }

        const dateRange = getDatesInRange(date1, date2);
        const tableWithData = [];

        dateRange.forEach(date => {
            const matchingEntry = tableData.find(
                entry => entry.date === date && entry.type === type && entry.time === time
            );

            if (matchingEntry) {
                tableWithData.push(matchingEntry);
            } else {
                // Create a record with zero values
                tableWithData.push({
                    sr: '',
                    date: date,
                    time: time,
                    kno: '',
                    type: type,
                    liter: '0',
                    fat: '0',
                    snf: '0',
                    rate: '0',
                    amt: '0.00',
                    date1: date1,
                    date2: date2,
                    kendra: '',
                });
            }
        });

        return tableWithData;
    };


    const styles = StyleSheet.create({
        page: {
            paddingTop: 10,
            flexDirection: 'row',
            backgroundColor: '#FFFFFF',
            justifyContent: 'center', // Center horizontally
            marginLeft: 14, // Increase the left margin
            marginRight: 13

        },
        pageWithMargin: {
            backgroundColor: "lightblue"
        },
        header: {
            position: 'absolute',
            top: 0, // Adjust this value to change the vertical position
            left: 0,
            right: 0,
            textAlign: 'center',
        },
        loading: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            marginTop: 160
           
        },
        tableContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',
            justifyContent: 'space-between',
        },
        table: {
            display: 'table',
            width: 'auto', // Adjust as needed to fit four tables in a row
            margin: 5,
            marginLeft: -1,
        },
        tableone: {
            display: 'table',
            width: '18%', // Adjust as needed to fit four tables in a row
            margin: 5,
        },
        tableCellone: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: 3,
            flex: 1, // Remove width and use flex to distribute space equally
        },
        tableRowone: {
            flexDirection: 'row',
            width: '65%',
            height: 17,
            textAlign: 'right',
            marginLeft: -5,
        },
        tableHeaderone: {
            flexDirection: 'row',
            width: '65%',
            height: 17,
            textAlign: 'right',
            border: '0.5px solid #000',
            marginLeft: -5,
            backgroundColor: 'lightgray'
        },
        allbill: {
            flexDirection: 'row',
            width: '65%',
            textAlign: 'right',
            height: 17,
            marginLeft: -5,
            backgroundColor: 'lightgray',
            marginTop: 5
        },
        tableRow: {
            flexDirection: 'row',
            height: 17,
            marginLeft: 3,
        },
        tableRow2: {
            flexDirection: 'row',
            height: 17,
            marginLeft: 3,
            marginTop: 5,
            backgroundColor: 'lightgray'
        },
        line: {
            flexDirection: 'row',
            backgroundColor: 'lightgray',
            marginTop: 2,
            height: 1,
            width: '95vw',
            marginBottom: 2,
        },
        tableHeader: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '5mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadersm: {
            border: '0.5px solid #000',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '11mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadermed: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '13mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableHeadermed2: {
            border: '0.5px solid #000',
            padding: 0.5,
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '19mm', // Set the width of the individual cell
            backgroundColor: 'lightgray',
        },
        tableCell: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '1mm', // Set the width of the individual cell

        },
        tableCellsm: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'center',
            width: '11mm', // Set the width of the individual cell

        },
        tableCellmed: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '1mm',
            width: '13mm', // Set the width of the individual cell
        },
        tableCellmed2: {
            border: '0.5px solid lightgray',
            fontSize: 8,
            fontFamily: 'Lato-Italic',
            textAlign: 'right',
            paddingRight: '1mm',
            width: '19mm', // Set the width of the individual cell
        },
        latoText: {
            fontFamily: 'Lato-Italic',
            fontSize: 15,
            marginBottom: 5,
            textAlign: 'center',
            width: '100%',
            fontWeight: 'bold',
            marginLeft: -15,
            marginTop: 1,
        },
        headtextsmall: {
            fontFamily: 'Lato-Italic',
            fontSize: 10,
            fontWeight: 'bold',
        },
        headtextsmall2: {
            fontFamily: 'Lato-Italic',
            fontSize: 14,
            marginLeft: 20,
            marginTop: -5,
            fontWeight: 'bold',
        },
        headtextsmall3: {
            fontFamily: 'Lato-Italic',
            fontSize: 14,
            color: 'rgb(156, 44, 24)',
            fontWeight: 'bold',
            alignSelf: 'center'
        },
        headtextsmall4: {
            fontFamily: 'Lato-Italic',
            fontSize: 10,
            color: 'rgb(156, 44, 24)',
            fontWeight: 'bold',
            alignSelf: 'center'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
        },
        row1: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 5
        },
        row2: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 140,
            paddingRight: 140,
            backgroundColor: 'lightgray',
        },
        column1: {
            display: 'flex',
            flexDirection: 'column',
            width: '23%',
            justifyContent: 'start',
            alignItems: 'flex-start', // Align text to the left
            paddingLeft: 5,
            paddingRight: 5,
            marginLeft: 20,
        },
        column2: {
            display: 'flex',
            flexDirection: 'column',
            width: '15%',
            justifyContent: 'start',
            alignItems: 'flex-start', // Align text to the left
            paddingLeft: 5,
            paddingRight: 5,


        },
        column3: {
            display: 'flex',
            flexDirection: 'column',
            width: '17%',
            justifyContent: 'start',
            alignItems: 'flex-start', // Align text to the left
            paddingLeft: 1,
            paddingRight: 5,
            marginLeft: 7,
        },
        column1Row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        simplerow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%',
        },
        simplecol: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: -1
        },
        column1Rowgray: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: 6,
            marginTop: 5,
            border: '0.5px solid black',
            backgroundColor: 'lightgray'
        },
        column1Label: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'left', // Align labels to the left
        },
        column1Value: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'right', // Align values to the right
        },
        pageBreak: {
            pageBreakBefore: 'always',
        },
        totalsRow: {
            flexDirection: 'row',
            borderTop: '1px solid #000',
            paddingTop: 5,
            paddingLeft: 2,
        },
        totalsCell: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            flex: 1,
            fontSize: 8,
            width: 2,
            textAlign: 'center',
        },
        imagerow: {
            display: 'flex',
            flexDirection: 'row',
            width: '95vw',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: -8
        },
        image: {
            width: 70, // Adjust the width as needed
            height: "auto", // Maintain aspect ratio
            marginLeft: -15,
            marginTop: 5

        },
        imgtext: {
            fontFamily: 'Lato-Italic',
            fontSize: 9,
            fontWeight: 'bold',
            textAlign: 'center', // Align labels to the left
        },
        tableCellgreen: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            flex: 1,
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'Lato-Italic',
            color: 'green',
            width: 2,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        tableCellred: {
            border: '0.5px solid lightgray',
            padding: 0.5,
            flex: 1,
            fontSize: 12,
            fontWeight: 'bold',
            fontFamily: 'Lato-Italic',
            color: 'red',
            width: 3,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        loadingSpinner: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 16,
            fontWeight: 'bold',
            color: 'gray',
        },
    });

    const knoData = data;
    const groupByTypeAndTime = {};

    // Group data by 'type' and 'time'
    knoData.forEach(entry => {
        const key = entry.type + '-' + entry.time;
        if (!groupByTypeAndTime[key]) {
            groupByTypeAndTime[key] = [];
        }
        groupByTypeAndTime[key].push(entry);
    });

    const tableDataArrays = ['म्हैस-सकाळ', 'म्हैस-संध्याकाळ', 'गाय-सकाळ', 'गाय-संध्याकाळ'].map((typeTimeKey) => {
        const [type, time] = typeTimeKey.split('-');
        const entries = groupByTypeAndTime[typeTimeKey]; // You should define 'groupByTypeAndTime' based on your data structure.
        return getTableDataWithZeros(entries, knoData[0].date1, knoData[0].date2, type, time);
    });

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(inputDate).toLocaleDateString('en-US', options);
    }


    const calculateSumOfAmtColumns = (tableDataArray) => {
        const sums = Array.from({ length: tableDataArray[0].length }, () => 0); // Initialize an array with zeros based on the length of the first table

        // Loop through the tables and calculate sums for each record
        tableDataArray.forEach((tableDataWithZeros) => {
            tableDataWithZeros.forEach((entry, entryIndex) => {
                sums[entryIndex] += parseFloat(entry.amt);
            });
        });

        return sums;
    };


    const sumsOfAmtColumns = calculateSumOfAmtColumns(tableDataArrays);
    //  console.log("sumsOfAmtColumns: ", sumsOfAmtColumns);
    const sumOfFifthTable = sumsOfAmtColumns.reduce((total, sum) => total + parseFloat(sum), 0).toFixed(2);
    let totalOfTotalLiter = 0;
    let totalOfTotalFat = 0;
    let tkapat = 0;
    let ada = 0;

    return (
        <>
            <Page
                key={index}
                size="A4"
                style={styles.page}
            >
                {isGenerating ? (
                    <View style={styles.loading}>
                        <Text style={styles.headtextsmall3}>मासिक बिल तयार होत आहे, {`अंदाजे वेळ: ${min} मिनिटे व ${sec} सेकंड्स`}</Text>
                        <Text style={styles.headtextsmall4}>एकूण बिल पाने : {pages} </Text>
                    </View>
                ) : (
                    <View>
                        <View style={styles.row1}>
                            <Text style={styles.headtextsmall}>{"खाते:: " + kno + " (" + knoData[0].cname + ")"}</Text>
                            <Text style={styles.headtextsmall2}>{knoData[0].kendra}</Text>
                            <Text style={styles.headtextsmall}>{"कालावधी : " + formatDate(knoData[0].date1) + "   ते   " + formatDate(knoData[0].date2)}</Text>
                        </View>
                        <View style={styles.row2}>
                            <Text style={styles.headtextsmall}>{"म्हैस"}</Text>
                            <Text style={styles.headtextsmall}>{"गाय"}</Text>
                        </View>

                        <View style={styles.tableContainer}>

                            {['म्हैस-सकाळ', 'म्हैस-संध्याकाळ', 'गाय-सकाळ', 'गाय-संध्याकाळ'].map((typeTimeKey, tableIndex) => {
                                const [type, time] = typeTimeKey.split('-'); // Split type and time
                                const entries = groupByTypeAndTime[typeTimeKey];
                                const tableDataWithZeros = getTableDataWithZeros(entries, knoData[0].date1, knoData[0].date2, type, time);

                                // Calculate totals
                                const totalLiter = tableDataWithZeros.reduce((total, entry) => total + parseFloat(entry.liter), 0);
                                totalOfTotalLiter += totalLiter;

                                const totalFat = tableDataWithZeros
                                    .filter(entry => entry.fat > 0) // Filter entries where fat is greater than 0
                                    .reduce((total, entry) => total + parseFloat(entry.fat), 0); // Sum up the fat values

                                const totalEntriesWithFat = tableDataWithZeros
                                    .filter(entry => entry.fat > 0) // Filter entries where fat is greater than 0
                                    .length;

                                const avgfat = totalFat / totalEntriesWithFat;


                                const totalSNF = tableDataWithZeros
                                    .filter(entry => entry.fat > 0) // Filter entries where fat is greater than 0
                                    .reduce((total, entry) => total + parseFloat(entry.snf), 0); // Sum up the fat values

                                const totalEntriesWithSNF = tableDataWithZeros
                                    .filter(entry => entry.snf > 0) // Filter entries where fat is greater than 0
                                    .length;

                                const avgsnf = totalSNF / totalEntriesWithSNF;


                                tkapat = parseFloat(knoData[0].cthev !== null && !isNaN(knoData[0].cthev) ? knoData[0].cthev : 0) + parseFloat(knoData[0].advkapat !== null && !isNaN(knoData[0].advkapat) ? knoData[0].advkapat : 0) + parseFloat(knoData[0].khadyakapat !== null && !isNaN(knoData[0].khadyakapat) ? knoData[0].khadyakapat : 0) + parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(knoData[0].ikapatliter) : 0) + parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0) + parseFloat(knoData[0].vah !== null && !isNaN(knoData[0].vah) ? (parseFloat(knoData[0].vah)) : 0)
                                ada = parseFloat(parseFloat(sumOfFifthTable) - parseFloat(tkapat)).toFixed(2);

                                const totalAmt = tableDataWithZeros.reduce((total, entry) => total + parseFloat(entry.amt), 0);

                                return (
                                    <View key={typeTimeKey} style={styles.table}>
                                        {/* Table Header */}
                                        <View style={styles.tableRow}>
                                            {tableIndex === 0 ? ( // Render "दिनांक" only for the first table
                                                <Text style={[styles.tableHeader, { width: 38 }]}>{"दिनांक"}</Text>
                                            ) : null}
                                            <Text style={styles.tableHeadersm}>{"लिटर"}</Text>
                                            <Text style={styles.tableHeadersm}>{"फॅट"}</Text>
                                            <Text style={styles.tableHeadersm}>{"SNF"}</Text>
                                            <Text style={styles.tableHeadermed}>{"दर"}</Text>
                                            <Text style={styles.tableHeadermed2}>{"रक्कम"}</Text>
                                        </View>
                                        {/* Table Data */}
                                        {tableDataWithZeros.map((entry, entryIndex) => (
                                            <View key={entryIndex} style={styles.tableRow}>
                                                {tableIndex === 0 ? ( // Render "दिनांक" only for the first table
                                                    <Text style={[styles.tableCell, { width: 35 }]}>
                                                        {entry.date.substr(5).split('-').reverse().join('/')}
                                                    </Text>
                                                ) : null}
                                                <Text style={styles.tableCellsm}>{entry.liter}</Text>
                                                <Text style={styles.tableCellsm}>{entry.fat}</Text>
                                                <Text style={styles.tableCellsm}>{entry.snf}</Text>
                                                <Text style={styles.tableCellmed}>{parseFloat(entry.rate).toFixed(2)}</Text>
                                                <Text style={styles.tableCellmed2}>{parseFloat(entry.amt).toFixed(2)}</Text>
                                            </View>
                                        ))}

                                        {/* Totals Row */}
                                        <View style={styles.tableRow2}>
                                            {tableIndex === 0 ? ( // Render "दिनांक" only for the first table
                                                <Text style={[styles.tableCell, { width: 35 }]}>एकूण</Text>
                                            ) : null}
                                            <Text style={styles.tableCellsm}>{totalLiter.toFixed(2)}</Text>
                                            <Text style={styles.tableCellsm}>{avgfat ? avgfat.toFixed(1) : "0.0"}</Text>
                                            <Text style={styles.tableCellsm}>{avgsnf ? avgsnf.toFixed(1) : "0.0"}</Text>
                                            <Text style={styles.tableCellmed}></Text>
                                            <Text style={styles.tableCellmed2}>{totalAmt.toFixed(2)}</Text>

                                        </View>
                                    </View>
                                );
                            })}

                            <View style={styles.tableone}>
                                <View style={styles.tableHeaderone}>

                                    <Text style={styles.tableCellone}>{"रक्कम"}</Text>
                                </View>
                                {sumsOfAmtColumns.map((sum, entryIndex) => (

                                    <View key={entryIndex} style={styles.tableRowone}>
                                        <Text style={styles.tableCellone}>{sum.toFixed(2)}</Text>
                                    </View>
                                ))}

                                <View style={styles.allbill}>
                                    <Text style={styles.tableCellone}>{sumOfFifthTable}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.line} />


                        <View style={styles.row}>
                            <View style={styles.column2}>
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"मागील ठेव :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].mthev !== null && !isNaN(knoData[0].mthev) ? knoData[0].mthev : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"मागील ठेव :"}</Text>
                                        <Text style={styles.column1Value}>{parseFloat(localStorage.getItem("bcount")) > 0 ? knoData[0].newthev !== null && !isNaN(knoData[0].newthev) ? knoData[0].newthev : 0 : knoData[0].othev !== null && !isNaN(knoData[0].othev) ? knoData[0].othev : 0}</Text>
                                    </View>
                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"ठेव कपात:"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].cthev !== null && !isNaN(knoData[0].cthev) ? knoData[0].cthev : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"ठेव कपात:"}</Text>
                                        <Text style={styles.column1Value}>{parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev)).toFixed(2)}</Text>
                                    </View>
                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण ठेव :"}</Text>
                                        <Text style={styles.column1Value}>
                                            {(
                                                (!isNaN(parseFloat(knoData[0].newthev)) ? parseFloat(knoData[0].newthev) : 0)
                                            ).toFixed(2)}
                                        </Text>

                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण ठेव :"}</Text>
                                        <Text style={styles.column1Value}>
                                            {(
                                                parseFloat(localStorage.getItem("bcount")) > 0 ?
                                                    parseFloat(knoData[0].newthev !== null && !isNaN(knoData[0].newthev) ? knoData[0].newthev : 0) + parseFloat(parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev)).toFixed(2))
                                                    :
                                                    parseFloat(knoData[0].othev !== null && !isNaN(knoData[0].othev) ? knoData[0].othev : 0) + parseFloat(parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev)).toFixed(2))
                                            ).toFixed(2)}
                                        </Text>

                                    </View>
                                }
                            </View>

                            <View style={styles.column1}>
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"मागील एड्व्हांस बाकी :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].madv !== null && !isNaN(knoData[0].madv) ? parseFloat(knoData[0].madv).toFixed(2) : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"मागील एड्व्हांस बाकी :"}</Text>
                                        <Text style={styles.column1Value}>{parseFloat(localStorage.getItem("bcount")) > 0 ? knoData[0].madv !== null && !isNaN(knoData[0].madv) ? knoData[0].madv : 0 : knoData[0].obal !== null && !isNaN(knoData[0].obal) ? knoData[0].obal : 0}</Text>
                                    </View>
                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एड्व्हांस चालू :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0}</Text>
                                    </View> :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एड्व्हांस चालू N:"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0}</Text>
                                    </View>
                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एड्व्हांस कपात :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].advkapat !== null && !isNaN(knoData[0].advkapat) ? parseFloat(knoData[0].advkapat).toFixed(2) : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एड्व्हांस कपात :"}</Text>
                                        <Text style={styles.column1Value}> {kapat[kno] && !isNaN(kapat[kno].cuchal) ? parseFloat(kapat[kno].cuchal).toFixed(2) : 0}</Text>
                                    </View>

                                }
                                {localStorage.getItem("billtype") === "oldbill" ?

                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"नवीन एड्व्हांस बाकी :"}</Text>
                                        <Text style={styles.column1Value}>
                                            {(
                                                (parseFloat(knoData[0].newadv) || 0)
                                            ).toFixed(2)}
                                        </Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"नवीन एड्व्हांस बाकी N :"}</Text>
                                        <Text style={styles.column1Value}>
                                            {(
                                                parseFloat(localStorage.getItem("bcount")) > 0 ?
                                                    (parseFloat(knoData[0].madv !== null && !isNaN(knoData[0].madv) ? knoData[0].madv : 0) + parseFloat(knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0) - parseFloat(kapat[kno] && !isNaN(kapat[kno].cuchal) ? kapat[kno].cuchal : 0))
                                                    :
                                                    (parseFloat(knoData[0].obal !== null && !isNaN(knoData[0].obal) ? knoData[0].obal : 0) + parseFloat(knoData[0].cadv !== null && !isNaN(knoData[0].cadv) ? knoData[0].cadv : 0) - parseFloat(kapat[kno] && !isNaN(kapat[kno].cuchal) ? kapat[kno].cuchal : 0))
                                            ).toFixed(2)}
                                        </Text>
                                    </View>
                                }
                            </View>

                            <View style={styles.column1}>
                                <View style={styles.column1Row}>
                                    <Text style={styles.column1Label}>{"मागील खाद्य बाकी :"}</Text>
                                    <Text style={styles.column1Value}>{knoData[0].mkhadya !== null && !isNaN(knoData[0].mkhadya) ? knoData[0].mkhadya : 0}</Text>
                                </View>
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"खाद्य चालू :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"खाद्य चालू N:"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0}</Text>
                                    </View>
                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"खाद्य कपात :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].khadyakapat !== null && !isNaN(knoData[0].khadyakapat) ? parseFloat(knoData[0].khadyakapat).toFixed(2) : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"खाद्य कपात N:"}</Text>
                                        <Text style={styles.column1Value}> {kapat[kno] && !isNaN(kapat[kno].ckhadya) ? parseFloat(kapat[kno].ckhadya).toFixed(2) : 0}</Text>
                                    </View>

                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"नवीन खाद्य बाकी :"}</Text>
                                        <Text style={styles.column1Value}>
                                            {(
                                                (parseFloat(knoData[0].newkhadya) || 0)
                                            ).toFixed(2)}
                                        </Text>
                                    </View>
                                    :

                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"नवीन खाद्य बाकी N:"}</Text>
                                        <Text style={styles.column1Value}>
                                            {(
                                                (parseFloat(knoData[0].mkhadya !== null && !isNaN(knoData[0].mkhadya) ? knoData[0].mkhadya : 0) + parseFloat(knoData[0].ckhadya !== null && !isNaN(knoData[0].ckhadya) ? knoData[0].ckhadya : 0) - parseFloat(kapat[kno] && !isNaN(kapat[kno].ckhadya) ? kapat[kno].ckhadya : 0))
                                            ).toFixed(2)}
                                        </Text>
                                    </View>
                                }
                            </View>

                            <View style={styles.column3}>
                                <View style={styles.column1Row}>
                                    <Text style={styles.column1Label}>{"एकूण लिटर :"}</Text>
                                    <Text style={styles.column1Value}>{totalOfTotalLiter.toFixed(2)}</Text>
                                </View>
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"इ.कपात/लि :"} </Text>
                                        <Text style={styles.column1Value}>{knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(knoData[0].ikapatliter) : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"इ.कपात/लि N:"} </Text>
                                        <Text style={styles.column1Value}>{knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(parseFloat(knoData[0].ikapatliter) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0}</Text>
                                    </View>
                                }
                                <View style={styles.column1Row}>
                                    <Text style={styles.column1Label}>{"इ.कपात/बील :"}</Text>
                                    <Text style={styles.column1Value}>{knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0}</Text>
                                </View>
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"वाहतूक/लि :"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].vah !== null && !isNaN(knoData[0].vah) ? (parseFloat(knoData[0].vah)) : 0}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"वाहतूक/लि N:"}</Text>
                                        <Text style={styles.column1Value}>{knoData[0].mhvah !== null && !isNaN(knoData[0].mhvah) ? parseFloat(parseFloat(knoData[0].mhvah) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0}</Text>
                                    </View>
                                }
                            </View>

                            <View style={styles.column3}>
                                <View style={styles.column1Row}>
                                    <Text style={styles.column1Label}>{"एकूण बील :"}</Text>
                                    <Text style={styles.column1Value}>{sumOfFifthTable}</Text>
                                </View>
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण कपात :"}</Text>
                                        <Text style={styles.column1Value}>{parseFloat(tkapat).toFixed(2)}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Row}>
                                        <Text style={styles.column1Label}>{"एकूण कपात N :"}</Text>
                                        <Text style={styles.column1Value}>{parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev) + parseFloat(kapat[kno] && !isNaN(kapat[kno].cuchal) ? kapat[kno].cuchal : 0) + parseFloat(kapat[kno] && !isNaN(kapat[kno].ckhadya) ? kapat[kno].ckhadya : 0) + parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(parseFloat(knoData[0].ikapatliter) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0) + parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0) + parseFloat(knoData[0].mhvah !== null && !isNaN(knoData[0].mhvah) ? parseFloat(parseFloat(knoData[0].mhvah) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0)).toFixed(2)}</Text>
                                    </View>
                                }
                                {localStorage.getItem("billtype") === "oldbill" ?
                                    <View style={styles.column1Rowgray}>
                                        <Text style={styles.column1Label}>{"अदा ₹:"}</Text>
                                        <Text style={parseFloat(ada) >= 0 ? styles.tableCellgreen : styles.tableCellred}>{ada}</Text>
                                    </View>
                                    :
                                    <View style={styles.column1Rowgray}>
                                        <Text style={styles.column1Label}>{"अदा ₹: N"}</Text>
                                        <Text style={parseFloat(ada) >= 0 ? styles.tableCellgreen : styles.tableCellred}>{parseFloat(parseFloat(sumOfFifthTable) - parseFloat(parseFloat(knoData[0].mhliter * knoData[0].mhthev) + parseFloat(knoData[0].gayliter * knoData[0].gaythev) + parseFloat(kapat[kno] && !isNaN(kapat[kno].cuchal) ? kapat[kno].cuchal : 0) + parseFloat(kapat[kno] && !isNaN(kapat[kno].ckhadya) ? kapat[kno].ckhadya : 0) + parseFloat(knoData[0].ikapatliter !== null && !isNaN(knoData[0].ikapatliter) ? parseFloat(parseFloat(knoData[0].ikapatliter) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0) + parseFloat(knoData[0].ikapatbill !== null && !isNaN(knoData[0].ikapatbill) ? parseFloat(knoData[0].ikapatbill).toFixed(2) : 0) + parseFloat(knoData[0].mhvah !== null && !isNaN(knoData[0].mhvah) ? parseFloat(parseFloat(knoData[0].mhvah) * parseFloat(totalOfTotalLiter)).toFixed(2) : 0)).toFixed(2)).toFixed(2)}</Text>
                                    </View>
                                }

                            </View>
                        </View>
                        <View style={styles.line} />

                        <View style={styles.simplecol}>
                            <Text style={styles.imgtext}>{"नमस्कार " + knoData[0].cname + ", आता आपण आपल्या खात्याचा संपूर्ण हिशोब आपल्या मोबाईल वर पाहू शकता, आताच आपल्या डेअरी चे ऍप डाउनलोड करून घ्या!"}</Text>
                        </View>
                        <View style={styles.line} />
                        <View style={styles.imagerow}>
                            <View style={styles.simplerow}>
                                <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                <Image style={styles.image} src={Logo} />
                            </View>
                            <Text style={styles.imgtext}>{"सॉफ्टवेअर खरेदीसाठी संपर्क : 800-788-9200"}</Text>
                        </View>

                        {index < Object.keys(data).length - 1 && <Text style={styles.pageBreak} />}

                    </View>)}
            </Page>

        </>
    );

}

export default Billprint;
