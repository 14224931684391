import React, { useContext, useEffect, useRef, useState } from 'react'
import './Khadyaadvreport.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Language, YoutubeSearchedForOutlined } from '@material-ui/icons';
import khadya from "../../imgs/khadya.png";
import cash from "../../imgs/cash.png";
import { UilEditAlt, UilEye, UilSetting } from '@iconscout/react-unicons'



const Khadyaadvreport = (props) => {
    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), kno: "", kname: "", lastkhadya: "", lastadv: "", lastdate: "", dno: "", name: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [DataSourcekh, setDataSourcekh] = useState([]);
    const [DataSourceadv, setDataSourceadv] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const [partyData, setPartydata] = useState([]);
    const [PreviousAmt, setPreviousAmt] = useState();
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const [khrtype, setKhrtype] = useState("khpur");
    const navigate = useNavigate();
    const refprint = useRef(null);
    const refprintclose = useRef(null);
    const [mobileusers, setMobileusers] = useState([]);
    const [wait, setWait] = useState(false);

    const handlertype = (type) => {
        setKhrtype(type)
    }

    useEffect(() => {
        setDataSource([]);
    }, [khrtype])

    const handleprintclose = () => {
        refprintclose.current.click();
    }



    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    const filteData = (e) => {
        const searchValue = e.target.value.trim();

        if (searchValue !== "") {
            setValue(searchValue);
            const filtertable = DataSource.filter(o =>
                String(o.kno) === searchValue // Check for exact match with kno
            );

            setTableFilter([...filtertable]);
        } else {
            setValue(searchValue);
            setTableFilter([...DataSource]);
        }

    };

    const filteDataold = (e) => {
        if (e.target.value != "") {
            setValue(e.target.value);
            const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
            ));

            setTableFilter([...filtertable]);
        }
        else {
            setValue(e.target.value);
            setTableFilter([...DataSource]);
        }
    }
    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
            localStorage.setItem("dno", input.dno);
            localStorage.setItem("partyname", input.name);
            localStorage.setItem("khrtype", khrtype);
            const url = "/khadyaadvreportprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे संकलन उपलब्ध नाही!");
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date2') {
            // Check if date2 is more than one year ahead of date1
            const date1 = new Date(input.date1);
            const date2 = new Date(valuecurrent);

            // Calculate the difference in days between date1 and date2
            const differenceInDays = (date2 - date1) / (1000 * 60 * 60 * 24);

            if (differenceInDays <= 30) {
                setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
            } else {
                notifyerror("रिपोर्ट लिमिट - 1 महिना");
                setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
            }
        }
    }

    const getkhpurreport = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";
        // console.log(khrtype);
        if (khrtype === "khpur") {
            landingphp = "getkhpurreport.php";
        }
        else if (khrtype === "khsale") {
            landingphp = "getkhsalereport.php";
        }
        else if (khrtype === "adv") {
            landingphp = "getadvreport.php";
        }
        else if (khrtype === "udhari") {
            landingphp = "getudhari.php";
        }
        else if (khrtype === "stock") {
            landingphp = "getkhstock.php";
        }
        else if (khrtype === "dreport") {
            landingphp = "getdreport.php";
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: input.dno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            console.log(response.data)
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);

                if (khrtype === "dreport") {

                    setPreviousAmt(response.data[0].
                        previousamt
                    );
                }

                setWait(false);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }


    const getudharidetails = () => {

    }

    function formatDate(inputDate) {
        // Ensure inputDate is in 'yyyy-mm-dd' format
        if (inputDate) {
            const parts = inputDate.split('-');
            if (parts.length !== 3) {
                return ''; // Invalid date format
            }

            // Convert month number to month name in English
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            const year = parts[0];
            const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
            const day = parts[2];

            return `${day}-${months[month]}`;
            // -${year}`;

        }
    }

    function formatDatefull(inputDate) {
        // Ensure inputDate is in 'yyyy-mm-dd' format
        if (inputDate) {
            const parts = inputDate.split('-');
            if (parts.length !== 3) {
                return ''; // Invalid date format
            }

            // Convert month number to month name in English
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            const year = parts[0];
            const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
            const day = parts[2];

            return `${day}-${months[month]} -${year}`;


        }
    }

    const showDetails = (kno, cname, lastadv, lastkhadya, lastdate) => {

        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";

        landingphp = "getudharidetails.php";

        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                refprint.current.click();
                response.data && response.data.map((user, index) =>
                    setInput((prevInputSettings) => ({
                        ...prevInputSettings,
                        kno: kno,
                        kname: cname,
                        lastadv: user.lastadv,
                        lastkhadya: user.lastkhadya,
                        lastdate: lastdate
                    }))
                )
                setWait(false);
                getkhsalelatest(kno);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    const getkhsalelatest = (kno) => {

        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";

        landingphp = "getkhsalelatest.php";

        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                setDataSourcekh(response.data);
                setWait(false);
            }
            else {

                setDataSourcekh([]);
                setWait(false);
            }
            getadvlatest(kno);
        }).catch(err => {

            setWait(false);
        });
    }

    const getadvlatest = (kno) => {

        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let landingphp = "";

        landingphp = "getadvlatest.php";

        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            billtype: localStorage.getItem("bill10type")
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${landingphp}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data && response.data.length > 0) {
                console.log(response.data);
                setDataSourceadv(response.data);
                setWait(false);
            }
            else {

                setDataSourceadv([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    useEffect(() => {
        getkhpartylist();
    }, [])

    async function getkhpartylist(flag) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhpartylist.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                setPartydata(response.data);
                localStorage.setItem("dlist", JSON.stringify(response.data));
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (input.dno === "") {
            setInput(prevInput => ({
                ...prevInput,
                name: "",
            }))
        }
        else {

            let custlisttemp = partyData;
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.dno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.name,
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.dno])

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modaltitle" id="exampleModalLabel"> {input.kname + " (खाते क्रमांक " + input.kno + ") यांच्या डिटेल्स.."}</h5>
                            <div className="modalverti">

                                <span className='subheadsank'>-------------</span>
                                <span className='subheadsank'>1. शेवटचे बिल {formatDatefull(input.lastdate)} </span>
                                <div className='modalhori'>
                                    <div className='khadyabox'>
                                        <img className='back' src={cash} alt="" />
                                        <div className='interbox'>
                                            <span className='labelkh'>
                                                {formatDatefull(input.lastdate)} अखेर एड्व्हांस
                                            </span>
                                            <span className='redlable'>₹ {input.lastadv}</span>
                                        </div>
                                    </div>

                                    <div className='khadyabox'>
                                        <img className='back' src={khadya} alt="" />
                                        <div className='interbox'>
                                            <span className='labelkh'>
                                                {formatDatefull(input.lastdate)} अखेर खाद्य
                                            </span>
                                            <span className='redlable'>₹ {input.lastkhadya}</span>
                                        </div>
                                    </div>
                                </div>

                                <span className='labelkh'>एड्व्हांस माहिती</span>

                                <table id="myTablebill" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className='thsmall' scope="col">दिनांक</th>
                                            <th className='thsmall' scope="col">प्रकार</th>
                                            <th className='thsmall' scope="col">रक्कम</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            DataSourceadv && DataSourceadv.map((user, index) =>
                                                <tr key={index}>
                                                    <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                    <td className='thsmallcustlarge'>{user.type}</td>
                                                    <td className='thsmallcust'>{parseFloat(user.amt).toFixed(2)}</td>
                                                </tr>
                                            )
                                        }

                                    </tbody>

                                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                        <td className='thsmallcust'>एकूण</td>
                                        <td className='thsmallcustlarge'>-</td>
                                        <td className='thsmallcustlarge'>
                                            {
                                                DataSourceadv.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)
                                            }
                                        </td>

                                    </tr>
                                </table>

                                <span className='labelkh'>खाद्य माहिती</span>

                                <table id="myTablebill" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className='thsmall' scope="col">दिनांक</th>
                                            <th className='thsmall' scope="col">खाद्य नाव</th>
                                            <th className='thsmall' scope="col">नग</th>
                                            <th className='thsmall' scope="col">दर</th>
                                            <th className='thsmall' scope="col">रक्कम</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            DataSourcekh && DataSourcekh.map((user, index) =>
                                                <tr key={index}>
                                                    <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                    <td className='thsmallcustlarge'>{user.khname}</td>
                                                    <td className='thsmallcust'>{parseFloat(user.quin).toFixed(2)}</td>
                                                    <td className='thsmallcust'>{parseFloat(user.rate).toFixed(2)}</td>
                                                    <td className='thsmallcust'>{parseFloat(user.amt).toFixed(2)}</td>
                                                </tr>
                                            )
                                        }

                                    </tbody>

                                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                        <td className='thsmallcust'>एकूण</td>
                                        <td className='thsmallcustlarge'>-</td>
                                        <td className='thsmallcustlarge'>
                                            {
                                                DataSourcekh.reduce((total, user) => total + parseFloat(user.quin), 0).toFixed(2)
                                            }
                                        </td>
                                        <td className='thsmallcustlarge'>-</td>
                                        <td className='thsmallcust'>
                                            {
                                                DataSourcekh.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)
                                            }
                                        </td>
                                    </tr>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmain">
                        <div className="firstrow2">
                            <div className="rowbill">

                                {
                                    khrtype === "udhari" || khrtype === "stock" ?
                                        ''
                                        :
                                        <>
                                            <div className="mb-2">
                                                <span className='subheadsank'>दिनांक</span>
                                                <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                            </div>

                                            <span className='subheadbill'>ते</span>

                                            <div className="mb-2">
                                                <span className='subheadsank'>दिनांक</span>
                                                <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                            </div>
                                        </>
                                }

                                {
                                    khrtype === "dreport" ?

                                        <>
                                            <div className='khreportrow'>
                                                <div className="mb-2">
                                                    <span className='subheadsank'>डिलर क्रमांक</span>
                                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="number" className={"form-control"} autoComplete='off' id="dno" name="dno" value={input.dno} onChange={handleChange} ></input>
                                                </div>
                                                <div className="mb-2 kdr">
                                                    <span className='subheadsank'>खाद्य डिलर चे नाव</span>
                                                    <input type="text" disabled className={"form-control"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                                                </div>
                                            </div>
                                        </>

                                        :
                                        ''
                                }

                                <div className="mb-2">
                                    {
                                        wait ?
                                            <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">लोडिंग..</button>
                                            :
                                            <button onClick={getkhpurreport} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>

                                    }
                                    <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="firstrow2">
                    <div class="table-containerbill">
                        <div className="buttonsreportsank">
                            <input type="text" className="form-control searchsank mx-3" id="searchsank" name="searchsank" placeholder={khrtype === "khpur" ? 'शोधा' : 'खाते क्रमांक शोधा'} value={value} onChange={khrtype === "khpur" ? filteDataold : filteData} autoComplete='off' />
                            <div className="radio">
                                <div className="form-check">
                                    <input onClick={e => handlertype("khpur")} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                                    <label className="form-check-label" htmlFor="radio1">
                                        खाद्य खरेदी रिपोर्ट
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={e => handlertype("khsale")} className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                                    <label className="form-check-label" htmlFor="radio2">
                                        खाद्य विक्री रिपोर्ट
                                    </label>
                                </div>
                            </div>

                            <div className="radio">
                                <div className="form-check">
                                    <input onClick={e => handlertype("stock")} className="form-check-input" type="radio" name="exampleRadios" id="radio3" value="option3" />
                                    <label className="form-check-label" htmlFor="radio3">
                                        खाद्य स्टॉक
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={e => handlertype("dreport")} className="form-check-input" type="radio" name="exampleRadios" id="radio4" value="option3" />
                                    <label className="form-check-label" htmlFor="radio3">
                                        खाद्य डीलर खाते
                                    </label>
                                </div>
                            </div>

                            <div className="radio">
                                <div className="form-check">
                                    <input onClick={e => handlertype("adv")} className="form-check-input" type="radio" name="exampleRadios" id="radio5" value="option3" />
                                    <label className="form-check-label" htmlFor="radio3">
                                        एड्व्हांस रिपोर्ट
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={e => handlertype("udhari")} className="form-check-input" type="radio" name="exampleRadios" id="radio6" value="option3" />
                                    <label className="form-check-label" htmlFor="radio3">
                                        खाद्य/एड्व्हांस उधारी यादी
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            khrtype === "khpur" ?
                                <table id="myTablebill" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className='thsmall' scope="col">दिनांक</th>
                                            <th className='thsmall' scope="col">खाद्याचे नाव</th>
                                            <th className='thsmall' scope="col">नग</th>
                                            <th className='thsmall' scope="col">दर</th>
                                            <th className='thsmall' scope="col">रक्कम</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {value.length > 0 ? tableFilter.map((user, index) =>
                                            <tr key={index}>
                                                <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                <td className='thsmallcust'>{user.khname && user.khname !== "" ? user.khname : "-"}</td>
                                                <td className='thsmallcust'>{user.quin && user.quin !== "" ? parseFloat(user.quin) : 0}</td>
                                                <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                                                <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                                            </tr>
                                        )
                                            :
                                            DataSource && DataSource.map((user, index) =>
                                                <tr key={index}>
                                                    <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                    <td className='thsmallcust'>{user.khname && user.khname !== "" ? user.khname : "-"}</td>
                                                    <td className='thsmallcust'>{user.quin && user.quin !== "" ? parseFloat(user.quin) : 0}</td>
                                                    <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                                                    <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>


                                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                        <td className='thsmallcust'>एकूण</td>
                                        <td className='thsmallcustlarge'>-</td>
                                        <td className='thsmallcust'>
                                            {value.length > 0
                                                ? tableFilter.reduce((total, user) => total + parseFloat(user.quin), 0).toFixed(2)
                                                : DataSource.reduce((total, user) => total + parseFloat(user.quin), 0).toFixed(2)}
                                        </td>
                                        <td className='thsmallcust'>-</td>
                                        <td className='thsmallcust'>
                                            {value.length > 0
                                                ? tableFilter.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)
                                                : DataSource.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)}
                                        </td>
                                    </tr>
                                </table>
                                :
                                khrtype === "khsale" ?
                                    <table id="myTablebill" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className='thsmall' scope="col">दिनांक</th>
                                                <th className='thsmall' scope="col">खाते/नाव</th>
                                                <th className='thsmall' scope="col">खाद्याचे नाव</th>
                                                <th className='thsmall' scope="col">नग</th>
                                                <th className='thsmall' scope="col">दर</th>
                                                <th className='thsmall' scope="col">रक्कम</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {value.length > 0 ? tableFilter.map((user, index) =>
                                                <tr key={index}>
                                                    <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                    <td className='thsmallcustlarge'>{user.kno + ": " + user.cname}</td>
                                                    <td className='thsmallcust'>{user.khname && user.khname !== "" ? user.khname : "-"}</td>
                                                    <td className='thsmallcust'>{user.quin && user.quin !== "" ? parseFloat(user.quin) : 0}</td>
                                                    <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                                                    <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                                                </tr>
                                            )
                                                :
                                                DataSource && DataSource.map((user, index) =>
                                                    <tr key={index}>
                                                        <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                        <td className='thsmallcustlarge'>{user.kno + ": " + user.cname}</td>
                                                        <td className='thsmallcust'>{user.khname && user.khname !== "" ? user.khname : "-"}</td>
                                                        <td className='thsmallcust'>{user.quin && user.quin !== "" ? parseFloat(user.quin) : 0}</td>
                                                        <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                                                        <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                        <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                            <td className='thsmallcust'>एकूण</td>
                                            <td className='thsmallcustlarge'>-</td>
                                            <td className='thsmallcustlarge'>-</td>
                                            <td className='thsmallcust'>
                                                {value.length > 0
                                                    ? tableFilter.reduce((total, user) => total + parseFloat(user.quin), 0).toFixed(2)
                                                    : DataSource.reduce((total, user) => total + parseFloat(user.quin), 0).toFixed(2)}
                                            </td>
                                            <td className='thsmallcust'>-</td>
                                            <td className='thsmallcust'>
                                                {value.length > 0
                                                    ? tableFilter.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)
                                                    : DataSource.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)}
                                            </td>
                                        </tr>
                                    </table>
                                    :

                                    khrtype === "stock" ?
                                        <table id="myTablebill" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className='thsmall' scope="col">क्रमांक</th>
                                                    <th className='thsmall' scope="col">खाद्य नाव</th>
                                                    <th className='thsmall' scope="col">कंपनी</th>
                                                    <th className='thsmall' scope="col">ए.खरेदी</th>
                                                    <th className='thsmall' scope="col">ए.विक्री</th>
                                                    <th className='thsmall' scope="col">स्टॉक</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {value.length > 0 ? tableFilter.map((user, index) =>
                                                    <tr key={index}>
                                                        <td className='thsmallcustkhr'>{index + 1}</td>
                                                        <td className='thsmallcustlarge'>{user.khname}</td>
                                                        <td className='thsmallcustkhr'>{user.khcompany}</td>
                                                        <td className='thsmallcustkhr'>{user.total_purchase_qty && user.total_purchase_qty !== "" ? parseFloat(user.total_purchase_qty) : 0}</td>
                                                        <td className='thsmallcustkhr'>{user.total_sale_qty && user.total_sale_qty !== "" ? parseFloat(user.total_sale_qty) : 0}</td>
                                                        <td className='thsmallcustkhr'>{user.stock && user.stock !== "" ? parseFloat(user.stock) : 0}</td>
                                                    </tr>
                                                )
                                                    :
                                                    DataSource && DataSource.map((user, index) =>
                                                        <tr key={index}>
                                                            <td className='thsmallcustkhr'>{index + 1}</td>
                                                            <td className='thsmallcustkhr'>{user.khname}</td>
                                                            <td className='thsmallcustkhr'>{user.khcompany}</td>
                                                            <td className='thsmallcustkhr'>{user.total_purchase_qty && user.total_purchase_qty !== "" ? parseFloat(user.total_purchase_qty) : 0}</td>
                                                            <td className='thsmallcustkhr'>{user.total_sale_qty && user.total_sale_qty !== "" ? parseFloat(user.total_sale_qty) : 0}</td>
                                                            <td className='thsmallcustkhr'>{user.stock && user.stock !== "" ? parseFloat(user.stock) : 0}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                            <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                <td className='thsmallcust'>एकूण</td>
                                                <td className='thsmallcustlarge'>-</td>
                                                <td className='thsmallcustlarge'>-</td>
                                                <td className='thsmallcust'>
                                                    {value.length > 0
                                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.total_purchase_qty), 0).toFixed(2)
                                                        : DataSource.reduce((total, user) => total + parseFloat(user.total_purchase_qty), 0).toFixed(2)}
                                                </td>
                                                <td className='thsmallcust'>
                                                    {value.length > 0
                                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.total_sale_qty), 0).toFixed(2)
                                                        : DataSource.reduce((total, user) => total + parseFloat(user.total_sale_qty), 0).toFixed(2)}
                                                </td>
                                                <td className='thsmallcust'>
                                                    {value.length > 0
                                                        ? tableFilter.reduce((total, user) => total + parseFloat(user.stock), 0).toFixed(2)
                                                        : DataSource.reduce((total, user) => total + parseFloat(user.stock), 0).toFixed(2)}
                                                </td>
                                            </tr>
                                        </table>

                                        :

                                        khrtype === "adv" ?
                                            <table id="myTablebill" className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className='thsmall' scope="col">दिनांक</th>
                                                        <th className='thsmall' scope="col">खाते/नाव</th>
                                                        <th className='thsmall' scope="col">प्रकार</th>
                                                        <th className='thsmall' scope="col">रक्कम</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {value.length > 0 ? tableFilter.map((user, index) =>
                                                        <tr key={index}>
                                                            <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                            <td className='thsmallcustlarge'>{user.kno + ": " + user.cname}</td>
                                                            <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : 0}</td>
                                                            <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt).toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                                        :
                                                        DataSource && DataSource.map((user, index) =>
                                                            <tr key={index}>
                                                                <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                                <td className='thsmallcustlarge'>{user.kno + ": " + user.cname}</td>
                                                                <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : '-'}</td>
                                                                <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt).toFixed(2) : 0}</td>
                                                            </tr>
                                                        )
                                                    }

                                                </tbody>

                                                <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                    <td className='thsmallcust'>एकूण</td>
                                                    <td className='thsmallcustlarge'>-</td>
                                                    <td className='thsmallcustlarge'>-</td>
                                                    <td className='thsmallcust'>
                                                        {value.length > 0
                                                            ? tableFilter.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)
                                                            : DataSource.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)}
                                                    </td>
                                                </tr>
                                            </table>
                                            :

                                            khrtype === "udhari" && localStorage.getItem("bill10type") === "1" ?
                                                <table id="myTablebill" className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='thsmall' scope="col">खाते क्र</th>
                                                            <th className='thsmall' scope="col">खाते/नाव</th>
                                                            <th className='thsmall' scope="col">शेवटचे बील</th>
                                                            <th className='thsmall' scope="col">एडव्हांस बाकी</th>
                                                            <th className='thsmall' scope="col">खाद्य बाकी</th>
                                                            <th className='thsmall' scope="col">एकूण बाकी</th>
                                                            <th className='thsmall' scope="col">डिटेल्स</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {value.length > 0 ? tableFilter.map((user, index) =>
                                                            <tr key={index}>
                                                                <td className='thsmallcustkhr'>{user.kno && user.kno !== "" ? user.kno : "-"}</td>
                                                                <td className='thsmallcust'>{user.cname && user.cname !== "" ? user.cname : "-"}</td>
                                                                <td className='thsmallcust'>{user.date2 && user.date2 !== "" ? user.date2 : "-"}</td>
                                                                <td className='thsmallcust'>{user.newadv && user.newadv !== "" ? user.newadv : "-"}</td>
                                                                <td className='thsmallcust'>{user.newkhadya && user.newkhadya !== "" ? parseFloat(user.newkhadya) : 0}</td>
                                                                <td className='thsmallcust'>{user.newkhadya && user.newadv !== "" ? parseFloat(parseFloat(user.newkhadya) + parseFloat(user.newadv)).toFixed(2) : 0}</td>
                                                                <td className='thsmallcust'><UilEye onClick={() => { showDetails(user.kno, user.cname, user.lastkhadya, user.lastadv, user.date2) }} className="ic" /></td>
                                                            </tr>
                                                        )
                                                            :
                                                            DataSource && DataSource.map((user, index) =>
                                                                <tr key={index}>
                                                                    <td className='thsmallcustkhr'>{user.kno && user.kno !== "" ? user.kno : "-"}</td>
                                                                    <td className='thsmallcust'>{user.cname && user.cname !== "" ? user.cname : "-"}</td>
                                                                    <td className='thsmallcust'>{user.date2 && user.date2 !== "" ? user.date2 : "-"}</td>
                                                                    <td className='thsmallcust'>{user.newadv && user.newadv !== "" ? user.newadv : "-"}</td>
                                                                    <td className='thsmallcust'>{user.newkhadya && user.newkhadya !== "" ? parseFloat(user.newkhadya) : 0}</td>
                                                                    <td className='thsmallcust'>{user.newkhadya && user.newadv !== "" ? parseFloat(parseFloat(user.newkhadya) + parseFloat(user.newadv)).toFixed(2) : 0}</td>
                                                                    <td className='thsmallcust'><UilEye onClick={() => { showDetails(user.kno, user.cname, user.lastkhadya, user.lastadv, user.date2) }} className="ic" /></td>
                                                                </tr>

                                                            )
                                                        }


                                                    </tbody>
                                                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                        <td className='thsmallcust'>एकूण</td>
                                                        <td className='thsmallcust'>-</td>
                                                        <td className='thsmallcust'>-</td>

                                                        <td className='thsmallcust'>
                                                            {value.length > 0
                                                                ? tableFilter.reduce((total, user) => total + parseFloat(user.newadv), 0).toFixed(2)
                                                                : DataSource.reduce((total, user) => total + parseFloat(user.newadv), 0).toFixed(2)}
                                                        </td>

                                                        <td className='thsmallcust'>
                                                            {value.length > 0
                                                                ? tableFilter.reduce((total, user) => total + parseFloat(user.newkhadya), 0).toFixed(2)
                                                                : DataSource.reduce((total, user) => total + parseFloat(user.newkhadya), 0).toFixed(2)}
                                                        </td>

                                                        <td className='thsmallcust'>
                                                            {value.length > 0
                                                                ? tableFilter.reduce((total, user) => total + (parseFloat(user.newadv) + parseFloat(user.newkhadya)), 0).toFixed(2)
                                                                : DataSource.reduce((total, user) => total + (parseFloat(user.newadv) + parseFloat(user.newkhadya)), 0).toFixed(2)}
                                                        </td>
                                                        <td className='thsmallcust'>-</td>

                                                        {/* <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(parseFloat(user.newkhadya) +  parseFloat(user.newadv)).toFixed(2))}</td> */}

                                                    </tr>
                                                </table>
                                                :

                                                khrtype === "udhari" && localStorage.getItem("bill10type") === "0" ?
                                                    <table id="myTablebill" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th className='thsmall' scope="col">खाते क्र</th>
                                                                <th className='thsmall' scope="col">खाते/नाव</th>
                                                                <th className='thsmall' scope="col">उधारी बाकी</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {value.length > 0 ? tableFilter.map((user, index) =>
                                                                <tr key={index}>
                                                                    <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : "-"}</td>
                                                                    <td className='thsmallcust'>{user.cname && user.cname !== "" ? user.cname : "-"}</td>
                                                                    <td className='thsmallcust'>{user.tbaki && user.tbaki !== "" ? parseFloat(user.tbaki).toFixed(2) : "-"}</td>

                                                                </tr>
                                                            )
                                                                :
                                                                DataSource && DataSource.map((user, index) =>
                                                                    <tr key={index}>
                                                                        <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : "-"}</td>
                                                                        <td className='thsmallcust'>{user.cname && user.cname !== "" ? user.cname : "-"}</td>
                                                                        <td className='thsmallcust'>{user.tbaki && user.tbaki !== "" ? parseFloat(user.tbaki).toFixed(2) : "-"}</td>

                                                                    </tr>

                                                                )
                                                            }


                                                        </tbody>
                                                        <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                            <td className='thsmallcust'>एकूण</td>
                                                            <td className='thsmallcust'>-</td>
                                                            <td className='thsmallcust'>
                                                                {DataSource.reduce((total, user) => {
                                                                    const tbaki = parseFloat(user.tbaki);
                                                                    return isNaN(tbaki) ? total : total + tbaki;
                                                                }, 0).toFixed(2)}
                                                            </td>

                                                        </tr>
                                                    </table>
                                                    :

                                                    khrtype === "dreport" ?
                                                        <>
                                                            <table id="myTablebill" className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th className='thsmall' scope="col">दिनांक</th>
                                                                        <th className='thsmall' scope="col">प्रकार</th>
                                                                        <th className='thsmall' scope="col">नाव</th>
                                                                        <th className='thsmall' scope="col">नग</th>
                                                                        <th className='thsmall' scope="col">दर</th>
                                                                        <th className='thsmall' scope="col">रक्कम</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {value.length > 0 ? tableFilter.map((user, index) =>
                                                                        <tr key={index}>
                                                                            <td className='thsmallcust'>{formatDate(user.date)}</td>
                                                                            <td className='thsmallcustlarge'>{user.trantype + ": " + user.trantype}</td>
                                                                            <td className='thsmallcust'>{user.khname && user.khname !== "" ? user.khname : "-"}</td>
                                                                            <td className='thsmallcust'>{user.quin && user.quin !== "" ? user.quin : "-"}</td>
                                                                            <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                                                                            <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                                                                        </tr>
                                                                    )
                                                                        :
                                                                        <>
                                                                            <tr key={0}>
                                                                                <td className='thsmallcustred'>{"मागील बाकी"}</td>
                                                                                <td className='thsmallcustred'>{"-"}</td>
                                                                                <td className='thsmallcustred'>{"-"}</td>
                                                                                <td className='thsmallcustred'>{"-"}</td>
                                                                                <td className='thsmallcustred'>{"-"}</td>
                                                                                <td className='thsmallcustred'>{PreviousAmt}</td>
                                                                            </tr>

                                                                            {
                                                                                DataSource && DataSource.map((user, index) =>
                                                                                    <tr key={index} className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"} >
                                                                                        <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{formatDate(user.date)}</td>
                                                                                        <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.trantype}</td>
                                                                                        <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.khname}</td>
                                                                                        <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.quin}</td>
                                                                                        <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.rate && user.rate !== "" ? user.rate : "-"}</td>
                                                                                        <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.amt && user.amt !== "" ? user.amt : "-"}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </>
                                                                    }
                                                                </tbody>
                                                                <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                                    <td className='thsmallcust'>एकूण</td>
                                                                    <td className='thsmallcustlarge'>-</td>
                                                                    <td className='thsmallcust'>-</td>
                                                                    <td className='thsmallcust'>
                                                                        {parseFloat(
                                                                            DataSource.reduce((total, user) => {
                                                                                if (user.trantype === "नावे") {
                                                                                    return total + parseFloat(user.quin);
                                                                                } else {
                                                                                    return total - parseFloat(user.quin);
                                                                                }
                                                                            }, 0) + parseFloat(PreviousAmt)
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                    <td className='thsmallcust'>-</td>
                                                                    <td className='thsmallcust'>
                                                                        {parseFloat(
                                                                            DataSource.reduce((total, user) => {
                                                                                if (user.trantype === "नावे") {
                                                                                    return total + parseFloat(user.amt);
                                                                                } else {
                                                                                    return total - parseFloat(user.amt);
                                                                                }
                                                                            }, 0) + parseFloat(PreviousAmt)
                                                                        ).toFixed(2)}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </>
                                                        :
                                                        ''
                        }
                    </div>

                </div>

            </div>

        </>
    )
}

export default Khadyaadvreport
