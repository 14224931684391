import React, { useContext, useEffect, useRef, useState } from 'react'
import './Bill.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { CasinoSharp, Co2Sharp, Face6TwoTone, FaxTwoTone, Rowing } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useFetcher, useNavigate } from 'react-router-dom';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import Globalcontext from '../../Context/Globalcontext';
import Status from "../../imgs/statusmob.jpeg";

const Bill = (props) => {

  const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), knofrom: "", knoto: "", knofromprint: "", knotoprint: "", ikapatnote: "कपात नोट", mobileuser: "" });
  const inputRefs = useRef([]);
  const [DataSource, setDataSource] = useState([]);
  const [kapat, setKapat] = useState({});
  const [tableFilter, setTableFilter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [print, setprint] = useState(false);
  const [first, setfirst] = useState("false");
  const cellRefs = useRef([]);
  const [value, setValue] = useState('');
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  //const [customerData, setCustomerData] = useState([]);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  let updatedDataSource = [];
  const [updatedDataSource2, setUpdatedDataSource2] = useState([]);
  const refdelete = useRef(null);
  const refdeleteclose = useRef(null);
  const [showthevcol, setShowthevcol] = useState("No");
  const [showother, setShowother] = useState("Yes");
  const [wait, setWait] = useState(false);
  const [wait2, setWait2] = useState(false);
  const [waitsave, setWaitsave] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customerData, setCustomerData } = useContext(Globalcontext);
  const refprint = useRef(null);
  const refprintclose = useRef(null);
  const [rtype, setRtype] = useState("1");
  const [count, setCount] = useState(10);
  const [isCounting, setIsCounting] = useState(false);
  const [kno1, setKno1] = useState(1);
  const [kno2, setKno2] = useState(2);
  const [isFetching, setIsFetching] = useState(false);
  const [mobileusers, setMobileusers] = useState([]);

  let index2 = 0;

  const handleIsfetching = () => {
    if (isFetching === "true") {
      setIsFetching("false");
    }
    else {
      setIsFetching("true");
    }
  }

  useEffect(() => {

    if (isFetching === "true") {
      getbill();
    }

  }, [isFetching])


  const handlertype = () => {
    if (rtype === "1") {
      setRtype("2");
    }
    else {
      setRtype("1");
    }
  }

  async function getmobileusers() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getmobileusers.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
      if (response.data) {
        setMobileusers(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const handleDelete = () => {
    refdelete.current.click();
  }

  const handledeleteclose = () => {
    refdeleteclose.current.click();
  }

  const handlePrint = () => {
    refprint.current.click();
  }
  const handleprintclose = () => {
    refprintclose.current.click();
  }

  const fetchtabledata = () => {

    // if (DataSource[0].flag === "oldbill") {
    //   if (DataSource[0].lastbill && DataSource[0].lastbill === "No") {
    //     notifyerror("आपण फक्त शेवटचे सेव्ह केले बील अपडेट करू शकता!");
    //     return;
    //   }
    // }

    try {
      const table = document.getElementById('myTablebill');
      const rows = table.getElementsByTagName('tr');

      const data = [];

      for (let i = 1; i < rows.length; i++) { // Start from 1 to skip header row
        const row = rows[i];
        const cells = row.getElementsByTagName('td');

        const rowData = {
          // Assuming the order of cells matches your column order
          date1: input.date1,
          date2: input.date2,
          kno: cells[0].textContent,
          bill: cells[2].textContent,
          tliter: cells[3].textContent,
          magilthev: cells[4].textContent,
          cthev: cells[5].textContent,
          newthev: cells[6].textContent,
          madv: cells[7].textContent,
          cadv: cells[8].textContent,
          tadv: cells[9].textContent,
          advcut: cells[10].textContent,
          newadv: cells[11].textContent,
          mkhadya: cells[12].textContent,
          ckhadya: cells[13].textContent,
          tkhadya: cells[14].textContent,
          khadyacut: cells[15].textContent,
          newkhadya: cells[16].textContent,
          ikapatliter: cells[17].textContent,
          ikapatbill: cells[18].textContent,
          vah: cells[19].textContent,
          ada: cells[20].textContent,
        };
        data.push(rowData);
      }
      const insertQuery = `INSERT INTO bill (date1, date2, kno, billamt,magilthev,cthev,newthev,madv,cadv,tadv,advkapat,newadv,mkhadya,ckhadya,tkhadya,khadyakapat,newkhadya,ikapatliter,ikapatbill,vah,ada,mainuser) VALUES
      ${data.map(row => `('${row.date1}', '${row.date2}', '${row.kno}', '${row.bill}','${row.magilthev}','${row.cthev}','${row.newthev}','${row.madv}', '${row.cadv}', '${row.tadv}', '${row.advcut}','${row.newadv}','${row.mkhadya}', '${row.ckhadya}', '${row.tkhadya}', '${row.khadyacut}','${row.newkhadya}','${row.ikapatliter}','${row.ikapatbill}','${row.vah}','${row.ada}','${localStorage.getItem("contact")}')`).join(', ')}`;

      savebill(insertQuery, data);
    }
    catch {
    }
  }

  const savebill = (insertQuery, data) => {
    setWaitsave(true);
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (data.length === 0 || insertQuery === "" || input.date1 === "" || input.date2 === "" || input.knofrom === "" || input.knoto === "") {
      notifyerror("अपूर्ण एंट्री माहिती!");
      setWaitsave(false);
      return;
    }
    let rawData = {
      insertQuery: insertQuery,
      mainuser: localStorage.getItem("contact"),
      date1: input.date1,
      date2: input.date2,
      mainuser2: mainuser2,

    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/savebill.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {


      if (response.data.trim() === "Saved.") {
        notify("सभासद बील सेव्ह झाले!");
        setDataSource([]);
        getbill();
        setWaitsave(false);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  function handleEnterKey(event, currentIndex) {

    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  useEffect(() => {
    const currentDate1 = new Date(input.date1);
    const dayOfMonth1 = currentDate1.getDate();
    const currentMonth1 = currentDate1.getMonth();
    const currentYear1 = currentDate1.getFullYear();

    let lastDayOfMonth1;

    if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
      lastDayOfMonth1 = 10;
    } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
      lastDayOfMonth1 = 20;
    } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
      lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
    }

    if (lastDayOfMonth1 !== undefined) {
      const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date2: formattedDate2
      }));
    }
    setDataSource([]);
    setTableFilter([]);
  }, [input.date1]);


  useEffect(() => {
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let firstDayOfMonth;
    let lastDayOfMonth;

    if (dayOfMonth >= 1 && dayOfMonth <= 10) {
      firstDayOfMonth = 1;
      lastDayOfMonth = 10;
    } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
      firstDayOfMonth = 11;
      lastDayOfMonth = 20;
    } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
      firstDayOfMonth = 21;
      lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
    }

    if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
      const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
      const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date1: formattedDate1,
        date2: formattedDate2
      }));
    }

    getmobileusers();

    getmaxno();
  }, []);

  const handleCellKeyPress12 = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        if (nextRow && nextRow[1]) { // Use nextRow[1] for column 12
          nextRow[1].focus();
        }
      }
      e.target.blur(); // Remove focus from the cell
    } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
      // Prevent non-numeric input
      if (isNaN(e.key) && e.key !== '.') {
        e.preventDefault();
      }
    }
  };

  const handleChangeactive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowthevcol("Yes");
    }
    else {
      setShowthevcol("No");
    }
  }

  const handleOtheractive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowother("Yes");
    }
    else {
      setShowother("No");
    }
  }


  const showprint = async () => {

    if (DataSource[0].flag !== "newbill") {
      refprintclose.current.click();
      setWait2(true);
      var mainuser2 = jsEncode.encode("mamapr", 125);

      try {
        const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbillprint2.php', {
          mainuser: localStorage.getItem('contact'),
          date1: input.date1,
          date2: input.date2,
          mainuser2: mainuser2,
          knofrom: input.knofromprint,
          knoto: input.knotoprint,
          mobileuser: input.mobileuser,
          billtype: DataSource[0].flag,
          bcount: DataSource[0].bcount
        });

        if (DataSource && DataSource.length > 0) {
          localStorage.setItem("date1print", input.date1);
          localStorage.setItem("date2print", input.date2);
          localStorage.setItem("billtype", DataSource[0].flag);
          localStorage.setItem("bcount", DataSource[0].bcount);
          localStorage.setItem("customerData", JSON.stringify(response.data));
          localStorage.setItem("kapat", JSON.stringify(kapat));
          localStorage.setItem("showother", showother);
          localStorage.setItem("design", rtype);
          if (input.ikapatnote !== "कपात नोट" && input.ikapatnote.trim() !== "") {
            localStorage.setItem("ikapatnote", "(" + input.ikapatnote + ")");
          }
          else {
            localStorage.setItem("ikapatnote", "");
          }
          localStorage.setItem("mobileuser", input.mobileuser);
          const url = "/billprint2";
          window.open(url, '_blank');  //'_self'  _blank
        } else {
          notifyerror("या कालावधीमध्ये संकलन उपलब्ध नाही!");
        }
      } catch (error) {
        notifyerror(error);
        // Handle errors here
      } finally {
        setWait2(false);
      }
    }
    else {
      notifyerror("कृपया प्रथम बिल सेव्ह करा!")
      refprintclose.current.click();
    }
  };


  const addOrUpdateCuchal = (kno, cuchal) => {
    setKapat((prevKapat) => {
      const updatedKapat = { ...prevKapat };

      if (updatedKapat[kno]) {
        updatedKapat[kno].cuchal = cuchal;
      } else {
        updatedKapat[kno] = { cuchal, ckhadya: '' };
      }

      return updatedKapat;
    });
  };


  const addOrUpdateCkhadya = (kno, ckhadya) => {
    setKapat((prevKapat) => {
      const updatedKapat = { ...prevKapat };

      if (updatedKapat[kno]) {
        updatedKapat[kno].ckhadya = ckhadya;
      } else {
        updatedKapat[kno] = { cuchal: '', ckhadya };
      }

      return updatedKapat;
    });
  };

  const handleCellKeyPress = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        if (nextRow[0]) {
          nextRow[0].focus();
        }
      }
      e.target.blur(); // Remove focus from the cell
    } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
      // Prevent non-numeric input
      if (isNaN(e.key) && e.key !== '.') {
        e.preventDefault();
      }
    }
  };

  const handleCellChange = (e, rowIndex, kno) => {

    let newValue = e.target.textContent;
    updatedDataSource = [...DataSource];

    //addOrUpdateCuchal(user.kno,advedit);

    if (updatedDataSource[rowIndex]) {
      //  updatedDataSource[rowIndex].khedit = updatedDataSource[rowIndex].action;
      updatedDataSource[rowIndex].action = newValue;
      // Calculate the result (column 6 - column 7) and store in column 8
      let column6Value = "";

      if (DataSource[0].flag === "oldbill") {
        column6Value = parseFloat(updatedDataSource[rowIndex].newadv) + parseFloat(updatedDataSource[rowIndex].cuchal);
      }
      else {
        column6Value = (parseFloat(updatedDataSource[rowIndex].bcount) > 0 ? parseFloat(updatedDataSource[rowIndex].newadv) : parseFloat(updatedDataSource[rowIndex].obal)) + parseFloat(updatedDataSource[rowIndex].cuchal);

      }

      const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;

      // console.log("column7Value-", column7Value);
      updatedDataSource[rowIndex].result = column6Value - column7Value;

      //if (newValue) {
      addOrUpdateCuchal(kno, newValue);
      if (DataSource[0].flag === "oldbill") {
        //notify("already before");


        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            (newValue ? parseFloat(newValue) : 0) +
            (
              (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].khedit)
                : parseFloat(updatedDataSource[rowIndex].khadyakapat)
            )
            +
            (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
              parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
              (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter && updatedDataSource[rowIndex].mhliter !== "No" ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
                parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter && updatedDataSource[rowIndex].gayliter !== "No" ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

            )
          );

      }
      else {

        // console.log("Edit- ", updatedDataSource[rowIndex].ikapatedit);

        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -

          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
            (
              (!isNaN(kapat[kno].ckhadya) && kapat[kno].ckhadya !== null)
                ? parseFloat(kapat[kno].ckhadya)
                :
                (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null && updatedDataSource[rowIndex].khadyahpta !== '0')
                  ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
                  : 0
            )
          )
          -
          (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
            parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
            (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
              parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)))


      }
      // }
      // else {
      //   //addOrUpdateCuchal(kno, newValue);

      //   if (DataSource[0].flag === "oldbill") {
      //     updatedDataSource[rowIndex].adacalculated =
      //     parseFloat(updatedDataSource[rowIndex].bill) -
      //     (
      //       parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //       parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //       (
      //         (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
      //           ? parseFloat(updatedDataSource[rowIndex].khedit)
      //           : parseFloat(updatedDataSource[rowIndex].khadyakapat)
      //       ) +
      //       (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
      //       parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0))-
      //       (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
      //       parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)))
      //     );

      //     //console.log(updatedDataSource[rowIndex].adacalculated);
      //   }
      //   else {

      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
      //           parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
      //           (
      //             (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
      //               ? parseFloat(updatedDataSource[rowIndex].khedit)
      //               :
      //               (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null)
      //                 ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
      //                 :
      //                 parseFloat(updatedDataSource[rowIndex].khadyakapat)
      //           ) +
      //           parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) +
      //         (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
      //         parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

      //         ))

      //   }
      // }

      if (!newValue) {
        newValue = 0;
      }

    }
    updatedDataSource[rowIndex].advedit = newValue;
    setDataSource(updatedDataSource);

    e.target.contentEditable = 'true'; // Re-enable editing
  };

  const handleCellBlurkh = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();
    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
      newValue = 0;
    }
  };

  const handleCellBlurikapat = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();
    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
      newValue = 0;
    }
  };

  const handleCellfocus = (e, rowIndex) => {
    if (e.target.textContent === "0") {
      e.target.textContent = '';
    }
  }

  const handleCellBlur = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();

    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
    }
  };

  const handleCellChangekh = (e, rowIndex, kno) => {
    let newValue = e.target.textContent;
    updatedDataSource = [...DataSource];

    if (updatedDataSource[rowIndex]) {
      updatedDataSource[rowIndex].action = newValue;

      // Calculate the result (column 6 - column 7) and store in column 8
      let column6Value = 0;
      if (DataSource[0].flag === "oldbill") {
        column6Value = parseFloat(updatedDataSource[rowIndex].mkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
      }
      else {
        column6Value = parseFloat(updatedDataSource[rowIndex].newkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
      }

      const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;
      updatedDataSource[rowIndex].result2 = column6Value - column7Value;

      // if (newValue) {
      addOrUpdateCkhadya(kno, newValue);
      if (DataSource[0].flag === "oldbill") {



        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            (
              (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].advedit)
                : parseFloat(updatedDataSource[rowIndex].advkapat)
            ) +
            (newValue ? parseFloat(newValue) : 0) +
            parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
            parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
            parseFloat(updatedDataSource[rowIndex].vah || 0)
          );
      }
      else {

        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
            (
              (!isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
                ? parseFloat(kapat[kno].cuchal)
                :
                (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
                  ? parseFloat(updatedDataSource[rowIndex].advhpta)
                  : 0
            ) +
            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
            parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
            parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
            parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)

          );
      }
      //}
      // else {
      //   addOrUpdateCkhadya(kno, newValue);
      //   if (DataSource[0].flag === "oldbill") {

      //     //updatedDataSource[rowIndex].adacalculated = parseFloat(updatedDataSource[rowIndex].bill) - (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) + (parseFloat(updatedDataSource[rowIndex].advedit) ? parseFloat(updatedDataSource[rowIndex].advedit) : parseFloat(updatedDataSource[rowIndex].advkapat ? updatedDataSource[rowIndex].advkapat : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) + parseFloat(updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)));
      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (
      //           (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].advedit)
      //             : parseFloat(updatedDataSource[rowIndex].advkapat)
      //         ) +
      //         (newValue ? parseFloat(newValue) : 0) +
      //         parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
      //         parseFloat(updatedDataSource[rowIndex].vah || 0)
      //       );
      //   }
      //   else {

      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (parseFloat(newValue) ? parseFloat(newValue) : 0) +
      //         (
      //           (!isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
      //             ? parseFloat(kapat[kno].cuchal)
      //             :
      //             (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
      //               ? parseFloat(updatedDataSource[rowIndex].advhpta)
      //               : 0
      //         ) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
      //         parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
      //         parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)

      //       );
      //   }
      // }

      if (!newValue) {
        newValue = 0;
      }
      updatedDataSource[rowIndex].khedit = newValue; //1
      setUpdatedDataSource2(prevInput => ({
        ...prevInput,
        khedit: newValue
      }))
      setDataSource(updatedDataSource);
    }

    e.target.contentEditable = 'true'; // Re-enable editing
  };



  // useEffect(()=>{
  //   console.log(updatedDataSource)
  // },[updatedDataSource])

  const handleCellChangeikapat = (e, rowIndex, kno) => {

    let newValue = e.target.textContent;


    updatedDataSource = [...DataSource];

    if (updatedDataSource[rowIndex]) {
      updatedDataSource[rowIndex].action = newValue;

      //  if (newValue) {

      //  addOrUpdateCkhadya(kno, newValue);

      if (DataSource[0].flag === "oldbill") {

        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            ((
              (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].advedit)
                : parseFloat(updatedDataSource[rowIndex].advkapat)
            )
              +
              (
                (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                  ? parseFloat(updatedDataSource[rowIndex].khedit)
                  : parseFloat(updatedDataSource[rowIndex].khadyakapat)
              ))
            +
            ((newValue ? parseFloat(newValue) : 0)
              + (parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0))))
            - parseFloat(updatedDataSource[rowIndex].vah)

          );
      }
      else {


        updatedDataSource[rowIndex].adacalculated =
          parseFloat(updatedDataSource[rowIndex].bill) -
          (
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
            parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
            (
              ((!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].advedit) :
                !isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
                ? parseFloat(kapat[kno].cuchal)
                :
                (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
                  ? parseFloat(updatedDataSource[rowIndex].advhpta)
                  : 0
            )
            +
            (
              (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                ? parseFloat(updatedDataSource[rowIndex].khedit) :
                !isNaN(kapat[kno].ckhadya) && kapat[kno].ckhadya !== null
                  ? parseFloat(kapat[kno].ckhadya)
                  :
                  (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null && updatedDataSource[rowIndex].khadyahpta !== '0')
                    ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
                    : 0
            )
            +
            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) -
            // parseFloat((updatedDataSource[rowIndex].ikapatedit ? updatedDataSource[rowIndex].ikapatedit : 0)) -
            (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
              parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

          );
      }
      // }
      // else {
      //   //  addOrUpdateCkhadya(kno, newValue);
      //   if (DataSource[0].flag === "oldbill") {
      //     //updatedDataSource[rowIndex].adacalculated = parseFloat(updatedDataSource[rowIndex].bill) - (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) + (parseFloat(updatedDataSource[rowIndex].advedit) ? parseFloat(updatedDataSource[rowIndex].advedit) : parseFloat(updatedDataSource[rowIndex].advkapat ? updatedDataSource[rowIndex].advkapat : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) + parseFloat(updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)));
      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (
      //           (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].advedit)
      //             : parseFloat(updatedDataSource[rowIndex].advkapat)
      //         ) +
      //         parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
      //         parseFloat(updatedDataSource[rowIndex].ikapatbill || 0) -
      //         parseFloat(updatedDataSource[rowIndex].vah || 0)
      //       );
      //   }
      //   else {
      //     updatedDataSource[rowIndex].adacalculated =
      //       parseFloat(updatedDataSource[rowIndex].bill) -
      //       (
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
      //         parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
      //         (
      //           ((!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].advedit) :
      //             !isNaN(kapat[kno].cuchal) && kapat[kno].cuchal !== null)
      //             ? parseFloat(kapat[kno].cuchal)
      //             :
      //             (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null && updatedDataSource[rowIndex].advhpta !== '0')
      //               ? parseFloat(updatedDataSource[rowIndex].advhpta)
      //               : 0
      //         )
      //         +
      //         (
      //           (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
      //             ? parseFloat(updatedDataSource[rowIndex].khedit) :
      //             !isNaN(kapat[kno].ckhadya) && kapat[kno].ckhadya !== null
      //               ? parseFloat(kapat[kno].ckhadya)
      //               :
      //               (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null && updatedDataSource[rowIndex].khadyahpta !== '0')
      //                 ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
      //                 : 0
      //         )
      //         +
      //         parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
      //         parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) -
      //         (parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) +
      //           parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0))

      //       );
      //   }
      // }

      if (!newValue) {
        newValue = 0;
      }
      updatedDataSource[rowIndex].ikapatedit = newValue; //1

      setUpdatedDataSource2(prevInput => ({
        ...prevInput,
        ikapatbill2: newValue
      }))
      setDataSource(updatedDataSource);
    }
    e.target.contentEditable = 'true'; // Re-enable editing
  };


  // useEffect(() => {
  //   console.log(DataSource)
  // }, [DataSource])


  async function getmaxno() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    props.setProgress(30);
    setTimeout(() => {
      props.setProgress(50);
    }, 200);
    axios.post('https://idairy.co.in/dairy/php/cust/getmaxno.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {
        setInput(prevInput => ({
          ...prevInput,
          knofrom: "1",
          knoto: response.data,
          knofromprint: "1",
          knotoprint: response.data
        }))
      }
      else {
        setInput(prevInput => ({
          ...prevInput,
          knofrom: "0",
          knoto: "0"
        }))
      }
    }).catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    let interval;
    if (isCounting && count > 1) {
      interval = setInterval(() => {
        setCount(prevCount => prevCount - 1);
      }, 1000);
    }

    if (count === 1) {
      clearInterval(interval);
      setIsCounting(false);
    }

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [isCounting, count]);


  const getbill = async () => {
    setCount(parseFloat(input.knoto * 2).toFixed(0));
    setWait(true);
    setIsFetching(true);
    setIsCounting(true);

    const mainuser2 = jsEncode.encode("mamapr", 125);

    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      setWait(false);
      setIsFetching(false);
      setIsCounting(false);
      return;
    }

    const fetchBills = async (i) => {
      if (i >= input.knoto) {
        setWait(false);
        setIsFetching(false);
        return;
      }

      const rawData = {
        date1: input.date1,
        date2: input.date2,
        knofrom: i + 1,
        knoto: i + 2,
        mainuser: localStorage.getItem("contact"),
        mainuser2: mainuser2,
      };

      const formData = new FormData();
      formData.append('data', JSON.stringify(rawData));

      try {
        const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbillfast3.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.length > 0) {
          console.log(response.data);
          setDataSource(prevDataSource => [...prevDataSource, ...response.data]);
          // response.data.forEach((user) => {
          //   addOrUpdateCuchal(user.kno, user.advhpta);
          //   addOrUpdateCkhadya(user.kno, user.khadyahpta);
          // });
          // console.log(response.data);
          {
            response.data && response.data.map((user, index) => {

              let khedit = user.flag === "oldbill" ?
                (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

                parseFloat(
                  // parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) 
                  parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) < parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) ?
                    user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                      :
                      user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                        parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                          parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                          - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                          : 0
                        :
                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)
                    :
                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                      parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                    ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                      parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                    ) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?

                      parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                        parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                      :
                      parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)))
                      ) -
                        parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))))
                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                ).toFixed(2);



              let advedit = user.flag === "oldbill" ?
                (user.advkapat ? user.advkapat : 0) :

                parseFloat(parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2) > 0 ?
                  parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta) <= parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) ?
                    parseFloat(user.advhpta)
                    :
                    parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                      >
                      parseFloat(
                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                      ?
                      parseFloat(
                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                        -
                        parseFloat(
                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                        <=
                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                        ?
                        parseFloat(parseFloat(
                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) -
                          parseFloat(
                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                              parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                              parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                        )
                          -
                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                          +
                          parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                          +
                          parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                        ).toFixed(2)
                        :

                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                      :
                      0)
                  :
                  0);

              addOrUpdateCuchal(user.kno, advedit);
              addOrUpdateCkhadya(user.kno, khedit);
            })
          }

          if (response.data[0].flag === "oldbill") {
            setWait(false);
            setIsFetching(false);
            setIsCounting(false);
            return;
          }
        }

        // Proceed to the next batch regardless of success or empty response
        updatedDataSource = [...DataSource];
        await fetchBills(i + 2);
        //  setWait(false);
        //    setIsFetching(false);
      } catch (error) {
        console.log(error);
        setWait(false);
        setIsFetching(false);
      }
    };

    await fetchBills(0);
  };



  const deletebill = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/deletebill.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data.trim() === "Deleted") {
        notify("सभासद बील डिलीट झाले!");
        refdeleteclose.current.click();
        setDataSource([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const handleedit = (sr, date, time, kno, name, type, liter, fat, snf, rate, amt) => {
    // setInput({ date: date, time: time, kno: kno, name: name, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })
    // setNewedit("edit");
    // setSredit(sr);
  }
  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }));
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refprint}
        data-bs-target="#printinput"
      >
      </button>

      <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalLabel">प्रिंट साठी खाते क्रमांक निवडा</h5>
              <div className='helpmain'>
                <img className='smallimg' src={Status} alt="" />
                <input onChange={handleChange} type="number" className="form-control" id="knofromprint" name="knofromprint" value={input.knofromprint} autoComplete='off' />
                <span> ते </span>
                <input onChange={handleChange} type="number" className="form-control" id="knotoprint" name="knotoprint" value={input.knotoprint} autoComplete='off' />
              </div>
              <div className="radiotheme">
                <div className="form-check">
                  <input onClick={handlertype} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                  <label className="form-check-label radiolabel" htmlFor="radio2">
                    बिल डिझाईन 1
                  </label>
                </div>
                <div className="form-check">
                  <input onClick={handlertype} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                  <label className="form-check-label radiolabel" htmlFor="radio1">
                    बिल डिझाईन 2
                  </label>
                </div>
              </div>
              <div hidden className="form-check check">
                <input className="form-check-input" type="checkbox" defaultChecked value="" onChange={handleOtheractive} id="activeother" />
                <span className="checklbl" htmlFor="activeother">
                  इतर कपात ऑप्शन्स प्रिंटवर दाखवा.
                </span>
              </div>
              <div className="form-check check">
                <input className="form-check-input" type="checkbox" defaultChecked value="" onChange={handleOtheractive} id="activeother" />
                <input onChange={handleChange} type="text" className="form-control" id="ikapatnote" name="ikapatnote" value={input.ikapatnote} autoComplete='off' />
              </div>

              <div className="mb-2">
                <span className='subheadsank'>मोबाईल यूजर नाव</span>
                <select onKeyDown={e => handleEnterKey(e, 6)} ref={ref => (inputRefs.current[6] = ref)} className={"form-control"} autoComplete='off' id="mobileuser" name="mobileuser" value={input.mobileuser} onChange={handleChange}>
                  <option key={0} value={"मोबाईल यूजर नाव निवडा"}>मोबाईल यूजर नाव निवडा</option>
                  {mobileusers ? mobileusers.map((opt2, index) =>

                    <option key={index + 1} value={opt2.name}>{opt2.name}</option>

                  ) : ""}
                </select>
              </div>

              <span className='note'>सूचना : बिल प्रिंट्स देताना प्रिंटर सेटिंग्ज बरोबर असल्याची खात्री करा!</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
              <button type="button" className="btn btn-danger" onClick={showprint}>ओके</button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refdelete}
        data-bs-target="#deletebill"
      >
      </button>

      <div className="modal fade" id="deletebill" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">बील डिलीट करायचे?</h4>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
              <button type="button" className="btn btn-danger" onClick={deletebill}>होय</button>
            </div>
          </div>
        </div>
      </div>
      <div className="sanklanmain">
        <div className="firstrowsank">
          <img onClick={props.visibility} className='back' src={back} alt="" />
          <div className="colmainbill">
            <div className="firstrowbill">
              <div className="rowbill">
                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill se'> || </span>

                <div className="mb-2 se">
                  <span className='subheadsank'>खाते क्र.</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2">
                  <span className='subheadsank'>खाते क्र</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                  {
                    wait ?
                      <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">Loading..{count}</button>
                      :
                      <button onClick={handleIsfetching} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">बील पहा</button>
                  }
                  {
                    wait ?
                      '' :
                      DataSource && DataSource.length === 0 ?
                        null :
                        (
                          waitsave ?
                            <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='save' className="btn btn-primary mx-1 sanksmall">
                              {
                                "लोडिंग ..."
                              }
                            </button>
                            :
                            <button onClick={fetchtabledata} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='save' className="btn btn-primary mx-1 sanksmall">
                              {
                                DataSource[0].flag === "oldbill" ? "बील अपडेट" :
                                  "बील सेव्ह"
                              }
                            </button>
                        )

                  }
                  {
                    wait ?
                      '' :
                      DataSource && DataSource.length === 0 ?
                        '' :
                        (
                          wait2 ? (
                            <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">लोडिंग...</button>
                          ) : (
                            <button onClick={handlePrint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">बील प्रिंट</button>
                          )
                        )
                  }
                </div>
              </div>
              <div className="optionbill">
                <div className='newbill'>
                  <span>{DataSource && DataSource.length === 0 ? "नवीन बील" : DataSource[0].flag === "oldbill" ? "जूने बील" :
                    "नवीन बील"}</span>
                  {DataSource && DataSource.length === 0 ?
                    '' :
                    DataSource[0].flag === "oldbill" ?
                      (DataSource[0]?.lastbill === "Yes" || DataSource[1]?.lastbill === "Yes" || DataSource[2]?.lastbill === "Yes" || DataSource[3]?.lastbill === "Yes" || DataSource[4]?.lastbill === "Yes") ?
                        <UilTrash onClick={handleDelete} className="icdelete" />
                        : ''

                      : ''}
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" onChange={handleChangeactive} id="activestatus" />
                  <span className="checklbl" htmlFor="activestatus">
                    सर्व कॉलम्स
                  </span>
                </div>
                <div hidden className="form-check">
                  <input className="form-check-input" type="checkbox" value="" onChange={handleChangeactive} id="activestatus" />
                  <span className="checklbl" htmlFor="activestatus">
                    ठेव अधिक कॉलम्स
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="firstrow2">
          <div class="table-containerbill">
            <table id="myTablebill" className="table table-bordered">
              <thead>
                <tr>
                  <th className='thsmall' scope="col">खाते क्रमांक</th>
                  <th className='thlarge' scope="col">नाव</th>
                  <th className='thsmall' scope="col">बील रक्कम</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">ए.लिटर</th>
                  <th className={'thsmallhide'} scope="col">मागील ठेव</th>
                  <th className='thsmall' scope="col">ठेव कपात<span><br />(-)</span></th>
                  <th className={'thsmallhide'} scope="col">नवीन ठेव</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">मागील एडव्हान्स बाकी</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">चालू एडव्हान्स</th>
                  <th className='thsmall' scope="col">एकूण एडव्हान्स</th>
                  <th className='thsmall' scope="col"><UilEditAlt className="editlogo" />एडव्हान्स कपात<br /><span>(-)</span></th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmall'} scope="col">नवीन एडव्हान्स बाकी</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">मागील खाद्य बाकी</th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">चालू खाद्य</th>
                  <th className='thsmall' scope="col">एकूण खाद्य</th>
                  <th className='thsmall' scope="col"><UilEditAlt className="editlogo" />खाद्य कपात<br /><span>(-)</span></th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmall'} scope="col">नवीन खाद्य बाकी</th>
                  <th className={'thsmallhide'} scope="col">इ.कपात/ली<br /><span>(-)</span></th>
                  <th className={'thsmall'} scope="col">इ.कपात<br /><span>(-)</span></th>
                  <th className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'} scope="col">वाहतूक<br /><span>(+)</span></th>
                  <th className='thsmall' scope="col">अदा<br /><span>(=)</span></th>
                </tr>
              </thead>
              <tbody>
                {DataSource && DataSource.map((user, index) => {

                  let khedit = user.flag === "oldbill" ?
                    (user.khadyakapat && user.khadyakapat !== '0' ? user.khadyakapat : 0) :

                    parseFloat(
                      // parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) 
                      parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) < parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) ?
                        user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                          :
                          user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                            parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                              parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                              - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                              : 0
                            :

                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                              parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                              parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

                        :

                        parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                          parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                        ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                          parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                        ) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?

                          parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                            parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                          parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                          + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)

                          :
                          parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)))
                          ) -
                            parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))))
                          + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                    ).toFixed(2);

                  let advedit = user.flag === "oldbill" ?
                    (user.advkapat ? user.advkapat : 0) :

                    parseFloat(parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2) > 0 ?
                      parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta) <= parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) ?
                        parseFloat(user.advhpta)
                        :
                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                          >
                          parseFloat(
                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                              parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                              parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                          ?
                          parseFloat(
                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                            -
                            parseFloat(
                              parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                            <=
                            parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                            ?
                            parseFloat(parseFloat(
                              parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) -
                              parseFloat(
                                parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                  parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                  parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                            )
                              -
                              parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                              +
                              parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                              +
                              parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                            ).toFixed(2)
                            :

                            parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                          :
                          0)
                      :
                      0);

                  // updatedDataSource = [...DataSource];

                  const cuchalValue = parseFloat(user.cuchal);
                  const newadvValue = parseFloat(user.newadv);
                  const obalValue = parseFloat(user.obal);
                  const bcountValue = parseFloat(user.bcount);
                  const advhptaValue = parseFloat(user.advhpta);
                  const billValue = parseFloat(user.bill).toFixed(2);
                  const mhliterValue = parseFloat(user.mhliter);
                  const mhthevValue = parseFloat(user.mhthev);
                  const gayliterValue = parseFloat(user.gayliter);
                  const gaythevValue = parseFloat(user.gaythev);
                  const ckhadyaValue = parseFloat(user.ckhadya);
                  const newkhadyaValue = parseFloat(user.newkhadya);
                  const ikapatliter2Value = parseFloat(user.ikapatliter2);
                  const tliterValue = parseFloat(user.tliter);
                  const ikapatbill2Value = parseFloat(user.ikapatbill2);
                  const vah2Value = parseFloat(user.vah2);
                  const gayvahValue = parseFloat(user.gayvah);

                  const calculatedValue = parseFloat(
                    cuchalValue + (bcountValue > 0 ? newadvValue : obalValue)
                  ).toFixed(2);

                  const mhthevCalculation = (mhliterValue * mhthevValue).toFixed(2);
                  const gaythevCalculation = (gayliterValue * gaythevValue).toFixed(2);
                  const totalBillMinusThev = (billValue - parseFloat(mhthevCalculation) - parseFloat(gaythevCalculation)).toFixed(2);

                  const advhptaCheck = advhptaValue <= totalBillMinusThev ? advhptaValue : totalBillMinusThev;

                  const ckhadyaPlusNewKhadya = (ckhadyaValue + newkhadyaValue).toFixed(2);
                  const khadyaCheck = parseFloat(totalBillMinusThev) < parseFloat(ckhadyaPlusNewKhadya) ? totalBillMinusThev : ckhadyaPlusNewKhadya;

                  const finalCalculation = (
                    parseFloat(
                      totalBillMinusThev -
                      parseFloat(ikapatliter2Value * tliterValue || 0) -
                      parseFloat(ikapatbill2Value || 0) +
                      parseFloat(vah2Value * mhliterValue || 0) +
                      parseFloat(gayvahValue * gayliterValue || 0)
                    ).toFixed(2)
                  );

                  const resultValue = user.result && !isNaN(user.result)
                    ? parseFloat(user.result).toFixed(2)
                    : finalCalculation <= calculatedValue ? finalCalculation : calculatedValue;



                  if (updatedDataSource[index]) {
                    addOrUpdateCkhadya(user.kno, khedit);
                    addOrUpdateCuchal(user.kno, advedit);
                    // console.log("in 1");
                    updatedDataSource[index].action = khedit;

                    if (!khedit) {
                      khedit = 0;
                    }
                    updatedDataSource[index].khedit = khedit;
                    updatedDataSource[index].advedit = advedit;
                    if (first === "false") {
                      // console.log("in first time");
                      updatedDataSource[index].khedit = khedit;
                      updatedDataSource[index].advedit = advedit;
                      setfirst("true")
                    }
                  }

                  return (
                    <tr key={index}>
                      <td className='thsmall'>{user.kno}</td>
                      <td className='thsmall'>{user.name}</td>
                      <td className='thsmall2'>{parseFloat(user.bill).toFixed(2)}</td>
                      <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.tliter ? parseFloat(user.tliter).toFixed(2) : 0}</td>
                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.magilthev ? parseFloat(user.magilthev).toFixed(2) : 0) :
                            parseFloat(user.bcount) > 0 ? parseFloat(user.newthev).toFixed(2) : parseFloat(user.othev).toFixed(2)
                        }</td>
                      <td className='thsmall2'>{
                        user.flag === "oldbill" ?
                          (user.cthev ? parseFloat(user.cthev).toFixed(2) : 0) :
                          parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                      }</td>
                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            parseFloat(user.newthev) ? parseFloat(user.newthev).toFixed(2) : 0
                            :
                            parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newthev) : parseFloat(user.othev))).toFixed(2)
                        }</td>
                      <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.newadv ? user.newadv : 0) :
                            parseFloat(user.bcount) > 0 ? parseFloat(user.newadv).toFixed(2) : parseFloat(user.obal).toFixed(2)
                        }</td>
                      <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.cuchal ? user.cuchal : 0}</td>
                      <td className='thsmall2'>
                        {
                          user.flag === "oldbill" ?
                            (user.tadv ? parseFloat(user.tadv).toFixed(2) : 0) :
                            parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                        }</td>
                      <td className='thsmall2edit'
                        contentEditable={
                          user.flag === "oldbill" ?
                            user.lastbill === "Yes" ?
                              'true'
                              : 'false'
                            : 'true'
                        }
                        onKeyDown={(e) => handleCellKeyPress(e, index)}
                        onBlur={(e) => handleCellBlur(e, index)}
                        onFocus={(e) => handleCellfocus(e, index)}
                        ref={(td) => {
                          if (!cellRefs.current[index]) {
                            cellRefs.current[index] = [];
                          }
                          cellRefs.current[index][0] = td; // Save the ref for the cell
                        }}
                        onInput={(e) => handleCellChange(e, index, user.kno)}>
                        {
                          advedit
                        }
                      </td>

                      <td className={showthevcol === "Yes" ? 'thsmallgreen' : 'thsmallgreen'} >
                        {user.flag === "oldbill" ? (
                          user.result != null && !isNaN(user.result) ? parseFloat(user.result).toFixed(2) : user.newadvcurrent != null && !isNaN(user.newadvcurrent) ? parseFloat(user.newadvcurrent).toFixed(2) : 0
                        )
                          :
                          parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2) > 0
                            ?
                            (
                              user.result != null && !isNaN(user.result)
                                ?
                                parseFloat(user.result).toFixed(2)
                                :
                                parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal)) - (user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta) <= parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                  ?
                                    parseFloat(user.advhpta)
                                  :
                                  parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' && parseFloat(user.advhpta) <= parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                    ?
                                    parseFloat(user.advhpta)
                                    :
                                    parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                      >
                                      parseFloat(
                                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)
                                          ?
                                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                          :
                                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya)
                                            ?
                                            (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2)
                                            :
                                            0))
                                      ?
                                      parseFloat(
                                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                        -
                                        parseFloat(
                                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                                        <=
                                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                                        ?

                                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2) > 0 ?
                                          parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' 
                                            ? 
                                                parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)  <    parseFloat(user.advhpta) 
                                                ?
                                                    parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) -  parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)) : 0).toFixed(2) //problem was here
                                                :
                                                parseFloat(user.advhpta) 

                                            :
                                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                              >
                                              parseFloat(
                                                parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                                  parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                                  parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))

                                              ?
                                              parseFloat(
                                                parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2)
                                                -
                                                parseFloat(
                                                  parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                                    parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                                    parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)))
                                                <=
                                                parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)

                                                ?
                                                    parseFloat(
                                                      parseFloat(
                                                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) -
                                                        parseFloat(
                                                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                                            parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                                            parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                                                      )
                                                      -
                                                      parseFloat(parseFloat(user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                                      +
                                                      parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                                      +
                                                      parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                                    ).toFixed(2)
                                                :

                                                parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                                              :
                                              0)
                                          :
                                          0
                                        :
                                        parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)
                                      :
                                      0)
                                 
                                )).toFixed(2)
                            )
                            :
                            parseFloat(parseFloat(user.cuchal) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newadv) : parseFloat(user.obal))).toFixed(2)}
                      </td>

                      <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{
                        user.flag === "oldbill" ?
                          (user.mkhadya ? parseFloat(user.mkhadya).toFixed(2) : 0) :
                          user.newkhadya ? parseFloat(user.newkhadya).toFixed(2) : 0
                      }</td>
                      <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>{user.ckhadya ? parseFloat(user.ckhadya).toFixed(2) : 0}</td>
                      <td className='thsmall2'>
                        {
                          user.flag === "oldbill" ?
                            parseFloat(user.ckhadya) + parseFloat(user.mkhadya) ? parseFloat(parseFloat(user.ckhadya) + parseFloat(user.mkhadya)).toFixed(2) : 0
                            :
                            parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0

                        }
                      </td>
                      <td className='thsmall2edit'
                        contentEditable={
                          user.flag === "oldbill" ?
                            user.lastbill === "Yes" ?
                              'true'
                              : 'false'
                            : 'true'
                        }
                        onKeyDown={(e) => handleCellKeyPress12(e, index)}
                        onBlur={(e) => handleCellBlurkh(e, index)}
                        ref={(td) => {
                          if (!cellRefs.current[index]) {
                            cellRefs.current[index] = [];
                          }
                          cellRefs.current[index][1] = td; // Save the ref for the cell in column 12
                        }}
                        onInput={(e) => handleCellChangekh(e, index, user.kno)}>
                        {
                          khedit
                        }
                      </td>
                      <td className={showthevcol === "Yes" ? 'thsmallgreen' : 'thsmallgreen'}>
                        {
                          user.flag === "oldbill" ?
                            (user.result2 != null && !isNaN(user.result2) ? parseFloat(user.result2).toFixed(2) : user.newkhadyacurrent != null && !isNaN(user.newkhadyacurrent) ? parseFloat(user.newkhadyacurrent).toFixed(2) : 0) :
                            user.result2 != null && !isNaN(user.result2) ? parseFloat(user.result2).toFixed(2) : (parseFloat(user.ckhadya) + parseFloat(user.newkhadya) -
                              (parseFloat(
                                // parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) 
                                parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) < parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) ?
                                  user.khadyahpta && user.khadyahpta !== null && user.khadyahpta !== '0' ? parseFloat(user.khadyahpta).toFixed(2)
                                    :
                                    user.advhpta && user.advhpta !== null && user.advhpta !== '0' ?
                                      parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0 > 0 ?
                                        parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                          parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                          parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0))
                                        - parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)
                                        : 0
                                      :
                                      parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?
                                        parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))).toFixed(2) :
                                        parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0)

                                  :
                                  parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                                    parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                                  ) > 0 && parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                                    parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)).toFixed(2)
                                  ) < parseFloat(parseFloat(user.ckhadya) + parseFloat(user.newkhadya) ? (parseFloat(user.ckhadya) + parseFloat(user.newkhadya)).toFixed(2) : 0) ?

                                    parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2))) -
                                      parseFloat(user.advhpta && user.advhpta !== null && user.advhpta !== '0' ? user.advhpta : 0)) -
                                    parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0))).toFixed(2)
                                    + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                    :
                                    parseFloat(parseFloat(parseFloat(parseFloat(user.bill).toFixed(2) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)))
                                    ) -
                                      parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                                      + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                      + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                    )

                              ).toFixed(2)
                              )).toFixed(2)
                        }
                      </td>
                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.ikapatliter ? parseFloat(user.ikapatliter) : 0) :
                            (user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter).toFixed(2) : 0)
                        }
                      </td>
                      {/* <td className={'thsmall2edit'}>
                        {
                          user.flag === "oldbill" ?
                            (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                            (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                        }
                      </td> */}

                      <td className='thsmall2edit'
                        contentEditable=
                        {
                          true
                          // user.flag === "oldbill" ?
                          //   user.lastbill === "Yes" ?
                          //     'true'
                          //     : 'false'
                          //   : 'true'
                        }
                        onKeyDown={(e) => handleCellKeyPress12(e, index)}
                        onBlur={(e) => handleCellBlurikapat(e, index)}
                        ref={(td) => {
                          if (!cellRefs.current[index]) {
                            cellRefs.current[index] = [];
                          }
                          cellRefs.current[index][1] = td; // Save the ref for the cell in column 12
                        }}
                        onInput={(e) => handleCellChangeikapat(e, index, user.kno)}>
                        {
                          user.flag === "oldbill" ?
                            (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                            (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                        }
                      </td>

                      <td className={showthevcol === "Yes" ? 'thsmall' : 'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.vah ? parseFloat(user.vah) : 0) :
                            parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter).toFixed(2) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                        }
                      </td>
                      <td className='thsmall2'>
                        {
                          user.flag === "oldbill" ?
                            (user.adacalculated != null && !isNaN(user.adacalculated)) ? parseFloat(user.adacalculated).toFixed(2) : (user.ada ? parseFloat(user.ada).toFixed(2) : 0) :
                            (user.adacalculated != null && !isNaN(user.adacalculated)) ? parseFloat(user.adacalculated).toFixed(2) : user.bill
                              ?
                              parseFloat(parseFloat(user.bill)
                                -
                                (parseFloat(parseFloat(parseFloat(user.mhliter) ? parseFloat(user.mhliter) : 0) * parseFloat(user.mhthev ? parseFloat(user.mhthev) : 0)) +
                                  parseFloat(parseFloat(parseFloat(user.gayliter) ? parseFloat(user.gayliter) : 0) * parseFloat(user.gaythev ? parseFloat(user.gaythev) : 0))) +
                                + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0)
                                + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter) : 0)
                                - khedit
                                - advedit
                                - parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                              ).toFixed(2) : 0
                        }
                      </td>
                    </tr>
                  );
                }
                )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Bill
