import React, { useEffect, useRef, useState } from 'react'
import './Customerreport.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';

const Customerreport = (props) => {

    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), kno: "", cname: "", kapatreport: "", type: "एकत्रित कपात रिपोर्ट" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const [wait, setWait] = useState(false);
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const navigate = useNavigate();

    const [rtype, setRtype] = useState("bybill");
    const handlertype = (type) => {
        setRtype(type);
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    const showprint = () => {
        if (rtype === "bybill") {
            if (DataSource && DataSource.length > 0) {
                localStorage.setItem("date1print", input.date1);
                localStorage.setItem("date2print", input.date2);
                localStorage.setItem("knoprint", input.kno);
                const url = "/custreportprint";
                window.open(url, '_blank');
            }
            else {
                notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
            }
        }
        else if (rtype === "kapat") {
            if (DataSource && DataSource.length > 0) {
                localStorage.setItem("date1print", input.date1);
                localStorage.setItem("date2print", input.date2);
                localStorage.setItem("type", input.type);
                const url = "/kapatprint";
                window.open(url, '_blank');
            }
            else {
                notifyerror("या कालावधीची माहिती उपलब्ध नाही!");
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date2') {
            // Check if date2 is more than one year ahead of date1
                setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
       
        }
    }

    const getreport = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.kno === "") {
            notifyerror("सभासद खाते क्रमांक भरा");
            setDataSource([]);
            setWait(false);
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: input.kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getcustreport.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            // console.log(response.data);
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
            }
            setWait(false);
        }).catch(err => {
            console.log(err);
        });
    }

    const getkapatreport = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.kapatreport === "कपात रिपोर्ट निवडा") {
            notifyerror("कपात प्रकार निवडा");
            setDataSource([]);
            setWait(false);
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kapatreport: input.kapatreport,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getkapatreport.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
        
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
            }
            setWait(false);
        }).catch(err => {
            console.log(err);
        });
    }

    function formatDate(inputDate) {
        // Ensure inputDate is in 'yyyy-mm-dd' format
        const parts = inputDate.split('-');
        if (parts.length !== 3) {
            return ''; // Invalid date format
        }

        // Convert month number to month name in English
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const year = parts[0];
        const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
        const day = parts[2];

        return `${day}-${months[month]}`;
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    const filteData = (e) => {
        if (e.target.value != "") {
            setValue(e.target.value);
            const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
            ));

            setTableFilter([...filtertable]);
        }
        else {
            setValue(e.target.value);
            setTableFilter([...DataSource]);
        }
    }

    const filteData2 = (e) => {
        const searchValue = e.target.value.trim();

        if (searchValue !== "") {
            setValue(searchValue);
            const filtertable = DataSource.filter(o =>
                String(o.kno) === searchValue // Check for exact match with kno
            );

            setTableFilter([...filtertable]);
        } else {
            setValue(searchValue);
            setTableFilter([...DataSource]);
        }
    };

    useEffect(() => {
        if (rtype === "kapat") {
            const currentDate1 = new Date(input.date1);
            const dayOfMonth1 = currentDate1.getDate();
            const currentMonth1 = currentDate1.getMonth();
            const currentYear1 = currentDate1.getFullYear();

            let lastDayOfMonth1;

            if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
                lastDayOfMonth1 = 10;
            } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
                lastDayOfMonth1 = 20;
            } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
                lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
            }

            if (lastDayOfMonth1 !== undefined) {
                const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
                setInput(prevInput => ({
                    ...prevInput,
                    date2: formattedDate2
                }));
            }
            setDataSource([]);
            setTableFilter([]);
        }
    }, [input.date1]);

    return (
        <div className="sanklanmain">
            <div className="firstrowsank">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmain">
                    <div className="firstrow2">
                        <div className="rowbill">
                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक निवडा</span>
                                <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                            </div>

                            <span className='subheadbill'>ते</span>

                            <div className="mb-2">
                                <span className='subheadsank'>-</span>
                                <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                            </div>

                            <span className='subheadbill se'> || </span>

                            {
                                rtype === "bybill" ?
                                    <div className="mb-2 se">
                                        <span className='subheadsank'>खाते क्र.</span>
                                        <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                                    </div>
                                    :
                                    <div className="mb-2">
                                        <span className='subheadsank'>कपात प्रकार</span>
                                        <select value={input.type} name='type' id='type' onChange={handleChange} className={"form-control"}>
                                            <option key={0} value="कपात प्रकार निवडा">कपात प्रकार निवडा</option>
                                            <option key={1} value="ठेव कपात रिपोर्ट">ठेव कपात रिपोर्ट</option>
                                            <option key={2} value="एड्व्हांस कपात रिपोर्ट">एड्व्हांस कपात रिपोर्ट</option>
                                            <option key={3} value="खाद्य कपात रिपोर्ट">खाद्य कपात रिपोर्ट</option>
                                            <option key={3} value="इतर कपात रिपोर्ट">इतर कपात रिपोर्ट</option>
                                            <option key={4} value="एकत्रित कपात रिपोर्ट">एकत्रित कपात रिपोर्ट</option>
                                        </select>
                                    </div>
                            }
                            <div className="mb-2">
                                {
                                    wait ?
                                    <button type="submit" id='show' className="btn btn-primary mx-1 sanksmall">लोडिंग...</button>
                                    :
                                    <button onClick={rtype === "bybill" ? getreport : getkapatreport} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">{rtype === "bybill" ? "खाते पहा" : "रिपोर्ट पहा"  }</button>
                                }
                                <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="firstrow2">
                <div class="table-containerbill">

                    <div className="buttonsreportsank">
                        <input type="text" className="form-control searchsank mx-3" id="searchsank" name="searchsank" placeholder='Search' value={value} onChange={filteData} autoComplete='off' />

                        <div className="radio">
                            <div className="form-check">
                                <input onClick={() => { handlertype("bybill") }} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                                <label className="form-check-label" htmlFor="radio3">
                                    बिलाप्रमाणे खाते रिपोर्ट
                                </label>
                            </div>
                            <div className="form-check">
                                <input onClick={() => { handlertype("kapat") }} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                                <label className="form-check-label" htmlFor="radio3">
                                    बिल कपात रिपोर्ट
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        rtype === "bybill" ?
                            <div>
                                <span className='custname'>
                                    {
                                        DataSource && DataSource.length > 0 ?
                                            "ग्राहकाचे नाव- " + DataSource[0].cname : ''
                                    }

                                </span>
                                <table id="myTablebill" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className='thsmall' scope="col">बील कालावधी</th>
                                            <th className='thsmall' scope="col">बिल रक्कम</th>
                                            <th className='thsmall' scope="col">मागील एडव्हान्स</th>
                                            <th className='thsmall' scope="col">चालू एडव्हान्स <span><br />(+)</span></th>
                                            <th className='thsmall' scope="col">एकूण एडव्हान्स<span><br />.</span></th>
                                            <th className='thsmall' scope="col">एडव्हान्स कपात<span><br />(-)</span></th>
                                            <th className='thsmall' scope="col">एडव्हान्स बाकी<span><br />(=)</span></th>
                                            <th className='thsmall' scope="col">मागील खाद्य<span><br />.</span></th>
                                            <th className='thsmall' scope="col">चालू खाद्य <span><br />(+)</span></th>
                                            <th className='thsmall' scope="col">एकूण खाद्य<span><br />.</span></th>
                                            <th className='thsmall' scope="col">खाद्य कपात<span><br />(-)</span></th>
                                            <th className='thsmall' scope="col">खाद्य बाकी<span><br />(=)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DataSource && DataSource.map((user, index) =>
                                            <tr key={index}>
                                                <td className='thsmallcustlarge'>{formatDate(user.date1) + " ते " + formatDate(user.date2)}</td>
                                                <td className='thsmallcust'>{user.billamt && user.billamt !== "" ? parseFloat(user.billamt) : 0}</td>
                                                <td className='thsmallcust'>{user.madv && user.madv !== "" ? parseFloat(user.madv) : 0}</td>
                                                <td className='thsmallcust'>{user.cadv && user.cadv !== "" ? parseFloat(user.cadv) : 0}</td>
                                                <td className='thsmallcust'>{user.tadv && user.tadv !== "" ? parseFloat(user.tadv) : 0}</td>
                                                <td className='thsmalledit'>{user.advkapat && user.advkapat !== "" ? parseFloat(user.advkapat) : 0}</td>
                                                <td className='thsmallcust'>{user.newadv && user.newadv !== "" ? parseFloat(user.newadv) : 0}</td>
                                                <td className='thsmallcust'>{user.mkhadya && user.mkhadya !== "" ? parseFloat(user.mkhadya) : 0}</td>
                                                <td className='thsmallcust'>{user.ckhadya && user.ckhadya !== "" ? parseFloat(user.ckhadya) : 0}</td>
                                                <td className='thsmallcust'>{user.tkhadya && user.tkhadya !== "" ? parseFloat(user.tkhadya) : 0}</td>
                                                <td className='thsmalledit'>{user.khadyakapat && user.khadyakapat !== "" ? parseFloat(user.khadyakapat) : 0}</td>
                                                <td className='thsmallcust'>{user.newkhadya && user.newkhadya !== "" ? parseFloat(user.newkhadya) : 0}</td>
                                            </tr>
                                        )
                                        }
                                    </tbody>

                                </table>
                            </div>
                            :
                            rtype === "kapat" && input.type === "एकत्रित कपात रिपोर्ट" ?
                                <div>
                                    <table id="myTablebill" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className='thsmall' scope="col">बील कालावधी</th>
                                                <th className='thsmall' scope="col">खाते क्रमांक</th>
                                                <th className='thsmall' scope="col">नाव</th>
                                                <th className='thsmall' scope="col">ठेव कपात</th>
                                                <th className='thsmall' scope="col">एडव्हांस कपात</th>
                                                <th className='thsmall' scope="col">खाद्य कपात</th>
                                                <th className='thsmall' scope="col">इतर कपात</th>
                                                <th className='thsmall' scope="col">एकूण कपात</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DataSource && DataSource.map((user, index) =>
                                                <tr key={index}>
                                                    <td className='thsmallcustlarge'>{formatDate(input.date1) + " ते " + formatDate(input.date2)}</td>
                                                    <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : 0}</td>
                                                    <td className='thsmallcust'>{user.kname && user.kname !== "" ? user.kname : 0}</td>
                                                    <td className='thsmallcust'>{user.cthev && user.cthev !== "" ? parseFloat(user.cthev).toFixed(2) : 0}</td>
                                                    <td className='thsmallcust'>{user.advkapat && user.advkapat !== "" ? parseFloat(user.advkapat).toFixed(2) : 0}</td>
                                                    <td className='thsmallcust'>{user.khadyakapat && user.khadyakapat !== "" ? parseFloat(user.khadyakapat).toFixed(2) : 0}</td>
                                                    <td className='thsmallcust'>{user.ikapatbill && user.ikapatbill !== "" ? parseFloat(user.ikapatbill).toFixed(2) : 0}</td>
                                                    <td className='thsmallcust'>{user.totalkapat && user.totalkapat !== "" ? parseFloat(user.totalkapat).toFixed(2) : 0}</td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                        <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                            <td className='thsmallcust'>एकूण (₹)</td>
                                            <td className='thsmallcustlarge'>-</td>
                                            <td className='thsmallcustlarge'>-</td>
                                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.cthev), 0).toFixed(0)}</td>
                                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.advkapat), 0).toFixed(0)}</td>
                                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.khadyakapat), 0).toFixed(0)}</td>
                                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.ikapatbill), 0).toFixed(0)}</td>
                                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.totalkapat), 0).toFixed(0)}</td>
                                        </tr>
                                    </table>
                                </div>

                                :
                                rtype === "kapat" && input.type === "ठेव कपात रिपोर्ट" ?
                                    <div>
                                        <table id="myTablebill" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className='thsmall' scope="col">बील कालावधी</th>
                                                    <th className='thsmall' scope="col">खाते क्रमांक</th>
                                                    <th className='thsmall' scope="col">नाव</th>
                                                    <th className='thsmall' scope="col">ठेव कपात</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {DataSource && DataSource.map((user, index) =>
                                                    <tr key={index}>
                                                        <td className='thsmallcustlarge'>{formatDate(input.date1) + " ते " + formatDate(input.date2)}</td>
                                                        <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : 0}</td>
                                                        <td className='thsmallcust'>{user.kname && user.kname !== "" ? user.kname : 0}</td>
                                                        <td className='thsmallcust'>{user.cthev && user.cthev !== "" ? parseFloat(user.cthev).toFixed(2) : 0}</td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                            <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                <td className='thsmallcust'>एकूण (₹)</td>
                                                <td className='thsmallcustlarge'>-</td>
                                                <td className='thsmallcustlarge'>-</td>
                                                <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.cthev), 0).toFixed(0)}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    :
                                    rtype === "kapat" && input.type === "एड्व्हांस कपात रिपोर्ट" ?
                                        <div>
                                            <table id="myTablebill" className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className='thsmall' scope="col">बील कालावधी</th>
                                                        <th className='thsmall' scope="col">खाते क्रमांक</th>
                                                        <th className='thsmall' scope="col">नाव</th>
                                                        <th className='thsmall' scope="col">एडव्हांस कपात</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {DataSource && DataSource.map((user, index) =>
                                                        <tr key={index}>
                                                            <td className='thsmallcustlarge'>{formatDate(input.date1) + " ते " + formatDate(input.date2)}</td>
                                                            <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : 0}</td>
                                                            <td className='thsmallcust'>{user.kname && user.kname !== "" ? user.kname : 0}</td>
                                                            <td className='thsmallcust'>{user.advkapat && user.advkapat !== "" ? parseFloat(user.advkapat).toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                                    }
                                                </tbody>
                                                <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                    <td className='thsmallcust'>एकूण (₹)</td>
                                                    <td className='thsmallcustlarge'>-</td>
                                                    <td className='thsmallcustlarge'>-</td>
                                                    <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.advkapat), 0).toFixed(0)}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        :
                                        rtype === "kapat" && input.type === "खाद्य कपात रिपोर्ट" ?
                                            <div>
                                                <table id="myTablebill" className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='thsmall' scope="col">बील कालावधी</th>
                                                            <th className='thsmall' scope="col">खाते क्रमांक</th>
                                                            <th className='thsmall' scope="col">नाव</th>
                                                            <th className='thsmall' scope="col">खाद्य कपात</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {DataSource && DataSource.map((user, index) =>
                                                            <tr key={index}>
                                                                <td className='thsmallcustlarge'>{formatDate(input.date1) + " ते " + formatDate(input.date2)}</td>
                                                                <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : 0}</td>
                                                                <td className='thsmallcust'>{user.kname && user.kname !== "" ? user.kname : 0}</td>
                                                                <td className='thsmallcust'>{user.khadyakapat && user.khadyakapat !== "" ? parseFloat(user.khadyakapat).toFixed(2) : 0}</td>
                                                            </tr>
                                                        )
                                                        }
                                                    </tbody>
                                                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                        <td className='thsmallcust'>एकूण (₹)</td>
                                                        <td className='thsmallcustlarge'>-</td>
                                                        <td className='thsmallcustlarge'>-</td>
                                                        <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.khadyakapat), 0).toFixed(0)}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            :
                                            rtype === "kapat" && input.type === "इतर कपात रिपोर्ट" ?
                                                <div>
                                                    <table id="myTablebill" className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th className='thsmall' scope="col">बील कालावधी</th>
                                                                <th className='thsmall' scope="col">खाते क्रमांक</th>
                                                                <th className='thsmall' scope="col">नाव</th>
                                                                <th className='thsmall' scope="col">खाते क्रमांक</th>
                                                                <th className='thsmall' scope="col">इतर कपात</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {DataSource && DataSource.map((user, index) =>
                                                                parseFloat(user.ikapatbill) > 0 ?
                                                                <tr key={index}>
                                                                    <td className='thsmallcustlarge'>{formatDate(input.date1) + " ते " + formatDate(input.date2)}</td>
                                                                    <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : 0}</td>
                                                                    <td className='thsmallcust'>{user.kname && user.kname !== "" ? user.kname : 0}</td>
                                                                    <td className='thsmallcust'>{user.accno2 && user.accno2 !== "" ? user.accno2 : 0}</td>
                                                                    <td className='thsmallcust'>{user.ikapatbill && user.ikapatbill !== "" ? parseFloat(user.ikapatbill).toFixed(2) : 0}</td>
                                                                </tr>
                                                                : ''
                                                            )
                                                            }
                                                        </tbody>
                                                        <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                                            <td className='thsmallcust'>एकूण (₹)</td>
                                                            <td className='thsmallcustlarge'>-</td>
                                                            <td className='thsmallcustlarge'>-</td>
                                                            <td className='thsmallcustlarge'>-</td>
                                                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.ikapatbill), 0).toFixed(0)}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                :
                                                ''
                    }
                </div>

            </div>

        </div >
    )
}

export default Customerreport
