import React, { useContext, useRef, useState } from 'react'
import './Othercontains.css'
import milkcan from "../../imgs/tvatap.webp";
import bonus from "../../imgs/bonus.png";
import khadya from "../../imgs/pregister.png";
import Box from '../Box/Box';
import Cowleft from "../../imgs/cowleft.gif";
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import Globalcontext from '../../Context/Globalcontext';
import Bill from '../Bill/Bill';
import Pregister from '../Pregister/Pregister';
import Thevvatap from '../Thevvatap/Thevvatap';
import Bonus from '../Bonus/Bonus';


const Othercontains = (props) => {
    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const {sanksource, setSanksource,setHeadname } = useContext(Globalcontext);

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const areUSure = (choose) => {

        handleDialog("", false);

    };

    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const visibility = () => {
        localStorage.setItem("glcustsr","");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("इतर व्हाऊचर");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };



  return (
    <>
    <div className='line' />
    <div className="maincon">

        <span className={`heading1 ${mastermenu ? '' : 'hidden'}`}>इतर व्हाऊचर्स</span>
        <div className={`Dashcontents ${mastermenu ? '' : 'hidden'}`} onTransitionEnd={handleTransitionEnd}>
            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t2dark), var(--t2dark))"} head={"वार्षिक ठेव वाटप"} img={<img src={milkcan} alt="" />} />
            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3dark), var(--t3dark))"} head={"वार्षिक बोनस वाटप"} img={<img src={bonus} alt="" />} />
        </div>

        <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
            <div className="first">

            </div>
            <div className="second">
                <div className='line' />
                <span className='firstspan'>1. येथे आपण वार्षिक ठेव वाटप रिपोर्ट पाहू शकता.</span>
                <span className='infospan'>2. ठेव वाटप केल्यानंतर सभासदांचा नवीन वार्षिक ठेव हिशोब सुरू करता येतो.</span>
                <span className='infospan'>3. तसेच आपण सभासदांना एकूण लिटर प्रमाणे बोनस वाटप ही करू शकता.</span>
              
            </div>

        </div>


        <div className={`cust ${mastermenu ? 'hidden' : ''}`}>
            {transitionEnded && showBackImage && (
                submenu === "thevvatap" ? <Thevvatap setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                submenu === "bonus" ? <Bonus setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                 ""
            )}
        </div>

    </div>
    <div className="secrow">
        <div className="leftsecrow">

        </div>
        {
            submenu !== "sanklan" && submenu !== "bill" && submenu !== "pregister" ?
        <div className="imgcon">
            <img className='imgcowtran' src={Cowleft} alt="" />
        </div>
        : ""
            }

    </div>

    {dialog.isLoading && (
        <Dialog
            //Update
            submessage={dialog.nameProduct}
            onDialog={areUSure}
            message={dialog.message}
            functionname={openkendra}
            button1text="नाही"
            button2text="होय"
        />
    )}
</>
  )
}

export default Othercontains
