import React, { useContext, useEffect, useRef, useState } from 'react'
import './Billvar.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { CasinoSharp, Co2Sharp, Face6TwoTone, FaxTwoTone, Rowing } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import Globalcontext from '../../Context/Globalcontext';
import Status from "../../imgs/statusmob.jpeg";

const Billvar = (props) => {

  const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), knofrom: "", knoto: "", knofromprint: "", knotoprint: "" });
  const inputRefs = useRef([]);
  const [DataSource, setDataSource] = useState([]);
  const [kapat, setKapat] = useState({});
  const [tableFilter, setTableFilter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [print, setprint] = useState(false);
  const [first, setfirst] = useState("false");
  const cellRefs = useRef([]);
  const [value, setValue] = useState('');
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  //const [customerData, setCustomerData] = useState([]);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  let updatedDataSource = [];
  const refdelete = useRef(null);
  const refdeleteclose = useRef(null);
  const [showthevcol, setShowthevcol] = useState("No");
  const [showother, setShowother] = useState("Yes");
  const [wait, setWait] = useState(false);
  const [wait2, setWait2] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customerData, setCustomerData } = useContext(Globalcontext);
  const refprint = useRef(null);
  const refprintclose = useRef(null);
  const [rtype, setRtype] = useState("1");

  let index2 = 0;

  const handlertype = () => {
    if (rtype === "1") {
      setRtype("2");
    }
    else {
      setRtype("1");
    }
  }


  const handleDelete = () => {
    refdelete.current.click();
  }

  const handledeleteclose = () => {
    refdeleteclose.current.click();
  }

  const handlePrint = () => {
    refprint.current.click();
  }
  const handleprintclose = () => {
    refprintclose.current.click();
  }

  const fetchtabledata = () => {

    if (DataSource[0].flag === "oldbill") {
      if (DataSource[0].lastbill && DataSource[0].lastbill === "No") {
        notifyerror("आपण फक्त शेवटचे सेव्ह केले बील अपडेट करू शकता!");
        return;
      }
    }

    try {
      const table = document.getElementById('myTablebill');
      const rows = table.getElementsByTagName('tr');

      const data = [];

      for (let i = 1; i < rows.length; i++) { // Start from 1 to skip header row
        const row = rows[i];
        const cells = row.getElementsByTagName('td');

        const rowData = {
          // Assuming the order of cells matches your column order
          date1: input.date1,
          date2: input.date2,
          kno: cells[0].textContent,
          bill: cells[2].textContent,
          magilthev: cells[3].textContent,
          cthev: cells[4].textContent,
          newthev: cells[5].textContent,
          magilbaki: cells[6].textContent,
          ckhadya: cells[7].textContent,
          cadv: cells[8].textContent,
          tbaki: cells[9].textContent,
          tkapat: cells[10].textContent,
          yenebaki: cells[11].textContent,
          ikapatliter: cells[12].textContent,
          ikapatbill: cells[13].textContent,
          vah: cells[14].textContent,
        };
        data.push(rowData);
      }
      const insertQuery = `INSERT INTO billvar (date1, date2, kno, billamt,magilthev,cthev,newthev,magilbaki,ckhadya,cadv,tbaki,tkapat,yenebaki,ikapatliter,ikapatbill,vah,mainuser) VALUES
      ${data.map(row => `('${row.date1}', '${row.date2}', '${row.kno}', '${row.bill}','${row.magilthev}','${row.cthev}','${row.newthev}','${row.magilbaki}','${row.ckhadya}','${row.cadv}','${row.tbaki}','${row.tkapat}','${row.yenebaki}','${row.ikapatliter}','${row.ikapatbill}','${row.vah}','${localStorage.getItem("contact")}')`).join(', ')}`;

      savebill(insertQuery, data);
    }
    catch {
      notify("in");
    }
  }

  const savebill = (insertQuery, data) => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (data.length === 0 || insertQuery === "" || input.date1 === "" || input.date2 === "" || input.knofrom === "" || input.knoto === "") {
      notifyerror("अपूर्ण एंट्री माहिती!");

      return;
    }
    let rawData = {
      insertQuery: insertQuery,
      mainuser: localStorage.getItem("contact"),
      date1: input.date1,
      date2: input.date2,
      mainuser2: mainuser2,

    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/savebillvar.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      //console.log(response.data);
      if (response.data.trim() === "Saved.") {
        notify("सभासद बील सेव्ह झाले!");
        getbill();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  function handleEnterKey(event, currentIndex) {

    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  useEffect(() => {
    const currentDate1 = new Date(input.date1);
    const dayOfMonth1 = currentDate1.getDate();
    const currentMonth1 = currentDate1.getMonth();
    const currentYear1 = currentDate1.getFullYear();

    let lastDayOfMonth1;

    if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
      lastDayOfMonth1 = 10;
    } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
      lastDayOfMonth1 = 20;
    } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
      lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
    }

    if (lastDayOfMonth1 !== undefined) {
      const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date2: formattedDate2
      }));
    }
    setDataSource([]);
    setTableFilter([]);
  }, [input.date1]);


  useEffect(() => {
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let firstDayOfMonth;
    let lastDayOfMonth;

    if (dayOfMonth >= 1 && dayOfMonth <= 10) {
      firstDayOfMonth = 1;
      lastDayOfMonth = 10;
    } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
      firstDayOfMonth = 11;
      lastDayOfMonth = 20;
    } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
      firstDayOfMonth = 21;
      lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
    }

    if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
      const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
      const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date1: formattedDate1,
        date2: formattedDate2
      }));
    }

    getmaxno();
  }, []);

  const handleCellKeyPress12 = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        if (nextRow && nextRow[1]) { // Use nextRow[1] for column 12
          nextRow[1].focus();
        }
      }
      e.target.blur(); // Remove focus from the cell
    } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
      // Prevent non-numeric input
      if (isNaN(e.key) && e.key !== '.') {
        e.preventDefault();
      }
    }
  };

  const handleChangeactive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowthevcol("Yes");
    }
    else {
      setShowthevcol("No");
    }
  }

  const handleOtheractive = (event) => {
    var checked = event.target.checked;

    if (event.target.checked) {
      setShowother("Yes");
    }
    else {
      setShowother("No");
    }
  }


  const showprint = async () => {

    if (DataSource[0].flag !== "newbill") {
      refprintclose.current.click();
      setWait2(true);
      var mainuser2 = jsEncode.encode("mamapr", 125);

      try {
        const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbillprintvar.php', {
          mainuser: localStorage.getItem('contact'),
          date1: input.date1,
          date2: input.date2,
          mainuser2: mainuser2,
          knofrom: input.knofromprint,
          knoto: input.knotoprint,
          billtype: DataSource[0].flag,
          bcount: DataSource[0].bcount
        });

        if (DataSource && DataSource.length > 0) {
          localStorage.setItem("date1print", input.date1);
          localStorage.setItem("date2print", input.date2);
          localStorage.setItem("billtype", DataSource[0].flag);
          localStorage.setItem("bcount", DataSource[0].bcount);
          localStorage.setItem("customerData", JSON.stringify(response.data));
          localStorage.setItem("kapat", JSON.stringify(kapat));
          localStorage.setItem("showother", showother);
          localStorage.setItem("design", rtype);
          const url = "/billprintvar";
          window.open(url, '_blank');  //'_self'  _blank
        } else {
          notifyerror("या कालावधीमध्ये संकलन उपलब्ध नाही!");
        }
      } catch (error) {
        notifyerror(error);
        // Handle errors here
      } finally {
        setWait2(false);
      }
    }
    else {
      notifyerror("कृपया प्रथम बिल सेव्ह करा!")
      refprintclose.current.click();
    }
  };


  const addOrUpdateCuchal = (kno, cuchal) => {
    setKapat((prevKapat) => {
      const updatedKapat = { ...prevKapat };

      if (updatedKapat[kno]) {
        updatedKapat[kno].cuchal = cuchal;
      } else {
        updatedKapat[kno] = { cuchal, ckhadya: '' };
      }

      return updatedKapat;
    });
  };

  const addOrUpdateCkhadya = (kno, ckhadya) => {
    setKapat((prevKapat) => {
      const updatedKapat = { ...prevKapat };

      if (updatedKapat[kno]) {
        updatedKapat[kno].ckhadya = ckhadya;
      } else {
        updatedKapat[kno] = { cuchal: '', ckhadya };
      }

      return updatedKapat;
    });
  };

  const handleCellKeyPress = (e, rowIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (rowIndex + 1 < cellRefs.current.length) {
        const nextRow = cellRefs.current[rowIndex + 1];
        if (nextRow[0]) {
          nextRow[0].focus();
        }
      }
      e.target.blur(); // Remove focus from the cell
    } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
      // Prevent non-numeric input
      if (isNaN(e.key) && e.key !== '.') {
        e.preventDefault();
      }
    }
  };


  const handleCellChange = (e, rowIndex, kno) => {
    let newValue = e.target.textContent;
    if (newValue) {
    }
    else {
      newValue = 0;
    }
    updatedDataSource = [...DataSource];

    if (updatedDataSource[rowIndex]) {
      updatedDataSource[rowIndex].action = newValue;
      // Calculate the result (column 6 - column 7) and store in column 8
      let column6Value = "";

      if (DataSource[0].flag === "oldbill") {

        column6Value = parseFloat(parseFloat(updatedDataSource[rowIndex].tbaki)) - parseFloat(newValue)
      
      }
      else {

        column6Value = parseFloat(parseFloat(updatedDataSource[rowIndex].cuchal) + parseFloat(updatedDataSource[rowIndex].ckhadya) + parseFloat(parseFloat(updatedDataSource[rowIndex].bcount) > 0 ? parseFloat(updatedDataSource[rowIndex].magilbaki) : parseFloat(updatedDataSource[rowIndex].obal))) - parseFloat(newValue)

      }

      const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;

      updatedDataSource[rowIndex].result = column6Value;//- column7Value;


    }
    setDataSource(updatedDataSource);
    e.target.contentEditable = 'true'; // Re-enable editing
  };

  useEffect(() => {
    // console.log(updatedDataSource)
  }, [updatedDataSource])


  const handleCellBlurkh = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();
    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
      newValue = 0;
    }

  };

  const handleCellfocus = (e, rowIndex) => {
    if (e.target.textContent === "0") {
      e.target.textContent = '';
    }
  }

  const handleCellBlur = (e, rowIndex) => {
    let newValue = e.target.textContent.trim();

    if (!newValue) {
      e.target.textContent = '0'; // Set to '0' if empty
    }

  };



  const handleCellChangekh = (e, rowIndex, kno) => {

    let newValue = e.target.textContent;
    updatedDataSource = [...DataSource];

    if (updatedDataSource[rowIndex]) {
      updatedDataSource[rowIndex].action = newValue;

      // Calculate the result (column 6 - column 7) and store in column 8
      let column6Value = 0;
      if (DataSource[0].flag === "oldbill") {
        column6Value = parseFloat(updatedDataSource[rowIndex].mkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
      }
      else {
        column6Value = parseFloat(updatedDataSource[rowIndex].newkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
      }

      const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;
      updatedDataSource[rowIndex].result2 = column6Value - column7Value;

      if (newValue) {
        addOrUpdateCkhadya(kno, newValue);
        if (DataSource[0].flag === "oldbill") {

          updatedDataSource[rowIndex].adacalculated =
            parseFloat(updatedDataSource[rowIndex].bill) -
            (
              parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
              parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
              (
                (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                  ? parseFloat(updatedDataSource[rowIndex].advedit)
                  : parseFloat(updatedDataSource[rowIndex].advkapat)
              ) +
              (newValue ? parseFloat(newValue) : 0) +
              parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
              parseFloat(updatedDataSource[rowIndex].ikapatbill || 0) -
              parseFloat(updatedDataSource[rowIndex].vah || 0)
            );


        }
        else {
          // console.log("advedit - ", parseFloat(updatedDataSource[rowIndex].advedit));
          updatedDataSource[rowIndex].adacalculated =
            parseFloat(updatedDataSource[rowIndex].bill) -
            (
              parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                (
                  (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                    ? parseFloat(updatedDataSource[rowIndex].advedit) :
                    (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null)
                      ? parseFloat(updatedDataSource[rowIndex].advhpta)
                      : 0
                ) +
                parseFloat(newValue ? parseFloat(newValue) : 0) +
                parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
                parseFloat((updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
                parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
                parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)

              ));
        }
      }
      else {
        addOrUpdateCkhadya(kno, newValue);
        if (DataSource[0].flag === "oldbill") {
          //updatedDataSource[rowIndex].adacalculated = parseFloat(updatedDataSource[rowIndex].bill) - (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) + (parseFloat(updatedDataSource[rowIndex].advedit) ? parseFloat(updatedDataSource[rowIndex].advedit) : parseFloat(updatedDataSource[rowIndex].advkapat ? updatedDataSource[rowIndex].advkapat : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) + parseFloat(updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)));
          updatedDataSource[rowIndex].adacalculated =
            parseFloat(updatedDataSource[rowIndex].bill) -
            (
              parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter) ? parseFloat(updatedDataSource[rowIndex].mhliter) : 0) * parseFloat(updatedDataSource[rowIndex].mhthev ? parseFloat(updatedDataSource[rowIndex].mhthev) : 0)) +
              parseFloat(parseFloat(parseFloat(updatedDataSource[rowIndex].gayliter) ? parseFloat(updatedDataSource[rowIndex].gayliter) : 0) * parseFloat(updatedDataSource[rowIndex].gaythev ? parseFloat(updatedDataSource[rowIndex].gaythev) : 0)) +
              (
                (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                  ? parseFloat(updatedDataSource[rowIndex].advedit)
                  : parseFloat(updatedDataSource[rowIndex].advkapat)
              ) +
              parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
              parseFloat(updatedDataSource[rowIndex].ikapatbill || 0) -
              parseFloat(updatedDataSource[rowIndex].vah || 0)
            );
        }
        else {
          updatedDataSource[rowIndex].adacalculated =
            parseFloat(updatedDataSource[rowIndex].bill) -
            (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
              parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
              (
                (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                  ? parseFloat(updatedDataSource[rowIndex].advedit)
                  :
                  (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null)
                    ? parseFloat(updatedDataSource[rowIndex].advhpta)
                    : parseFloat(updatedDataSource[rowIndex].advkapat)
              ) +
              parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
              parseFloat((updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) -
              parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].mhliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].mhliter) : 0) -
              parseFloat(updatedDataSource[rowIndex].gayvah && updatedDataSource[rowIndex].gayliter ? parseFloat(updatedDataSource[rowIndex].gayvah * updatedDataSource[rowIndex].gayliter) : 0)

            ));
        }
      }

      if (!newValue) {
        newValue = 0;
      }
      updatedDataSource[rowIndex].khedit = newValue;
      setDataSource(updatedDataSource);
    }

    e.target.contentEditable = 'true'; // Re-enable editing
  };


  async function getmaxno() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    props.setProgress(30);
    setTimeout(() => {
      props.setProgress(50);
    }, 200);
    axios.post('https://idairy.co.in/dairy/php/cust/getmaxno.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {
        setInput(prevInput => ({
          ...prevInput,
          knofrom: "1",
          knoto: response.data,
          knofromprint: "1",
          knotoprint: response.data
        }))
      }
      else {
        setInput(prevInput => ({
          ...prevInput,
          knofrom: "0",
          knoto: "0"
        }))
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const getbill = async () => {
    setWait(true);
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      quin: input.knofrom,
      rate: input.knoto,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)

    try {
      const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbillvar.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data.length > 0) {
      
        setDataSource(response.data);

      
        response.data.map((user, index) => {
          addOrUpdateCuchal(user.kno, user.advhpta);
          addOrUpdateCkhadya(user.kno, user.khadyahpta);
        });

        setWait(false);
      } else {
        notifyerror("या कालावधीमध्ये संकलन उपलब्ध नाही!");
        setWait(false);
      }
    } catch (error) {
      console.log(error);
      setWait(false);
    }
  };


  const deletebill = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.knofrom === "" || input.knoto === "") {
      notifyerror("सभासद क्रमांक भरा");
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/deletebillvar.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data.trim() === "Deleted") {
        notify("सभासद बील डिलीट झाले!");
        refdeleteclose.current.click();
        setDataSource([]);

      }

    }).catch(err => {
      console.log(err);
    });
  }

  const handleedit = (sr, date, time, kno, name, type, liter, fat, snf, rate, amt) => {
    // setInput({ date: date, time: time, kno: kno, name: name, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })
    // setNewedit("edit");
    // setSredit(sr);
  }
  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }));
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };




  return (
    <>

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refprint}
        data-bs-target="#printinput"
      >
      </button>

      <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="exampleModalLabel">प्रिंट साठी खाते क्रमांक निवडा</h5>
              <div className='helpmain'>
                <img className='smallimg' src={Status} alt="" />
                <input onChange={handleChange} type="number" className="form-control" id="knofromprint" name="knofromprint" value={input.knofromprint} autoComplete='off' />
                <span> ते </span>
                <input onChange={handleChange} type="number" className="form-control" id="knotoprint" name="knotoprint" value={input.knotoprint} autoComplete='off' />
              </div>
              <div hidden className="radiotheme">
                <div className="form-check">
                  <input onClick={handlertype} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                  <label className="form-check-label radiolabel" htmlFor="radio2">
                    बिल डिझाईन 1
                  </label>
                </div>
                <div className="form-check">
                  <input onClick={handlertype} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                  <label className="form-check-label radiolabel" htmlFor="radio1">
                    बिल डिझाईन 2
                  </label>
                </div>
              </div>
              <div className="form-check check">
                <input className="form-check-input" type="checkbox" defaultChecked value="" onChange={handleOtheractive} id="activeother" />
                <span className="checklbl" htmlFor="activeother">
                  इतर कपात ऑप्शन्स प्रिंटवर दाखवा.
                </span>
              </div>
              <span className='note'>सूचना : बिल प्रिंट्स देताना पेपर साईज A5 सेट असल्याची खात्री करा!</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
              <button type="button" className="btn btn-danger" onClick={showprint}>ओके</button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refdelete}
        data-bs-target="#deletebill"
      >
      </button>

      <div className="modal fade" id="deletebill" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">बील डिलीट करायचे?</h4>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
              <button type="button" className="btn btn-danger" onClick={deletebill}>होय</button>
            </div>
          </div>
        </div>
      </div>
      <div className="sanklanmain">
        <div className="firstrowsank">
          <img onClick={props.visibility} className='back' src={back} alt="" />
          <div className="colmainbill">
            <div className="firstrowbill">
              <div className="rowbill">
                <div className="mb-2">
                  <span className='subheadsank'>दिनांक var</span>
                  <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2">
                  <span className='subheadsank'>दिनांक</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill se'> || </span>

                <div className="mb-2 se">
                  <span className='subheadsank'>खाते क्र.</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                </div>

                <span className='subheadbill'>ते</span>

                <div className="mb-2">
                  <span className='subheadsank'>खाते क्र</span>
                  <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                  {
                    wait ?
                      <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">Loading..</button>
                      :
                      <button onClick={getbill} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">बील पहा</button>
                  }
                  {
                    DataSource && DataSource.length === 0 ?
                      null :
                      (
                        <button onClick={fetchtabledata} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='save' className="btn btn-primary mx-1 sanksmall">
                          {
                            DataSource[0].flag === "oldbill" ? "बील अपडेट" :
                              "बील सेव्ह"
                          }
                        </button>
                      )
                  }
                  {
                    DataSource && DataSource.length === 0 ?
                      null :
                      (
                        wait2 ? (
                          <button ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">लोडिंग...</button>
                        ) : (
                          <button onClick={handlePrint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">बील प्रिंट</button>
                        )
                      )
                  }
                </div>
              </div>
              <div className="optionbill">
                <div className='newbill'>
                  <span>{DataSource && DataSource.length === 0 ? "नवीन बील" : DataSource[0].flag === "oldbill" ? "जूने बील" :
                    "नवीन बील"}</span>
                  {DataSource && DataSource.length === 0 ?
                    '' :
                    DataSource[0].flag === "oldbill" ?
                      DataSource[0].lastbill === "Yes" ?
                        <UilTrash onClick={handleDelete} className="icdelete" />
                        : ''
                      : ''}
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" onChange={handleChangeactive} id="activestatus" />
                  <span className="checklbl" htmlFor="activestatus">
                    सर्व कॉलम्स
                  </span>
                </div>
                <div hidden className="form-check">
                  <input className="form-check-input" type="checkbox" value="" onChange={handleChangeactive} id="activestatus" />
                  <span className="checklbl" htmlFor="activestatus">
                    ठेव अधिक कॉलम्स
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="firstrow2">
          <div class="table-containerbill">
            <table id="myTablebill" className="table table-bordered">
              <thead>
                <tr>
                  <th className='thsmall' scope="col">खाते क्रमांक</th>
                  <th className='thlarge' scope="col">नाव</th>
                  <th className='thsmall' scope="col">बील रक्कम</th>
                  <th className='thsmallhide' scope="col">मागील ठेव</th>
                  <th className='thsmallhide' scope="col">चालू ठेव</th>
                  <th className='thsmallhide' scope="col">नवीन ठेव</th>
                  <th className='thsmallhide' scope="col">मागील बाकी</th>
                  <th className='thsmallhide' scope="col">चालू खाद्य</th>
                  <th className='thsmallhide' scope="col">चालू एडव्हांस</th>
                  <th className='thsmall' scope='col'> एकूण बाकी</th>
                  <th className='thsmall' scope="col"><UilEditAlt className="editlogo" />कपात रक्कम<br /><span>(-)</span></th>
                  <th className='thsmallhide' scope='col'>येणेबाकी</th>
                  <th className={'thsmallhide'} scope="col">इ.कपात/ली<br /><span>(-)</span></th>
                  <th className={'thsmallhide'} scope="col">इ.कपात/बील<br /><span>(-)</span></th>
                  <th className={'thsmallhide'} scope="col">वाहतूक<br /><span>(+)</span></th>

                </tr>
              </thead>
              <tbody>
                {DataSource && DataSource.map((user, index) => {
                  let tkapat = user.flag === "oldbill" ?
                    (user.tkapat ? user.tkapat : 0) :
                    parseFloat(parseFloat(user.bill) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev))) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                      + parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                    ) < parseFloat(user.cuchal) + parseFloat(user.ckhadya) + parseFloat(parseFloat(user.bcount) > 0 ? parseFloat(user.magilbaki) : parseFloat(user.obal))
                      ?
                      parseFloat(parseFloat(user.bill) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev))) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                        + parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                      ).toFixed(2)
                      :
                      parseFloat(parseFloat(user.cuchal) + parseFloat(user.ckhadya) + parseFloat(parseFloat(user.bcount) > 0 ? parseFloat(user.magilbaki) : parseFloat(user.obal))).toFixed(2)

                  updatedDataSource = [...DataSource];
                  if (updatedDataSource[index]) {

                    if (first === "false") {
                      // console.log("in first time");

                      updatedDataSource[index].tkapat = tkapat;
                      setfirst("true")
                    }
                  }
                  return (
                    <tr key={index}>
                      <td className='thsmall'>{user.kno}</td>
                      <td className='thsmall'>{user.name}</td>
                      <td className='thsmall2'>{
                        user.flag === "oldbill" ?
                          parseFloat(user.bill).toFixed(2)
                          :
                          parseFloat(parseFloat(user.bill) - parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev))) + parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                            + parseFloat((user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter) : 0) + parseFloat((user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)))
                          ).toFixed(2)
                      }</td>

                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.magilthev ? parseFloat(user.magilthev).toFixed(2) : 0) :
                            parseFloat(user.bcount) > 0 ? parseFloat(user.newthev).toFixed(2) : parseFloat(user.othev).toFixed(2)
                        }</td>

                      <td className='thsmallhide'>
                        {
                          user.flag === "oldbill" ?
                            (user.cthev ? parseFloat(user.cthev).toFixed(2) : 0) :
                            parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)).toFixed(2)
                        }</td>

                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            parseFloat(user.newthev) ? parseFloat(user.newthev).toFixed(2) : 0
                            :
                            parseFloat(parseFloat(parseFloat(user.mhliter * user.mhthev) + parseFloat(user.gayliter * user.gaythev)) + (parseFloat(user.bcount) > 0 ? parseFloat(user.newthev) : parseFloat(user.othev))).toFixed(2)
                        }</td>

                      <td className='thsmallhide'>
                        {
                          user.flag === "oldbill" ?
                            parseFloat((user.magilbaki ? parseFloat(user.magilbaki).toFixed(2) : 0)).toFixed(2) :
                            parseFloat(parseFloat(user.bcount) > 0 ? parseFloat(user.magilbaki) : parseFloat(user.obal)).toFixed(2)
                        }</td>

                      <td className='thsmallhide'>
                        {
                          user.flag === "oldbill" ?
                            parseFloat((user.ckhadya ? parseFloat(user.ckhadya).toFixed(2) : 0)).toFixed(2) :
                            parseFloat((user.ckhadya ? parseFloat(user.ckhadya).toFixed(2) : 0)).toFixed(2)
                        }</td>

                      <td className='thsmallhide'>
                        {
                          user.flag === "oldbill" ?
                            parseFloat((user.cadv ? parseFloat(user.cadv).toFixed(2) : 0)).toFixed(2) :
                            parseFloat((user.cuchal ? parseFloat(user.cuchal).toFixed(2) : 0)).toFixed(2)
                        }</td>
                      <td className='thsmall2'>
                        {
                          user.flag === "oldbill" ?
                            (user.tbaki ? parseFloat(user.tbaki).toFixed(2) : 0) :
                            parseFloat(parseFloat(user.cuchal) + parseFloat(user.ckhadya) + parseFloat(parseFloat(user.bcount) > 0 ? parseFloat(user.magilbaki) : parseFloat(user.obal))).toFixed(2)
                        }</td>

                      <td className='thsmall2edit'
                        contentEditable={
                          user.flag === "oldbill" ?
                            user.lastbill === "Yes" ?
                              'true'
                              : 'false'
                            : 'true'
                        }
                        onKeyDown={(e) => handleCellKeyPress(e, index)}
                        onBlur={(e) => handleCellBlur(e, index)}
                        onFocus={(e) => handleCellfocus(e, index)}
                        ref={(td) => {
                          if (!cellRefs.current[index]) {
                            cellRefs.current[index] = [];
                          }
                          cellRefs.current[index][0] = td; // Save the ref for the cell
                        }}
                        onInput={(e) => handleCellChange(e, index, user.kno)}>
                        {
                          user.flag === "oldbill" ?
                            parseFloat(user.tkapat).toFixed(2)
                            :
                            tkapat
                        }
                      </td>

                      <td className='thsmallhide'>
                        {
                          user.flag === "oldbill"
                            ?
                            user.result != null && !isNaN(user.result) ? parseFloat(user.result).toFixed(2) : user.yenebaki != null && !isNaN(user.yenebaki) ? parseFloat(user.yenebaki).toFixed(2) : 0
                            :
                            user.result != null && !isNaN(user.result) ? parseFloat(user.result).toFixed(2)
                              :
                              parseFloat(parseFloat(parseFloat(user.cuchal) + parseFloat(user.ckhadya) + parseFloat(parseFloat(user.bcount) > 0 ? parseFloat(user.magilbaki) : parseFloat(user.obal))) - parseFloat(tkapat)).toFixed(2)
                        }</td>

                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.ikapatliter ? parseFloat(user.ikapatliter) : 0) :
                            (user.ikapatliter2 && user.tliter ? parseFloat(user.ikapatliter2 * user.tliter).toFixed(2) : 0)
                        }
                      </td>
                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.ikapatbill ? parseFloat(user.ikapatbill) : 0) :
                            (user.ikapatbill2 ? parseFloat(user.ikapatbill2) : 0)
                        }
                      </td>
                      <td className={'thsmallhide'}>
                        {
                          user.flag === "oldbill" ?
                            (user.vah ? parseFloat(user.vah) : 0) :
                            parseFloat(user.vah2 && user.mhliter ? parseFloat(user.vah2 * user.mhliter).toFixed(2) : 0) + parseFloat(user.gayvah && user.gayliter ? parseFloat(user.gayvah * user.gayliter).toFixed(2) : 0)
                        }
                      </td>

                    </tr>
                  );
                }
                )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default Billvar
