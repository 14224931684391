import React, { useContext, useEffect, useRef, useState } from 'react'
import './Newkendra.css'
import { useLocation, useNavigate } from 'react-router-dom';
import back from "../../imgs/backexam.png";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UilEditAlt, UilTrash } from '@iconscout/react-unicons'
import { Modal } from "bootstrap";
import Globalcontext from '../../Context/Globalcontext';

const Newkendra = (props) => {
    const ref = useRef(null);
    const ref2 = useRef(null);
    const refclear = useRef(null);
    const reffileclear = useRef(null);
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const [showreport, setShowreport] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { submenu, setsubmenu, newcustsource, setNewcustsource, kendrasource, setKendrasource } = useContext(Globalcontext);
    const navigate = useNavigate();
    const inputRefs = useRef([]);

    const closeModal = () => {
        setShowModal(false);
    };

    const [imageurl, setImageUrl] = useState();
    const [input, setInput] = useState({ sr: "", cname: "", mobno: "" });
    const [mastermenu, setMastermenu] = useState(true);
    const [newedit, setNewedit] = useState("new");
    const [value, setValue] = useState('');
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [type, setType] = useState({});
    const [contactdelete, setContactdelete] = useState({});

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);

    const clearAll = () => {
        setInput({ sr: "", cname: "", mobno: "" });
    }

    const handleReport = () => {

        setShowreport(true);
        getcustomers();

    }

    const closereport = () => {
        setShowreport(false);
    }

    useEffect(() => {
        if (showreport) {
            getcustomers();
        }

    }, [showreport])

    async function getcustomers() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getkendra.php', { mainuser: localStorage.getItem("contact"),mainuser2:mainuser2 }).then(function sample(response) {

            if (response.data) {


                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getfirstkendra() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getfirstkendra.php', { mainuser: localStorage.getItem("contact"),mainuser2:mainuser2}).then(function sample(response) {

            if (response.data) {
                response.data.map((user, index) =>
                 handleedit(user.sr, user.name, user.mobno)
            )
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }

    const handledelete = (contact) => {

        refdelete.current.click();

        const name = "contact";
        const valuecurrent = contact;

        setContactdelete(value => ({ ...value, [name]: valuecurrent }));
    }

    const deleteAccount = () => {

        axios.post('https://idairy.co.in/iniqtest/cust/deletlator.php', contactdelete).then(function sample(response) {

            if (response.data) {
                if (response.data === "Deleted") {
                    notify("Account Deleted Successfully!");
                    refdeleteclose.current.click();
                }

            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handlesubmit = async (event) => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        event.preventDefault();

        let rawData = {
            sredit: input.sr,
            name: input.cname,
            mobno: input.mobno,
            mainuser: localStorage.getItem("contact"),
            mainuser2:mainuser2,
            flag: newedit
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/createkendra.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
          //  console.log(response.data);
            if (response.data === "Successfully saved.") {
                notify("केंद्र तयार झाले!");
                localStorage.setItem('kendra', input.cname);
                localStorage.setItem("kmobno", input.mobno);
                props.setKendraValue(localStorage.getItem("kendra"));
            }
            else if (response.data === "Mobile Number Exists") {
                notifyerror("Mobile Number Already Exist!");

            }
            else if (response.data === "Successfully Updated.") {
                notify("केंद्र माहिती अपडेट झाली!");
                localStorage.setItem('kendra', input.cname);
                localStorage.setItem("kmobno", input.mobno);
                props.setKendraValue(localStorage.getItem("kendra"));
               
            }
            else if (response.data === "Limit Finished!") {
                notifyerror("आपले केंद्र लिमिट संपले आहे.");
                setInput({ cname: "", mobno: "" });
                setNewedit("new");
                clearAll();

            }
            else if (response.data === "Incomplete information!") {
                notifyerror("Incomplete information!");

            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (sr, name, mobno) => {
        setInput({ sr: sr, cname: name, mobno: mobno });
        setShowreport(false);
        setNewedit("edit");

    }


    function getRandomNumber() {
        let min = 1000;
        let max = 9999;
        return Math.round(Math.random() * (max - min) + min);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        if (name === "contact") {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value)) {
                valuecurrent = event.target.value;
                setInput(value => ({ ...value, [name]: valuecurrent }))

            }
        }
        else {
            if (name === "img") {
                valuecurrent = event.target.files[0].name;
            }
            else {
                valuecurrent = event.target.value;
            }
            setInput(value => ({ ...value, [name]: valuecurrent }))

        }
    }

    const filteData = (e) => {
        if (e.target.value != "") {
            setValue(e.target.value);
            const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
            ));

            setTableFilter([...filtertable]);
        }
        else {
            setValue(e.target.value);
            setTableFilter([...DataSource]);
        }
    }

    const handleBack = () => {

        if (kendrasource === "Dashboard") {
            navigate("/Dashboard");
            setNewcustsource("");
            setKendrasource("");
            props.visibility();
        }
        else{
            props.visibility();
        }
    };


    useEffect(() => {
        getfirstkendra();

        const modalElement = refdelete.current;
        const modal = new Modal(modalElement);

        const handleModalHide = () => {
            modal.show();
        };

        modalElement.addEventListener("hide.bs.modal", handleModalHide);

        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
          }

        return () => {
            modalElement.removeEventListener("hide.bs.modal", handleModalHide);
        };

       

        
    }, []);

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            handlesubmit();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace")
        {
          if(event.target.tagName === "BUTTON")
          {
            if (inputRefs.current[1]) {
              inputRefs.current[1].focus();
            }
          }
        }
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
              (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
              (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
            ))
          )) {
            event.preventDefault();
      
            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
              const prevElement = inputRefs.current[prevIndex];
              if (prevElement) {
                if (
                  (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                  prevElement !== event.target
                ) {
                  prevElement.focus();
                  break;
                } else {
                  prevIndex--;
                }
              } else {
                prevIndex--;
              }
            }
          }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };
    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden={true}
                ref={refdelete}
                data-bs-target="#exampleModaldelete"
            >
            </button>

            <div
                className="modal fade"
                id="exampleModaldelete"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static" // Disable clicks outside the modal
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                ref={refdeleteclose}
                                aria-label="Close"
                            >
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">Delete This Account?</h4>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={handledeleteclose}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={deleteAccount}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`report ${showreport ? '' : 'hidden'}`}>
                <div className="buttonsreport">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {props.type} Report
                    </h5>
                    <input type="text" className="form-control search mx-3" id="search" name="search" placeholder='Search' value={value} onChange={filteData} autoComplete='off' />

                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn btn-primary mb-3 my-3"
                        table="table-to-xls"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Export" />

                    <button
                        type="button"
                        className="btn-close"
                        onClick={closereport}
                        aria-label="Close"
                    ></button>
                </div>

                <table id="table-to-xls" className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">केंद्र नंबर</th>
                            <th scope="col">नाव</th>
                            <th scope="col">मोबाईल</th>


                        </tr>
                    </thead>
                    <tbody>
                        {value.length > 0 ? tableFilter.map((user, index) =>
                            <tr key={index}>
                                <td>{user.sr}</td>
                                <td>{user.name}</td>
                                <td>{user.mobno}</td>
                                <td><UilEditAlt onClick={() => { handleedit(user.sr, user.name, user.mobno) }} className="ic" /><UilTrash onClick={() => { handledelete(user.contact) }} v className="ic" /></td>
                            </tr>
                        )
                            :
                            DataSource.map((user, index) =>
                                <tr key={index}>
                                    <td>{user.sr}</td>
                                    <td>{user.name}</td>
                                    <td>{user.mobno}</td>
                                    <td><UilEditAlt onClick={() => { handleedit(user.sr, user.name, user.mobno) }} className="ic" /><UilTrash onClick={() => { handledelete(user.contact) }} v className="ic" /></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>

            </div>
            <div className={`backmain ${showreport ? 'hidden' : ''}`}>
                <img onClick={handleBack} className='back' src={back} alt="" />
                <div className="custbox">
                    <form>

                        <div className="mb-2">
                            <span className='subhead'> केंद्राचे नाव</span>
                            <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="text" className={newedit === "new" ? "form-control" : "form-control editmode"} id="cname" name="cname" value={input.cname} autoComplete='off' />
                        </div>
                        <div className="mb-2">
                            <span className='subhead'> मोबाईल क्रमांक</span>
                            <input onChange={handleChange} onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} id="mobno" name="mobno" value={input.mobno} autoComplete='off' />
                        </div>


                        <div className='row1form'>
                            <button onClick={handlesubmit} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[2] = ref)} type="submit" className="btn btn-primary my-3 mx-1">

                                {newedit === "new" ?
                                    "सेव्ह" :
                                    "अपडेट"
                                }

                            </button>
                            <button type='button' onClick={handleReport} className="btn btn-primary my-3 mx-1">केंद्र यादी</button>


                        </div>

                    </form>
                </div>


            </div>
            <ToastContainer />
        </>
    );
};

export default Newkendra;
