import React, { useEffect, useState } from 'react';
import './Pregisterprint.css';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";

// Register the font using the imported variable
Font.register({
  family: 'Lato-Italic',
  src: LatoItalicFont, // Use the imported font variable
});


// ... (other imports and Font registration)

const Pregisterprint = (props) => {
  const [customerData, setCustomerData] = useState({});
  const [literTotal, setLiterTotal] = useState(0);
  const [amtTotal, setAmtTotal] = useState(0);
  let totals = {};


  const calculateSumOfAmtColumns = (tableDataArray) => {
    const sums = Array.from({ length: 10 }, () => 0); // Initialize an array with 10 zeros

    // Loop through the four tables and calculate sums for each record
    for (let tableIndex = 0; tableIndex < 4; tableIndex++) {
      const tableDataWithZeros = tableDataArray[tableIndex];

      tableDataWithZeros.forEach((entry, entryIndex) => {
        sums[entryIndex] += parseFloat(entry.amt);
      });
    }

    return sums;
  };

  useEffect(() => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    let rawData = {
      date1: localStorage.getItem("date1print"),
      date2: localStorage.getItem("date2print"),
      mainuser: localStorage.getItem("contact"),
      mobileuser: localStorage.getItem("mobileuser"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getpregister.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      // console.log(response.data);
      setCustomerData(response.data);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  // Function to generate a date range between date1 and date2
  const getDatesInRange = (date1, date2) => {
    const dates = [];
    const currentDate = new Date(date1);

    while (currentDate <= new Date(date2)) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  // Function to get all unique 'type' and 'time' combinations
  const getUniqueTypeTimeCombinations = () => {
    const combinations = [];

    Object.keys(customerData).forEach(kno => {
      customerData[kno].forEach(entry => {
        const combination = `${entry.type}-${entry.time}`;
        if (!combinations.includes(combination)) {
          combinations.push(combination);
        }
      });
    });

    return combinations;
  };

  const getFirstAndLastWord = (text) => {
    // Trim any leading or trailing spaces
    let words = "";
    if (text) {
       words = text.trim().split(/\s+/); // Split by one or more whitespace characters
    }
    // Check if there are words present
    if (words.length === 0) {
      return ''; // Return an empty string if no words are present
    } else if (words.length === 1) {
      return words[0]; // Return the single word if only one is present
    } else {
      return `${words[0]} ${words[words.length - 1]}`; // Return first and last word
    }
  };


  const getTableDataWithZeros = (tableData, date1, date2, type, time) => {
    if (!Array.isArray(tableData)) {
      return Array.from({ length: getDatesInRange(date1, date2).length }, (_, index) => ({
        sr: '',
        date: getDatesInRange(date1, date2)[index],
        time: time,
        kno: '',
        type: type,
        liter: '0',
        fat: '0',
        snf: '0',
        rate: '0',
        amt: '0.00',
        date1: date1,
        date2: date2,
        kendra: '',
      }));
    }

    const dateRange = getDatesInRange(date1, date2);
    const tableWithData = [];

    dateRange.forEach(date => {
      const matchingEntry = tableData.find(
        entry => entry.date === date && entry.type === type && entry.time === time
      );

      if (matchingEntry) {
        tableWithData.push(matchingEntry);
      } else {
        // Create a record with zero values
        tableWithData.push({
          sr: '',
          date: date,
          time: time,
          kno: '',
          type: type,
          liter: '0',
          fat: '0',
          snf: '0',
          rate: '0',
          amt: '0.00',
          date1: date1,
          date2: date2,
          kendra: '',
        });
      }
    });

    return tableWithData;
  };

  function formatDate(inputDate) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(inputDate).toLocaleDateString('en-US', options);
  }

  if (localStorage.getItem('pregister1') === "1") {
    if (Array.isArray(customerData) && customerData.length > 0) {
      totals = {
        tliter: customerData.reduce((total, rowData) => total + parseFloat(rowData.tliter), 0),
        billamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.billamt), 0),
        magilthev: customerData.reduce((total, rowData) => total + parseFloat(rowData.magilthev), 0),
        cthev: customerData.reduce((total, rowData) => total + parseFloat(rowData.cthev), 0),
        newthev: customerData.reduce((total, rowData) => total + parseFloat(rowData.newthev), 0),
        madv: customerData.reduce((total, rowData) => total + parseFloat(rowData.madv), 0),
        cadv: customerData.reduce((total, rowData) => total + parseFloat(rowData.cadv), 0),
        tadv: customerData.reduce((total, rowData) => total + parseFloat(rowData.tadv), 0),
        advkapat: customerData.reduce((total, rowData) => total + parseFloat(rowData.advkapat), 0),
        newadv: customerData.reduce((total, rowData) => total + parseFloat(rowData.newadv), 0),
        mkhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.mkhadya), 0),
        ckhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.ckhadya), 0),
        tkhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.tkhadya), 0),
        khadyakapat: customerData.reduce((total, rowData) => total + parseFloat(rowData.khadyakapat), 0),
        newkhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.newkhadya), 0),
        ikapatliter: customerData.reduce((total, rowData) => total + parseFloat(rowData.ikapatliter), 0),
        ikapatbill: customerData.reduce((total, rowData) => total + parseFloat(rowData.ikapatbill), 0),
        vah: customerData.reduce((total, rowData) => total + parseFloat(rowData.vah), 0),
        ada: customerData.reduce((total, rowData) => {
          if (parseFloat(rowData.ada) > 0) {
            total += parseFloat(rowData.ada);
          }
          return total;
        }, 0)
      };
    }
  }
  else {
    if (Array.isArray(customerData) && customerData.length > 0) {
      totals = {
        gayliter: customerData.reduce((total, rowData) => parseFloat(rowData.tgayliter), 0),
        gayamt: customerData.reduce((total, rowData) => parseFloat(rowData.tgayamt), 0),
        mhliter: customerData.reduce((total, rowData) => parseFloat(rowData.tmhliter), 0),
        mhamt: customerData.reduce((total, rowData) => parseFloat(rowData.tmhamt), 0),
        tliter: customerData.reduce((total, rowData) => parseFloat(rowData.ttliter), 0),
        tamt: customerData.reduce((total, rowData) => parseFloat(rowData.tbillamt), 0),
        cthev: customerData.reduce((total, rowData) => total + parseFloat(rowData.cthev), 0),
        ckhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.ckhadya), 0),
        cadv: customerData.reduce((total, rowData) => total + parseFloat(rowData.cadv), 0),
        khadyakapat: customerData.reduce((total, rowData) => total + parseFloat(rowData.khadyakapat), 0),
        advkapat: customerData.reduce((total, rowData) => total + parseFloat(rowData.advkapat), 0),
        newkhadya: customerData.reduce((total, rowData) => total + parseFloat(rowData.newkhadya), 0),
        newadv: customerData.reduce((total, rowData) => total + parseFloat(rowData.newadv), 0),
        ada: customerData.reduce((total, rowData) => total + parseFloat(rowData.ada), 0),

      };
    }
  }



  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  const recordsPerPage = 14;
  const recordsPerPage2 = 30;
  const totalPages = Math.ceil(customerData.length / recordsPerPage);
  const totalPages2 = Math.ceil(customerData.length / recordsPerPage2);


  return (
    <>
      {
        localStorage.getItem('pregister1') === "1"
          ?
          <PDFViewer className='pdf'>
            <Document>
              {customerData.length > 0 && (
                Array.from({ length: Math.ceil(customerData.length / recordsPerPage) }).map((_, pageIndex) => (
                  <Page size="A4" orientation="landscape" style={{ marginTop: 20, marginLeft: 5, marginRight: 5 }} key={pageIndex}>
                    <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                    <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>

                    <View style={styles.table}>
                      {/* Table Header */}
                      <View style={styles.tableRow2}>
                        <Text style={styles.tableHeader}>{"खाते क्र"}</Text>
                        <Text style={styles.tableHeader}>{"नाव"}</Text>
                        <Text style={styles.tableHeader}>{"ए.लिटर"}</Text>
                        <Text style={styles.tableHeader}>{"ए.बील"}</Text>
                        <Text style={styles.tableHeader}>{"मा.ठेव"}</Text>
                        <Text style={styles.tableHeader}>{"ठेव"}</Text>
                        <Text style={styles.tableHeader}>{"ए.ठेव"}</Text>
                        <Text style={styles.tableHeader}>{"मा.एड्व्हांस बाकी"}</Text>
                        <Text style={styles.tableHeader}>{"चालू एड्व्हांस"}</Text>
                        <Text style={styles.tableHeader}>{"ए.एड्व्हांस"}</Text>
                        <Text style={styles.tableHeader}>{"एड्व्हांस कपात"}</Text>
                        <Text style={styles.tableHeader}>{"नवीन एड्व्हांस बाकी"}</Text>
                        <Text style={styles.tableHeader}>{"मा.खाद्य बाकी"}</Text>
                        <Text style={styles.tableHeader}>{"चालू खाद्य"}</Text>
                        <Text style={styles.tableHeader}>{"ए.खाद्य"}</Text>
                        <Text style={styles.tableHeader}>{"खाद्य कपात"}</Text>
                        <Text style={styles.tableHeader}>{"नवीन खाद्य बाकी"}</Text>
                        <Text style={styles.tableHeader}>{"इ.कपात/ली"}</Text>
                        <Text style={styles.tableHeader}>{"इ.कपात/बील"}</Text>
                        <Text style={styles.tableHeader}>{"वाहतूक"}</Text>
                        <Text style={styles.tableHeaderlast}>{"अदा"}</Text>
                      </View>
                      {customerData.slice(pageIndex * recordsPerPage, (pageIndex + 1) * recordsPerPage).map((rowData, index) => (
                        <View key={index} style={styles.tableRow}>
                          <Text style={styles.tableCell}>{rowData.kno}</Text>
                          <Text style={styles.tableCell}>{getFirstAndLastWord(rowData.cname)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.tliter).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.billamt).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.magilthev).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.cthev).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.newthev).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.madv).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.cadv).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.tadv).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.advkapat).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.newadv).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.mkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.ckhadya).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.tkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.khadyakapat).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.newkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.ikapatliter) ? parseFloat(rowData.ikapatliter) : 0}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.ikapatbill) ? parseFloat(rowData.ikapatbill) : 0}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.vah) ? parseFloat(rowData.vah) : 0}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.ada) ? parseFloat(rowData.ada) : 0}</Text>

                        </View>
                      ))}
                    </View>

                    {pageIndex === totalPages - 1 && ( // Render total row only on the last page
                      <View style={styles.table}>
                        <View style={styles.tableRow3}>
                          <Text style={styles.tableHeader}>{"एकूण"}</Text>
                          <Text style={styles.tableHeader}>{"-"}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.tliter).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.billamt).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.magilthev).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.cthev).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.newthev).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.madv).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.cadv).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.tadv).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.advkapat).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.newadv).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.mkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.ckhadya).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.tkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.khadyakapat).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.newkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.ikapatliter).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.ikapatbill).toFixed(2)}</Text>
                          <Text style={styles.tableHeader}>{parseFloat(totals.vah).toFixed(2)}</Text>
                          <Text style={styles.tableHeaderlast}>{parseFloat(totals.ada).toFixed(2)}</Text>
                        </View>
                      </View>
                    )}

                  </Page>
                ))
              )}
            </Document>
          </PDFViewer >
          :
          <PDFViewer className='pdf'>
            <Document>
              {customerData.length > 0 && (
                Array.from({ length: Math.ceil(customerData.length / recordsPerPage2) }).map((_, pageIndex) => (
                  <Page size="A4" style={{ marginTop: 20, marginLeft: 5, marginRight: 5 }} key={pageIndex}>
                    <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                    <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>

                    <View style={styles.table}>
                      {/* Table Header */}
                      <View style={styles.tableRow2}>
                      <Text style={styles.tableHeader}>{"क्र"}</Text>
                        <Text style={styles.tableHeader}>{"खाते क्र"}</Text>
                        <Text style={styles.tableHeader}>{"नाव"}</Text>
                        <Text style={styles.tableHeader}>{"ए.लिटर"}</Text>
                        <Text style={styles.tableHeader}>{"ए.रक्कम"}</Text>
                        <Text style={styles.tableHeader}>{"चालू ठेव"}</Text>
                        <Text style={styles.tableHeader}>{"एकूण ठेव"}</Text>
                        <Text style={styles.tableHeader}>{"चालू एडव्हांस"}</Text>
                        <Text style={styles.tableHeader}>{"एडव्हांस बाकी"}</Text>
                        <Text style={styles.tableHeader}>{"चालू खाद्य"}</Text>
                        <Text style={styles.tableHeader}>{"खाद्य बाकी"}</Text>
                        <Text style={styles.tableHeader}>{"अदा"}</Text>
                        <Text style={styles.tableHeader}>{"सही"}</Text>

                      </View>
                      {customerData.slice(pageIndex * recordsPerPage2, (pageIndex + 1) * recordsPerPage2).map((rowData, index) => (
                        <View key={index} style={styles.tableRow}>
                          <Text style={styles.tableCell}>{pageIndex * recordsPerPage2 + index + 1}</Text>
                          <Text style={styles.tableCell}>{rowData.kno}</Text>
                          <Text style={styles.tableCell}>{getFirstAndLastWord(rowData.cname)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.tliter).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.billamt).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.cthev).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.newthev).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.cadv).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.newadv).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.ckhadya).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.newkhadya).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{parseFloat(rowData.ada).toFixed(2)}</Text>
                          <Text style={styles.tableCell}>{ }</Text>

                        </View>
                      ))}
                    </View>

                    {pageIndex === totalPages2 - 1 && ( // Render total row only on the last page
                      <View style={styles.bottomrow}>

                        <View style={styles.box1}>
                          <View style={styles.bottomtitlerow}>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { lineHeight: 18 }]}>{"एकूण गाय लि   : "}</Text>
                            </View>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { textAlign: 'right', lineHeight: 18 }]}>{parseFloat(totals.gayliter).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण म्हैस लि   : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.mhliter).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण लि   : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.tliter).toFixed(2)}</Text>
                            </View>
                          </View>
                        </View>


                        <View style={styles.box2}>
                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { lineHeight: 18 }]}>{"एकूण गाय रक्कम  : "}</Text>
                            </View>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { textAlign: 'right', lineHeight: 18 }]}>{parseFloat(totals.gayamt).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण म्हैस रक्कम   : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.mhamt).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण रक्कम  : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.tamt).toFixed(2)}</Text>
                            </View>
                          </View>
                        </View>


                        <View style={styles.box2}>
                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { lineHeight: 18 }]}>{"ए.चालू ठेव   : "}</Text>
                            </View>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { textAlign: 'right', lineHeight: 18 }]}>{parseFloat(totals.cthev).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण खाद्य : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.ckhadya).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण एडव्हांस   : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.cadv).toFixed(2)}</Text>
                            </View>
                          </View>
                        </View>

                        <View style={styles.box2}>
                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { lineHeight: 18 }]}>{"खाद्य कपात   : "}</Text>
                            </View>
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text style={[styles.valueText, { textAlign: 'right', lineHeight: 18 }]}>{parseFloat(totals.khadyakapat).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एडव्हांस कपात: "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.advkapat).toFixed(2)}</Text>
                            </View>
                          </View>

                          <View style={styles.bottomtitlerow3}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.valueText}>{"एकूण येणेबाकी  : "}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                              <Text style={[styles.valueText, { textAlign: 'right' }]}>{parseFloat(totals.newadv + totals.newkhadya).toFixed(2)}</Text>
                            </View>
                          </View>
                        </View>

                      </View>

                    )}

                  </Page>
                ))
              )}
            </Document>
          </PDFViewer >



      }
    </>
  );
};

// ... (styles and export)



const styles = StyleSheet.create({


  page: {

    flexDirection: 'row',
    justifyContent: 'center', // Center horizontally
    marginLeft: 13,
  },
  header: {
    position: 'absolute',
    top: 0, // Adjust this value to change the vertical position
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '95vw',
    justifyContent: 'space-between',
  },
  table: {
    display: 'table',
    width: '97%', // Adjust as needed to fit four tables in a row

  },
  tableone: {
    display: 'table',
    width: '18%', // Adjust as needed to fit four tables in a row

  },
  tableCellone: {
    border: '0.5px solid lightgray',
    padding: 0.5,
    fontSize: 8,
    fontFamily: 'Lato-Italic',
    textAlign: 'center',
    flex: 1, // Remove width and use flex to distribute space equally
  },
  tableRowone: {
    flexDirection: 'row',
    width: '100%',
  },
  tableHeaderone: {
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    border: '1px solid #000',
    padding: 2,
    fontSize: 8,
    textAlign: 'center',
    flex: 1, // Remove width and use flex to distribute space equally
  },

  tableRow: {
    flexDirection: 'row',

  },
  tableRow2: {
    flexDirection: 'row',
    backgroundColor: 'lightgray',

  },

  tableRow3: {
    flexDirection: 'row',
    backgroundColor: 'lightgray',
    marginTop: 10

  },
  line: {
    flexDirection: 'row',
    backgroundColor: 'lightgray',

    height: 1,
    width: '100vw',

  },
  tableHeader: {
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    borderTopWidth: 1,    // Add border only for top side
    borderBottomWidth: 1, // Add border only for bottom side
    borderLeftWidth: 1,   // Add border only for left side
    borderRightWidth: 0,  // No border for right side
    padding: 0.1,
    flex: 0.5,
    borderColor: 'lightblack',
    textAlign: 'center',
    fontSize: 8,
  },
  tableHeaderlast: {
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    borderTopWidth: 1,    // Add border only for top side
    borderBottomWidth: 1, // Add border only for bottom side
    borderLeftWidth: 1,   // Add border only for left side
    borderRightWidth: 1,  // No border for right side
    borderStyle: 'solid', // Set the border style to 'solid'
    borderColor: 'lightblack',
    padding: 2,
    flex: 0.5,
    textAlign: 'center',
    fontSize: 9,
  },
  tableCell: {
    border: '0.5px solid lightgray',
    padding: 0.5,
    flex: 1,
    fontSize: 9,
    fontFamily: 'Lato-Italic',
    width: 2,
    textAlign: 'center',
  },
  tableCellgreen: {
    border: '0.5px solid lightgray',
    padding: 0.5,
    flex: 1,
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Lato-Italic',
    color: 'green',
    width: 2,
    textAlign: 'center',
  },
  tableCellred: {
    border: '0.5px solid lightgray',
    padding: 0.5,
    flex: 1,
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Lato-Italic',
    color: 'red',
    width: 2,
    textAlign: 'center',
  },
  latoText: {
    fontFamily: 'Lato-Italic',
    fontSize: 14,
    marginBottom: 2,
    textAlign: 'center',
    width: '100vw',
    fontWeight: 'bold',

  },
  latoText2: {
    fontFamily: 'Lato-Italic',
    fontSize: 11,
    textAlign: 'center',
    width: '100vw',
    fontWeight: 'bold',
  },
  headtextsmall: {
    fontFamily: 'Lato-Italic',
    fontSize: 10,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 140,
    paddingRight: 140,
    backgroundColor: 'lightgray',
  },
  column1: {
    display: 'flex',
    flexDirection: 'column',
    width: '19%',
    justifyContent: 'start',
    alignItems: 'flex-start', // Align text to the left
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 20,
  },
  column2: {
    display: 'flex',
    flexDirection: 'column',
    width: '15%',
    justifyContent: 'start',
    alignItems: 'flex-start', // Align text to the left
    paddingLeft: 5,
    paddingRight: 5,


  },
  column3: {
    display: 'flex',
    flexDirection: 'column',
    width: '17%',
    justifyContent: 'start',
    alignItems: 'flex-start', // Align text to the left
    paddingLeft: 5,
    paddingRight: 5,

  },
  column1Row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  column1Rowgray: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 6,
    marginTop: 5,
    border: '0.5px solid black',
    backgroundColor: 'lightgray'
  },
  column1Label: {
    fontFamily: 'Lato-Italic',
    fontSize: 9,
    fontWeight: 'bold',
    textAlign: 'left', // Align labels to the left
  },
  column1Value: {
    fontFamily: 'Lato-Italic',
    fontSize: 9,
    fontWeight: 'bold',
    textAlign: 'right', // Align values to the right
  },
  pageBreak: {
    pageBreakBefore: 'always',
  },
  totalsRow: {
    flexDirection: 'row',
    borderTop: '1px solid #000',
    paddingTop: 5,
    paddingLeft: 2,
  },
  totalsCell: {
    border: '0.5px solid lightgray',
    padding: 0.5,
    flex: 1,
    fontSize: 8,
    width: 2,
    textAlign: 'center',
  },
  imagerow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 80, // Adjust the width as needed
    height: "auto", // Maintain aspect ratio
    marginLeft: -15,
    marginTop: 5

  },
  imgtext: {
    fontFamily: 'Lato-Italic',
    fontSize: 9,
    fontWeight: 'bold',
    textAlign: 'center', // Align labels to the left
  },

  bottomrow: {
    display: 'flex',
    flexDirection: 'row',
  },
  box1: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: 10
  },
  box2: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    width: '100%',

  },

  box3: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    width: '100%',
  },

  box4: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    width: '100%',
    marginRight: 10

  },

  bottomtitlerow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    fontSize: 10,
    width: '70%',
    padding: 0.5,
    borderColor: 'lightblack',
    marginTop: 15,
    textAlign: 'start',

  },

  bottomtitlerow2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    fontSize: 10,
    width: '100%',
    padding: 0.5,
    borderColor: 'lightblack',
    marginTop: 15,
    textAlign: 'start',
  },

  bottomtitlerow3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    fontSize: 10,
    width: '85%',
    padding: 0.5,
    borderColor: 'lightblack',
    marginTop: 15,
    textAlign: 'start',
  },

  valueText: {
    fontFamily: 'Lato-Italic',
    fontWeight: 'bold',
    fontSize: 10,
  },



});

export default Pregisterprint;
