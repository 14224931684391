import React, { useContext } from 'react'
import Globalcontext from '../../Context/Globalcontext';
import Endexam from "../../imgs/endexam.png";
import './Dialog.css'
import { useNavigate } from 'react-router-dom';

const Dialog = ({ message, onDialog, submessage, functionname, button1text, button2text }) => {

    const a = useContext(Globalcontext);
    const navigate = useNavigate();

    const handlecancel = () => {

        localStorage.setItem("status", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("kendra", "Not available");
        localStorage.setItem("kmobno", "");
        localStorage.setItem("ksr", "");
        navigate("/");
    }



    const handledialog = () => {
        functionname();
        onDialog(false);
    }
    return (
        <div
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0,0,0,0.5)"
            }}

        >
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    background: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: "whitesmoke"
                }}
            >
                <div className="dialogmain">
                    <div className="icondialog">
                        <img src={Endexam} alt="" />
                    </div>
                    <span className='head'>{message}</span>
                    <span className='subhead'>{submessage}</span>

                    <div className="buttonclass">
                        <button
                            onClick={handlecancel}
                            className="buttonend"
                        >
                            {button1text}
                        </button>
                        <button
                            onClick={handledialog}
                            className="buttonend"
                        >
                            {button2text}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dialog
