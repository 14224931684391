import React, { useContext, useEffect, useRef, useState } from 'react'
import './Vikrireport.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { YoutubeSearchedForOutlined } from '@material-ui/icons';


const Vikrireport = (props) => {
  const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), rno: '', grtype: "" });
  const inputRefs = useRef([]);
  const [DataSource, setDataSource] = useState([]);
  const [DataSource3, setDataSource3] = useState([]);
  const [PreviousAmt, setPreviousAmt] = useState();
  const [tableFilter, setTableFilter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [print, setprint] = useState(false);
  const cellRefs = useRef([]);
  const [value, setValue] = useState('');
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  const [customerData, setCustomerData] = useState([]);
  const [rtype, setRtype] = useState("sanghvikri");
  const navigate = useNavigate();

  const handlertype = (rtypetemp) => {
    if (rtypetemp === "sanghvikri") {
      setRtype("sanghvikri");
    }
    else if (rtypetemp === "kirkol") {
      setRtype("kirkol");
    }
    else if (rtypetemp === "ratiball") {
      setRtype("ratiball");
    }
  }

  useEffect(() => {
    setDataSource([]);
  }, [rtype])

  function handleEnterKey(event, currentIndex) {

    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  const filteData = (e) => {
    if (e.target.value != "") {
      setValue(e.target.value);
      const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      ));

      setTableFilter([...filtertable]);
    }
    else {
      setValue(e.target.value);
      setTableFilter([...DataSource]);
    }
  }
  const showprint = () => {
    if (rtype === "sanghvikri" || rtype === "kirkol") {
      if (DataSource && DataSource.length > 0) {
        localStorage.setItem("date1print", input.date1);
        localStorage.setItem("date2print", input.date2);
        localStorage.setItem("rtype", rtype);
        localStorage.setItem("grtype", input.grtype);
        localStorage.setItem("rno", input.rno);
        const url = "/vikrireportprint";
        window.open(url, '_blank');  //_blank
      }
      else {
        notifyerror("या कालावधीचे रतीब उपलब्ध नाही!");
      }
    }
    else{
      if (rtype === "ratiball") {
        if (DataSource3 && DataSource3.length > 0) {
          localStorage.setItem("date1print", input.date1);
          localStorage.setItem("date2print", input.date2);
          localStorage.setItem("rtype", rtype);
          localStorage.setItem("grtype", input.grtype);
          localStorage.setItem("rno", input.rno);
          const url = "/vikrireportprint";
          window.open(url, '_blank');  //_blank
        }
        else {
          notifyerror("या कालावधीचे रतीब उपलब्ध नाही!");
        }
      }
    }
  }

  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }));

    if (name === 'date2') {
      // Check if date2 is more than one year ahead of date1
      const date1 = new Date(input.date1);
      const date2 = new Date(valuecurrent);

      // Calculate the difference in days between date1 and date2
      const differenceInDays = (date2 - date1) / (1000 * 60 * 60 * 24);

      if (differenceInDays <= 30) {
        setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
      } else {
        notifyerror("रिपोर्ट लिमिट - 1 महिना");
        setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
      }
    }
  }

  const getsanghvikrireport = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getsanghvikrireport.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
     
      if (response.data && response.data.length > 0) {
        setDataSource(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getkirkolreport = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      rno: input.rno,
      grtype: input.grtype,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getkirkolvikrireport.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      if (response.data && response.data.length > 0) {
        //  console.log(response.data[0].previousamt)
      

        // let prevamttemp = response.data[0].previousamt === "0" ? response.data[1].previousamt : response.data[0].previousamt;

        setPreviousAmt(response.data[0].prevamt);
        setDataSource(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getratibyenebakiyadi = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      rno: input.rno,
      grtype: input.grtype,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getratibyenebakiyadi.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      if (response.data && response.data.length > 0) {

        //console.log(response.data);
        setDataSource3(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource3([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  function formatDate(inputDate) {
    // Ensure inputDate is in 'yyyy-mm-dd' format
    const parts = inputDate.split('-');
    if (parts.length !== 3) {
      return ''; // Invalid date format
    }

    // Convert month number to month name in English
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const year = parts[0];
    const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
    const day = parts[2];

    return `${day}-${months[month]}-${year}`;
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };


  return (
    <div className="sanklanmain">
      <div className="firstrowsank">
        <img onClick={props.visibility} className='back' src={back} alt="" />
        <div className="colmain">
          <div className="firstrow2">
            <div className="rowbill">
              <div className="mb-2">
                <span className='subheadsank'>दिनांक</span>
                <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
              </div>

              <span className='subheadbill'>ते</span>

              <div className="mb-2">
                <span className='subheadsank'>दिनांक</span>
                <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
              </div>

              {
                rtype === "kirkol" ?

                  <>
                    <div className="mb-2">
                      <span className='subheadsank'>प्रकार</span>
                      <select list="encodings" autoComplete='off' className="form-control" id="grtype" name="grtype" value={input.grtype} onChange={handleChange}>
                        <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                        <option key={1} value="रतीब">रतीब</option>
                      </select>
                    </div>

                    <div className="mb-2">
                      <span className='subheadsank'>रतीब खाते क्रमांक</span>
                      <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className="form-control" autoComplete='off' id="rno" name="rno" value={input.rno} onChange={handleChange} ></input>
                    </div>
                  </>

                  :
                  ''
              }


              <div className="mb-2">
                <button onClick={rtype === "sanghvikri" ? getsanghvikrireport : rtype === "kirkol" ? getkirkolreport : getratibyenebakiyadi} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>
                <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="firstrow2">
        <div class="table-containerbill">
          <div className="buttonsreportsank">
            <input type="text" className="form-control searchsank mx-3" id="searchsank" name="searchsank" placeholder='Search' value={value} onChange={filteData} autoComplete='off' />
            <div className="radio">
              <div className="form-check">
                <input onClick={() => { handlertype("sanghvikri") }} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                <label className="form-check-label" htmlFor="radio2">
                  संघ विक्री रिपोर्ट
                </label>
              </div>
              <div className="form-check">
                <input onClick={() => { handlertype("kirkol") }} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                <label className="form-check-label" htmlFor="radio1">
                  रतीब/किरकोळ ग्राहक रिपोर्ट
                </label>
              </div>
              <div className="form-check">
                <input onClick={() => { handlertype("ratiball") }} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                <label className="form-check-label" htmlFor="radio1">
                  रतीब एकत्रित येणेबाकी यादी
                </label>
              </div>
            </div>
          </div>

          {
            rtype === "sanghvikri" ?
              <>
                <table id="myTablebill" className="table table-bordered">
                  <thead>
                    <tr>
                      <th className='thsmall' scope="col">दिनांक</th>
                      <th className='thsmall' scope="col">खाते/नाव</th>
                      <th className='thsmall' scope="col">वेळ</th>
                      <th className='thsmall' scope="col">प्रकार</th>
                      <th className='thsmall' scope="col">लिटर</th>
                      <th className='thsmall' scope="col">फॅट</th>
                      <th className='thsmall' scope="col">एसएनएफ</th>
                      <th className='thsmall' scope="col">ए.कमिशन</th>
                      <th className='thsmall' scope="col">दर</th>
                      <th className='thsmall' scope="col">रक्कम</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.length > 0 ? tableFilter.map((user, index) =>
                      <tr key={index}>
                        <td className='thsmallcust'>{formatDate(user.date)}</td>
                        <td className='thsmallcustlarge'>{user.sno + ": " + user.sname}</td>
                        <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                        <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                        <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                        <td className='thsmallcust'>{user.fat && user.fat !== "" ? parseFloat(user.fat) : 0}</td>
                        <td className='thsmallcust'>{user.snf && user.snf !== "" ? parseFloat(user.snf) : 0}</td>
                        <td className='thsmallcust'>{user.comm && user.comm !== "" ? parseFloat(user.comm) : 0}</td>
                        <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                        <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                      </tr>
                    )
                      :
                      DataSource && DataSource.map((user, index) =>
                        <tr key={index}>
                          <td className='thsmallcust'>{formatDate(user.date)}</td>
                          <td className='thsmallcustlarge'>{user.sno + ": " + user.sname}</td>
                          <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                          <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                          <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                          <td className='thsmallcust'>{user.fat && user.fat !== "" ? parseFloat(user.fat) : 0}</td>
                          <td className='thsmallcust'>{user.snf && user.snf !== "" ? parseFloat(user.snf) : 0}</td>
                          <td className='thsmallcust'>{user.comm && user.comm !== "" ? parseFloat(user.comm) : 0}</td>
                          <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                          <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                        </tr>
                      )
                    }


                  </tbody>
                  <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                    <td className='thsmallcust'>एकूण</td>
                    <td className='thsmallcustlarge'>-</td>
                    <td className='thsmallcust'>-</td>
                    <td className='thsmallcust'>-</td>
                    <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>-</td>
                    <td className='thsmallcust'>-</td>
                    <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.comm), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>-</td>
                    <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)}</td>


                  </tr>
                </table>

            
              </>
              : rtype === "kirkol" ?
                <>
                  <table id="myTablebill" className="table table-bordered">
                    <thead>
                      <tr>
                        <th className='thsmall' scope="col">दिनांक</th>
                        <th className='thsmall' scope="col">प्रकार</th>
                        <th className='thsmall' scope="col">खाते क्र.</th>
                        <th className='thsmall' scope="col">प्रकार</th>
                        <th className='thsmall' scope="col">वेळ</th>
                        <th className='thsmall' scope="col">प्रकार</th>
                        <th className='thsmall' scope="col">लिटर</th>
                        <th className='thsmall' scope="col">दर</th>
                        <th className='thsmall' scope="col">रक्कम</th>

                      </tr>
                    </thead>
                    <tbody>
                      {value.length > 0 ? tableFilter.map((user, index) =>
                        <tr key={index}>
                          <td className='thsmallcust'>{formatDate(user.date)}</td>
                          <td className='thsmallcustlarge'>{user.rno + ": " + user.rname}</td>
                          <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                          <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                          <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                          <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                          <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>
                        </tr>
                      )
                        :
                        <>
                          <tr key={0}>
                            <td className='thsmallcustred'>{"मागील बाकी"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{"-"}</td>
                            <td className='thsmallcustred'>{PreviousAmt}</td>
                          </tr>

                          {
                            DataSource && DataSource.map((user, index) =>
                              <tr key={index} className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"} >
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{formatDate(user.date)}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.grtype}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.rno}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.trantype}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.time && user.time !== "" ? user.time : "-"}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.type && user.type !== "" ? user.type : "-"}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                                <td className={user.trantype === "नावे" ? 'thsmallcust' : "thsmallcustgreen"}>{user.amt && user.amt !== "" ? parseFloat(user.amt) : 0}</td>

                              </tr>
                            )
                          }
                        </>
                      }
                    </tbody>
                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                      <td className='thsmallcust'>एकूण</td>
                      <td className='thsmallcustlarge'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)}</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>
                        {parseFloat(
                          DataSource.reduce((total, user) => {
                            if (user.trantype === "नावे") {
                              return total + parseFloat(user.amt);
                            } else {
                              return total - parseFloat(user.amt);
                            }
                          }, 0) + parseFloat(PreviousAmt)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </table>

                  {
                    rtype === "sanghvikri" ?
                      <div className="literdetailas">
                        <span>एकूण संघ विक्री केलेले लिटर : {DataSource.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)}</span>
                        <span>एकूण संकलन केलेले लिटर : {DataSource.reduce((total, user) => parseFloat(user.tliter), 0).toFixed(2)}</span>
                        <span>----------------------</span>
                        <span>एकूण फरक लिटर : {parseFloat(parseFloat(DataSource.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)) - parseFloat(DataSource.reduce((total, user) => parseFloat(user.tliter), 0))).toFixed(2)}</span>
                      </div>
                      : ''
                  }
                </>
                :
                <>
                  <table id="myTablebill" className="table table-bordered">
                    <thead>
                      <tr>
                        <th className='thsmall' scope="col">खाते क्रमांक</th>
                        <th className='thsmall' scope="col">रतीब नाव</th>
                        <th className='thsmall' scope="col">म्हैस लिटर</th>
                        <th className='thsmall' scope="col">गाय लिटर</th>
                        <th className='thsmall' scope="col">एकूण लिटर</th>
                        <th className='thsmall' scope="col">एकूण येणेबाकी</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value.length > 0 ? tableFilter.map((user, index) =>
                        <tr key={index}  >
                          <td className='thsmallcust'>{user.rno && user.rno !== "" ? user.rno : "-"}</td>
                          <td className='thsmallcust'>{user.rname && user.rname !== "" ? user.rname : "-"}</td>
                          <td className='thsmallcust'>{user.tliter && user.tliter !== "" ? user.tliter : "-"}</td>
                          <td className='thsmallcust'>{user.ryenebaki && user.ryenebaki !== "" ? user.ryenebaki : "-"}</td>
                        </tr>
                      )
                        :
                        DataSource3 && DataSource3.map((user, index) =>
                          <tr key={index}  >
                            <td className='thsmallcust'>{user.rno && user.rno !== "" ? user.rno : "-"}</td>
                            <td className='thsmallcust'>{user.rname && user.rname !== "" ? user.rname : "-"}</td>
                            <td className='thsmallcust'>{user.mhliter && user.mhliter !== "" ? parseFloat(user.mhliter).toFixed(2) : 0}</td>
                            <td className='thsmallcust'>{user.gayliter && user.gayliter !== "" ? parseFloat(user.gayliter).toFixed(2) : 0}</td>
                            <td className='thsmallcust'>{user.tliter && user.tliter !== "" ? parseFloat(user.tliter).toFixed(2) : "-"}</td>
                            <td className='thsmallcust'>{user.ryenebaki && user.ryenebaki !== "" ? parseFloat(user.ryenebaki).toFixed(2) : "-"}</td>

                          </tr>
                        )
                      }
                    </tbody>
                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                      <td className='thsmallcust'>एकूण</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>{DataSource3.reduce((total, user) => total + parseFloat(user.mhliter), 0).toFixed(2)}</td>
                      <td className='thsmallcust'>{DataSource3.reduce((total, user) => total + parseFloat(user.gayliter), 0).toFixed(2)}</td>
                      <td className='thsmallcust'>{DataSource3.reduce((total, user) => total + parseFloat(user.tliter), 0).toFixed(2)}</td>
                      <td className='thsmallcust'>{DataSource3.reduce((total, user) => total + parseFloat(user.ryenebaki), 0).toFixed(2)}</td>

                    </tr>
                  </table>


                </>

          }
        </div>

      </div>

    </div>

  )
}

export default Vikrireport
