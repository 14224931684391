import React, { useEffect, useRef, useState } from 'react';
import './InternetStatusAlert.css'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '../Dialog/Dialog';
import { useNavigate } from 'react-router-dom';

function InternetStatusAlert() {
    const [online, setOnline] = useState(window.navigator.onLine);
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const reflogout = useRef(null);
    const refdeleteclose = useRef(null);
    const navigate = useNavigate();

    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const logout = (contact) => {

        localStorage.setItem("status", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("kendra", "Not available");
        localStorage.setItem("kmobno", "");
        localStorage.setItem("ksr", "");
        localStorage.setItem("status", "");
        localStorage.setItem("vdate", "");
    
        refdeleteclose.current.click();
      
    
        navigate("/");
        notify("Logged Out.");
      }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }

    const handlelogout = () => {
        reflogout.current.click();
    }
    const areUSure = (choose) => {
        handleDialog("", false, "");
    };

   
    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    useEffect(() => {
        // Add event listeners to detect online/offline status changes
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            // Remove event listeners when the component unmounts
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const handleOnline = () => {
        setOnline(true);
        // You can trigger any actions when the internet connection is restored here.
    };

    const handleOffline = () => {
        setOnline(false);
        handleDialog("इंटरनेट उपलब्ध नाही?", true, "सॉफ्टवेयर बंद करायचे?");
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={reflogout}
                data-bs-target="#nonet"
            >
            </button>

            <div className="modal fade" id="nonet" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div data-bs-backdrop="static" className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">इंटरनेट उपलब्ध नाही,सॉफ्टवेअर बंद करायचे?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={logout} >होय</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {online ? (
                    // Content to display when online
                    ''
                ) : (
                    // Content to display when offline
                    //notifyerror("इंटरनेट कनेक्शन तपासा!")
                    handlelogout()
                )}
            </div>
        </>
    );
}

export default InternetStatusAlert;
