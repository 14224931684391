import React, { useContext, useRef, useState } from 'react'
import './Regicontents.css'
import Registration from "../../imgs/registration.png";
import Bdate from "../../imgs/bdate.png";
import { useNavigate } from 'react-router-dom';
import Globalcontext from '../../Context/Globalcontext';


const Regicontents = (props) => {
    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const areUSure = (choose) => {

        handleDialog("", false);

    };



    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("रिपोर्ट्स");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };

    function formatDate(inputDate) {
        // Convert the input date string to a Date object
        const date = new Date(inputDate);

        // Define month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr',
            'May', 'Jun', 'Jul', 'Aug',
            'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get the day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Format the date as 'day Month Year'
        const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

        return formattedDate;
    }

    return (
        <>
            <div className='line' />
            <div className="maincon">

                <div className="rowreport">
                    <div className="regifirst">

                        <div className='regimain'>
                            <img className='smallimg' src={Bdate} alt="" />
                            <span className="headingregi2">Validity Date : {formatDate(localStorage.getItem("vdate"))}</span>
                        </div>
                        <div className='regimain2'>
                            <img className='smallimg' src={Registration} alt="" />
                            <span className="headingregi">ACTIVATED</span>
                        </div>


                    </div>


                </div>

                <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
                    <div className="first">
                    </div>
                    <div className="second">
                        <div className='line' />
                        <span className='firstspan'>1. Registration Status - Activeted</span>
                        <span className='infospan'>2. For more info, Contact us on support@idairy.co.in</span>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Regicontents
