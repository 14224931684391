import React, { useState, useRef } from 'react'
import './Nrepair.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import { Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';

const Nrepair = (props) => {
  const [input, setInput] = useState({ vibhag: "दरपत्रक निवडा", date: "" });
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  const inputRefs = useRef([]);
  const [wait, setWait] = useState(false);

  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }))
  }

  const handleUpdate = () => {
    if(input.vibhag === "" || input.vibhag === "दरपत्रक निवडा")
    {
      notifyerror("दरपत्रक विभाग निवडा!")
      return;
    }

    if (input.date) {
      repair();
    }
    else {
      notifyerror("प्रथम दिनांक सेट करा!")
    }
  }

  const repair = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.date === "") {
      notifyerror("प्रथम दिनांक सेट करा!")
      return;
    }
    setWait(true);
    let rawData = {
      vibhag: input.vibhag,
      date: input.date,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)

    axios.post('https://idairy.co.in/dairy/php/cust/raterepairvibhag.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
    
      if (response.data.trim() === "Successfully updated.") {
        notify(input.date + " पासून पुढे नवीन दरपत्रक लागू झाले!");
        setInput({ date: "" });
      }
      setWait(false);
    }).catch(err => {
      console.log(err);
    });
  }

  function handleEnterKey(event, currentIndex) {
    if (event.key === 'Backspace' && (
      (event.target.tagName === 'INPUT' && event.target.value === '') ||
      (event.target.tagName === 'SELECT' && (
        (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
      ))
    )) {
      event.preventDefault();
      let prevIndex = currentIndex - 1;
      while (prevIndex >= 0) {
        const prevElement = inputRefs.current[prevIndex];
        if (prevElement) {
          if (
            (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
            prevElement !== event.target
          ) {
            prevElement.focus();
            break;
          } else {
            prevIndex--;
          }
        } else {
          prevIndex--;
        }
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      nextIndex = currentIndex + 1;
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  return (
    <div>
      <div className="ratemain2">
        <div className="firstrowrepair">
          <img onClick={props.visibility} className='back' src={back} alt="" />
          <span>नवीन दरपत्रक आले असेल व तुम्ही जुन्या दरपत्रकानुसार एंट्रीज केल्या असतील तर येथून तुम्ही त्या एंट्रीज ला नवीन दरपत्रक लागू करू शकता! </span>
        </div>

        <div className="firstrowrepair">
          <span className='title'>दरपत्रक विभाग</span>
          <select onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} className="form-control" autoComplete='off' id="vibhag" name="vibhag" value={input.vibhag} onChange={handleChange}>
            <option key={0} value="दरपत्रक निवडा">दरपत्रक निवडा</option>
            <option key={1} value="दरपत्रक विभाग-१">दरपत्रक विभाग-१</option>
            <option key={2} value="दरपत्रक विभाग-२">दरपत्रक विभाग-२</option>
          </select>
        </div>

        <div className="firstrowrepair">
          <span className='title'>पासून पुढे</span>
          <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className='form-control' autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
        </div>
        <div className="firstrowrepair">

          {
            wait ?
            <button onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[2] = ref)} type='button' id='saverepair' className="btn btn-primary">लोडिंग...</button>
              :
              <button onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[2] = ref)} onClick={handleUpdate} type='button' id='saverepair' className="btn btn-primary">नवीन दरपत्रक सेट करणे</button>
          }


         
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
export default Nrepair
