import React, { useEffect, useRef, useState } from 'react'
import './Ratibmaster.css'
import back from "../../imgs/backexam.png";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';

const Ratibmaster = (props) => {
    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ sno: "", name: "", address: "", mobno: "",mhcomm:"",gaycomm: "",obal: ""});
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');

    function handleEnterKey(event, currentIndex) {

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

    }

    useEffect(() => {

        if (input.kno === "") {
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                type: "",
                amt: "",
                amt: "",
                remark: "",
                mhcomm: "",
                gaycomm: "",

            }))

            setNewedit("new");
        }
        else {
            // getcustdata(input.kno);
            let custlisttemp = JSON.parse(localStorage.getItem("custlist"));
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.kno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.kno])

    async function getmaxno() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmaxnoratib.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setInput(prevInput => ({
                    ...prevInput,
                    sno: parseInt(response.data) + 1
                }));
            }
            else {
                setInput(prevInput => ({
                    ...prevInput,
                    sno: 1
                }));

            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getcustdata(kno) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                response.data.map((user, index) =>
                (
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname,
                    }))
                )
                )
            }
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            saveratib();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[4]) {
                    inputRefs.current[4].focus();
                }
            }
        }
    }


    const saveratib = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.sno === "" || input.name === "" || input.address === "" || (input.mobno === "")) {
            notifyerror("अपूर्ण एंट्री माहिती!");
            return;
        }
        let rawData = {
            rno: input.sno,
            rname: input.name,
            address: input.address,
            mobno: input.mobno,
            obal: input.obal,
            flag: newedit,
            sredit: sredit,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/saveratib.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (newedit === "new") {
                if (response.data.trim() === "Saved.") {
                    notify("सेव्ह झाले!");
                    setInput(prevInput => ({
                        ...prevInput,
                        sno: parseInt(input.sno) + 1,
                        name: "",
                        address: "",
                        mobno: "",
                        obal: "",
                        mhcomm: "",
                        gaycomm: "",
                    }))
                    setSredit("");
                    setNewedit("new");
                    getsangh();
                    if (inputRefs.current[0]) {
                        inputRefs.current[0].focus();
                    }
                }
            }
            else {
                if (response.data.trim() === "updated.") {
                    notify("माहिती अपडेट झाली!")
                    getsangh();
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getmaxno();
        getsangh();
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [])

    async function getsangh() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getratib.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
        
            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (rno, rname, address, mobno,obal) => {
        setInput({ sno: rno, name: rname, address: address, mobno: mobno,obal: obal})
        setNewedit("edit");
        setSredit(rno);
    }

    async function deleteuchal(sr) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deleteuchal.php', { sr: sr, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                notify(response.data);
                setInput(prevInput => ({
                    ...prevInput,
                    kno: "",
                    name: "",
                    type: "",
                    amt: "",
                }))
                setSredit("");
                setNewedit("new");
                getsangh();
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }

            }

        }).catch(err => {
            console.log(err);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <div className="khadyamain">
            <div className="firstrowkhadya">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmainkhadya">
                    <div className="firstrow2">
                        <div className="rowsank">
                            <div className="mb-2">
                                <span className='subheadsank'>ग्राहक खाते क्रमांक</span>
                                <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="sno" name="sno" value={input.sno} onChange={handleChange} ></input>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='linekh' />
            <div className="row1kh">


            </div>
            <div className="row1sangh">
                <div className="mb-2">
                    <span className='subheadsank'>रतीब ग्राहक नाव</span>
                    <input type="text" onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>पत्ता</span>
                    <input type="text" onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="address" name="address" value={input.address} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                    <span className='subheadsank'>मोबाईल क्रमांक</span>
                    <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="mobno" name="mobno" value={input.mobno} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                    <span className='subheadsank'>सुरुवातीची उधारी</span>
                    <input onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="obal" name="obal" value={input.obal} onChange={handleChange} ></input>
                </div>

               
            </div>
            <div className='linekh' />

            <div className="row1sangh">
                <div className="mb-2">

                    <button onClick={saveratib} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[4] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">

                        {newedit === "new" ?
                            "सेव्ह" :
                            "अपडेट"
                        }
                    </button>
                    <button type="submit" className="btn btn-primary mx-1 sanksmall">रिपोर्ट</button>
                </div>
            </div>
            <div className="firstrow2">
                <div class="table-containeruchal">
                    <table id="myTable" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">खाते क्र.</th>
                                <th className='thsmall' scope="col">ग्राहक नाव</th>
                                <th className='thsmall' scope="col">पत्ता</th>
                                <th className='thsmall' scope="col">मोबाईल</th>
                                <th className='thsmall' scope="col">सुरू.उधारी</th>
                                <th className='thsmall' scope="col">एक्शन</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.length > 0 ? tableFilter.map((user, index) =>
                                <tr key={index}>
                                    <td className='thsmall'>{user.rno}</td>
                                    <td className='thsmall'>{user.rname}</td>
                                    <td className='thsmall'>{user.address}</td>
                                    <td className='thsmall'>{user.mobno}</td>
                                    <td className='thsmall'>{user.obal}</td>

                                    {/* <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr) }} v className="icsank" /></td> */}
                                </tr>
                            )
                                :
                                DataSource.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmall'>{user.rno}</td>
                                        <td className='thsmall'>{user.rname}</td>
                                        <td className='thsmall'>{user.address}</td>
                                        <td className='thsmall'>{user.mobno}</td>
                                        <td className='thsmall'>{user.obal}</td>
                                        <td><UilEditAlt onClick={() => { handleedit(user.rno, user.rname, user.address, user.mobno,user.obal) }} className="icsank" /><UilTrash className="icsank" /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Ratibmaster
