import React, { useContext, useEffect, useRef, useState } from 'react'
import './Idairygrowcon.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import { ToastContainer, toast } from 'react-toastify';
import Globalcontext from '../../Context/Globalcontext';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';

const Idairycon = (props) => {
    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
    const [loading, setLoading] = useState(false);
    const [data, setGdata] = useState([]);
    const colors = ['#FF8042', '#FFBB28', 'pink', '#0088FE', '#00C49F', 'red', 'green'];
    const [khrtype, setKhrtype] = useState("म्हैस");
    const notify = (msg) => toast(msg);

    const handlertype = (type) => {
        setKhrtype(type);
    }

    useEffect(() => {
        getliterchart();
    }, [khrtype])

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const getPath = (x, y, width, height) => {
        return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
      ${x + width / 2}, ${y}
      C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
      Z`;
    };

    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;

        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };

    const areUSure = (choose) => {

        handleDialog("", false);

    };

    useEffect(() => {
        getliterchart();
    }, [])

    const getliterchart = () => {
        setLoading(true);
        setGdata([]);
        let rawData = {
            contact: localStorage.getItem("contact"),
            khrtype: khrtype,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData);

        axios.post('https://idairy.co.in/dairy/php/cust/getliterchart.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            setGdata(response.data);

            if (response.data[0].totalsum === "0") {

            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("रिपोर्ट्स");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };

    return (
        <>
            <div className='line' />
            <div className="maincon">

                <div className="rowreport">
                    <div className="chartreport">
                        <span className={`heading1 ${mastermenu ? '' : mastermenu}`}>आयडेअरी ग्रो</span>
                        <div className="literrow">
                            {
                                data.length > 0 ?
                                    <span className={`smallhead`}>मागील ७ दिवसांचे दूध कलेक्शन - एकूण लिटर : <span className='smallhead2'> {parseFloat(data[0].totalsum).toFixed(2)} </span></span>
                                    :
                                    <span className={`smallhead`}>मागील ७ दिवसांचे दूध कलेक्शन - एकूण लिटर : 0</span>
                            }

                            <div className="litertype">
                                <div className="form-check">
                                    <input onClick={e => handlertype("म्हैस")} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                                    <label className="form-check-label" htmlFor="radio1">
                                        म्हैस व्यवसाय लिटर
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={e => handlertype("गाय")} className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                                    <label className="form-check-label" htmlFor="radio2">
                                        गाय व्यवसाय लिटर
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="chart">
                            <div className="bchart">
                                {
                                    data.length > 0 ?
                                        loading ?
                                            <span>loading....</span> :
                                            <BarChart
                                                width={1000}
                                                height={350}
                                                data={data}
                                                margin={{
                                                    top: 30,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis ticks={[50, 100, 150, 200,250,300]} />
                                                <Bar dataKey="uv" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                                                    {/* {data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                                    ))} */}
                                                </Bar>
                                            </BarChart>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                        <div className='linechart' />
                        <div className="uchaldiv">
                            {
                                data.length > 0 ?
                                    <span className={`uchalbox`}>एकूण एडव्हांस : {data[0].totaluchal - data[0].totaluchaljma}</span>
                                    :
                                    <span className={`uchalbox`}>एकूण एडव्हांस : 0</span>
                            }
                            <span> + </span>
                            {
                                data.length > 0 ?
                                    <span className={`uchalbox2`}>एकूण खाद्य : {data[0].totalkhadya}</span>
                                    :
                                    <span className={`uchalbox2`}>एकूण खाद्य : 0</span>
                            }
                            <span> = </span>
                            {
                                data.length > 0 ?
                                    <span className={`uchalbox3`}>एकूण वाटप : {parseFloat(data[0].totaluchal - data[0].totaluchaljma) + parseFloat(data[0].totalkhadya)}</span>
                                    :
                                    <span className={`uchalbox3`}>एकूण वाटप : 0</span>
                            }
                        </div>
                    </div>

                </div>
            </div>

            {dialog.isLoading && (
                <Dialog
                    //Update
                    submessage={dialog.nameProduct}
                    onDialog={areUSure}
                    message={dialog.message}
                    functionname={openkendra}
                    button1text="नाही"
                    button2text="होय"
                />
            )}
        </>
    )
}

export default Idairycon
