import React, { useRef, useState } from 'react'
import './Customerreportvar.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';

const Customerreport = (props) => {

    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), kno: "", cname: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [DataSource2, setDataSource2] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const navigate = useNavigate();

    const [rtype, setRtype] = useState("thakbaki");
    const handlertype = (rtypetemp) => {

        if (rtypetemp === "bybill") {
            setRtype("bybill");
        }
        else if (rtypetemp === "thakbaki") {
            setRtype("thakbaki");
        }
      
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
            localStorage.setItem("knoprint", input.kno);
            const url = "/custreportprintvar";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date2') {
            // Check if date2 is more than one year ahead of date1
            const date1 = new Date(input.date1);
            const date2 = new Date(valuecurrent);
            const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000; // Approximate milliseconds in a year
            if (date2 - date1 <= oneYearInMilliseconds) {
                setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
            } else {
                // Display an error or prevent setting the state if date2 is more than one year ahead
                // You can add your error handling logic here
                notifyerror("रिपोर्ट लिमिट - 1 वर्ष");
                setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
            }
        }
    }

    const getreport = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.kno === "") {
            notifyerror("सभासद खाते क्रमांक भरा");
            setDataSource([]);
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            kno: input.kno,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getcustreportvar.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            // console.log(response.data);
            if (response.data && response.data.length > 0) {
                setDataSource2(response.data);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource2([]);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const getreportthakbaki = () => {

        var mainuser2 = jsEncode.encode("mamapr", 125);

        let rawData = {
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getthakbaki0.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
          
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);
            }
            else {
                notifyerror("माहिती उपलब्ध नाही!");
                setDataSource([]);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const filteData = (e) => {
        if (e.target.value != "") {
            setValue(e.target.value);
            const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
                String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
            ));

            setTableFilter([...filtertable]);
        }
        else {
            setValue(e.target.value);
            setTableFilter([...DataSource]);
        }
    }

    function formatDate(inputDate) {
        // Ensure inputDate is in 'yyyy-mm-dd' format
        const parts = inputDate.split('-');
        if (parts.length !== 3) {
            return ''; // Invalid date format
        }

        // Convert month number to month name in English
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const year = parts[0];
        const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
        const day = parts[2];

        return `${day}-${months[month]}`;
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <div className="sanklanmain">
            <div className="firstrowsank">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmain">
                    <div className="firstrow2">
                        <div className="rowbill">
                            {
                                rtype === "bybill" ?
                                    <>
                                        <div className="mb-2">
                                            <span className='subheadsank'>दिनांक निवडा var</span>
                                            <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                        </div>

                                        <span className='subheadbill'>ते</span>

                                        <div className="mb-2">
                                            <span className='subheadsank'>-</span>
                                            <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                        </div>

                                        <span className='subheadbill se'> || </span>

                                        <div className="mb-2 se">
                                            <span className='subheadsank'>खाते क्र.</span>
                                            <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                                        </div>
                                    </>
                                    : ''
                            }

                            <div className="mb-2">

                                <button onClick={rtype === "bybill" ? getreport : getreportthakbaki} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>
                                <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="firstrow2">
                <div class="table-containerbill">

                    <div className="buttonsreportsank">
                        <input type="text" className="form-control searchsank mx-3" id="searchsank" name="searchsank" placeholder='Search' value={value} onChange={filteData} autoComplete='off' />
                        <div className="radio">
                            <div className="form-check">
                                <input onClick={() => { handlertype("thakbaki") }} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                                <label className="form-check-label" htmlFor="radio2">
                                    सध्याची थकबाकी यादी
                                </label>
                            </div>
                            <div className="form-check">
                                <input onClick={() => { handlertype("bybill") }} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                                <label className="form-check-label" htmlFor="radio1">
                                    बिलाप्रमाणे खाते रिपोर्ट
                                </label>
                            </div>
                          
                        </div>
                    </div>

                    {
                        rtype === "bybill" ?

                            <>
                                <span className='custname'>
                                    {
                                        DataSource && DataSource.length > 0 ?
                                            "ग्राहकाचे नाव- " + DataSource[0].cname : ''
                                    }

                                </span>

                                <table id="myTablebill" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className='thsmall' scope="col">दिनांक</th>
                                            <th className='thsmall' scope="col">मागील बाकी</th>
                                            <th className='thsmall' scope="col">चालू खाद्य <span><br />(+)</span></th>
                                            <th className='thsmall' scope="col">चालू एडव्हान्स<span><br />(+)</span></th>
                                            <th className='thsmall' scope="col">एकूण बाकी<span><br />(=)</span></th>
                                            <th className='thsmall' scope="col">प्रत्यक्ष कपात<span><br />(-)</span></th>
                                            <th className='thsmall' scope="col">येणेबाकी<span><br />.</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            DataSource2 && DataSource2.length > 0 ?
                                                DataSource2 && DataSource2.map((user, index) =>
                                                    <tr key={index}>
                                                        <td className='thsmallcustlarge'>{formatDate(user.date1) + " ते " + formatDate(user.date2)}</td>
                                                        <td className='thsmallcust'>{user.magilbaki && user.magilbaki !== "" ? parseFloat(user.magilbaki) : 0}</td>
                                                        <td className='thsmallcust'>{user.ckhadya && user.ckhadya !== "" ? parseFloat(user.ckhadya) : 0}</td>
                                                        <td className='thsmallcust'>{user.cadv && user.cadv !== "" ? parseFloat(user.cadv) : 0}</td>
                                                        <td className='thsmallcust'>{user.tbaki && user.tbaki !== "" ? parseFloat(user.tbaki) : 0}</td>
                                                        <td className='thsmalledit'>{user.tkapat && user.tkapat !== "" ? parseFloat(user.tkapat) : 0}</td>
                                                        <td className='thsmallcust'>{user.yenebaki && user.yenebaki !== "" ? parseFloat(user.yenebaki) : 0}</td>

                                                    </tr>
                                                )

                                                : ''
                                        }
                                    </tbody>

                                </table>
                            </>

                            : 

                            <table id="myTablebill" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th className='thsmall' scope="col">खाते क्रमांक</th>
                                        <th className='thsmall' scope="col">सभासद नाव</th>
                                        <th className='thsmall' scope="col">येणेबाकी</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {DataSource && DataSource.map((user, index) =>
                                        <tr key={index}>

                                            <td className='thsmallcust'>{user.kno && user.kno !== "" ? user.kno : ''}</td>
                                            <td className='thsmallcust'>{user.cname}</td>
                                            <td className='thsmallcust'>{user.total_amt && user.total_amt !== "" ? parseFloat(user.total_amt).toFixed(2) : 0}</td>


                                        </tr>
                                    )
                                    }
                                </tbody>

                            </table>
                            
                          

                    }
                </div>

            </div>

        </div>
    )
}

export default Customerreport
