import React, { useEffect, useState } from 'react';
import './Sanklanreportprint.css';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";

Font.register({
    family: 'Lato-Italic',
    src: LatoItalicFont, // Use the imported font variable
});
const Sanklanreportprint = () => {
    const [customerData, setCustomerData] = useState({});
    const [literTotal, setLiterTotal] = useState(0);
    const [amtTotal, setAmtTotal] = useState(0);
    let totals = {};

    useEffect(() => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let phplanding = "";
        if (localStorage.getItem("rtype") === "all") {
            phplanding = "getsanklanreport.php";
        }
        else if (localStorage.getItem("rtype") === "bydate") {
            phplanding = "getvadhtut.php";
        }

        let rawData = {
            date1: localStorage.getItem("date1print"),
            date2: localStorage.getItem("date2print"),
            mainuser: localStorage.getItem("contact"),
            mainuser2:mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/${phplanding}`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            setCustomerData(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const getDatesInRange = (date1, date2) => {
        const dates = [];
        const currentDate = new Date(date1);

        while (currentDate <= new Date(date2)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(inputDate).toLocaleDateString('en-US', options);
    }

    if(localStorage.getItem("rtype") === "all")
    {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                tliter: customerData.reduce((total, rowData) => total + parseFloat(rowData.liter), 0),
                tamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.amt), 0),
            };
        }
    }
    else if(localStorage.getItem("rtype") === "bydate")
    {
        if (Array.isArray(customerData) && customerData.length > 0) {
            totals = {
                tmhliter: customerData.reduce((total, rowData) => total + parseFloat(rowData.mhliter), 0),
                tmhamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.mhamt), 0),
                tgayliter: customerData.reduce((total, rowData) => total + parseFloat(rowData.gayliter), 0),
                tgayamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.gayamt), 0),
                tliter: customerData.reduce((total, rowData) => total + parseFloat(rowData.mhliter && rowData.mhliter !== null && rowData.gayliter && rowData.gayamt !== null ? parseFloat(rowData.mhliter) + parseFloat(rowData.gayliter) : 0), 0),
                tamt: customerData.reduce((total, rowData) => total + parseFloat(rowData.mhamt && rowData.mhamt !== null && rowData.gayamt && rowData.gayamt !== null ? parseFloat(rowData.mhamt) + parseFloat(rowData.gayamt) : 0 ), 0),
                tsanghtotal: customerData.reduce((total, rowData) => total + parseFloat(rowData.sanghtotal), 0),
                ttotalratib: customerData.reduce((total, rowData) => total + parseFloat(rowData.totalratib), 0),
                tvadhtut: customerData.reduce((total, rowData) => total + parseFloat(rowData.vadhtut), 0),
            };
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <PDFViewer className='pdf'>
            <Document>

                {customerData.length > 0 ? (
                    localStorage.getItem("rtype") === "all"
                        ?
                        <Page size="A4" orientation="landscape">
                            <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                            <Text style={styles.latoText}>{"संकलन रिपोर्ट"}</Text>
                            <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                            <View style={styles.imagerow}>
                                <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                <Image style={styles.image} src={Logo} />
                            </View>

                            <View style={styles.table}>
                                {/* Table Header */}
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                    <Text style={styles.tableHeader}>{"नाव"}</Text>
                                    <Text style={styles.tableHeader}>{"वेळ"}</Text>
                                    <Text style={styles.tableHeader}>{"प्रकार"}</Text>
                                    <Text style={styles.tableHeader}>{"लिटर"}</Text>
                                    <Text style={styles.tableHeader}>{"फॅट"}</Text>
                                    <Text style={styles.tableHeader}>{"एसएनएफ"}</Text>
                                    <Text style={styles.tableHeader}>{"दर"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"रक्कम"}</Text>
                                </View>
                                {customerData.map((rowData, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={styles.tableCell}>{rowData.date.substr(5).split('-').reverse().join('/')}</Text>
                                        <Text style={styles.tableCell}>{rowData.kno + ": " + rowData.cname}</Text>
                                        <Text style={styles.tableCell}>{rowData.time}</Text>
                                        <Text style={styles.tableCell}>{rowData.type}</Text>
                                        <Text style={styles.tableCell}>{rowData.liter}</Text>
                                        <Text style={styles.tableCell}>{rowData.fat}</Text>
                                        <Text style={styles.tableCell}>{rowData.snf}</Text>
                                        <Text style={styles.tableCell}>{rowData.rate}</Text>
                                        <Text style={styles.tableCell}>{rowData.amt}</Text>
                                        {/* Add more data cells as needed */}
                                    </View>
                                ))}
                                <View style={styles.tableRow3}>
                                    <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeader}>{totals.tliter}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeaderlast}>{totals.tamt}</Text>

                                </View>
                            </View>

                        </Page>
                        :
                        <Page size="A4" orientation="landscape">
                            <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                            <Text style={styles.latoText}>{"वाढ-तूट रिपोर्ट"}</Text>
                            <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                            <View style={styles.imagerow}>
                                <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                <Image style={styles.image} src={Logo} />
                            </View>

                            <View style={styles.table}>
                                {/* Table Header */}
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableHeader}>{"दिनांक"}</Text>
                                    <Text style={styles.tableHeader}>{"वेळ."}</Text>
                                    <Text style={styles.tableHeader}>{"म्हैस लिटर"}</Text>
                                    <Text style={styles.tableHeader}>{"म्हैस रक्कम"}</Text>
                                    <Text style={styles.tableHeader}>{"गाय लिटर"}</Text>
                                    <Text style={styles.tableHeader}>{"गाय रक्कम"}</Text>
                                    <Text style={styles.tableHeader}>{"एकूण लिटर"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"एकूण रक्कम"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"संघ विक्री (-)"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"रतीब विक्री (-)"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"वाढ/तूट (=)"}</Text>
                                </View>
                                {customerData.map((rowData, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={styles.tableCell}>{rowData.date}</Text>
                                        <Text style={styles.tableCell}>{rowData.time}</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.mhliter).toFixed(2)}</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.mhamt).toFixed(2)}</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.gayliter).toFixed(2)}</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.gayamt).toFixed(2)}</Text>
                                        <Text style={styles.tableCell}>{rowData.mhliter && rowData.mhliter !== null && rowData.gayliter && rowData.gayamt !== null ? (parseFloat(rowData.mhliter) + parseFloat(rowData.gayliter)).toFixed(2) : 0 }</Text>
                                        <Text style={styles.tableCell}>{rowData.mhamt && rowData.mhamt !== null && rowData.gayamt && rowData.gayamt !== null ? (parseFloat(rowData.mhamt) + parseFloat(rowData.gayamt)).toFixed(2) : 0 }</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.sanghtotal).toFixed(2)}</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.totalratib).toFixed(2)}</Text>
                                        <Text style={styles.tableCell}>{parseFloat(rowData.vadhtut).toFixed(2)}</Text>
                                    </View>
                                ))}

                                <View style={styles.tableRow3}>
                                    <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                    <Text style={styles.tableHeader}>{" "}</Text>
                                    <Text style={styles.tableHeader}>{parseFloat(totals.tmhliter).toFixed(2)}</Text>
                                    <Text style={styles.tableHeader}>{parseFloat(totals.tmhamt).toFixed(2)}</Text>
                                    <Text style={styles.tableHeader}>{parseFloat(totals.tgayliter).toFixed(2)}</Text>
                                    <Text style={styles.tableHeader}>{parseFloat(totals.tgayamt).toFixed(2)}</Text>
                                    <Text style={styles.tableHeader}>{parseFloat(totals.tliter).toFixed(2)}</Text>
                                    <Text style={styles.tableHeaderlast}>{parseFloat(totals.tamt).toFixed(2)}</Text>
                                    <Text style={styles.tableHeaderlast}>{parseFloat(totals.tsanghtotal).toFixed(2)}</Text>
                                    <Text style={styles.tableHeaderlast}>{parseFloat(totals.ttotalratib).toFixed(2)}</Text>
                                    <Text style={styles.tableHeaderlast}>{parseFloat(totals.tvadhtut).toFixed(2)}</Text>
                                </View>
                            </View>

                        </Page>
                ) : ""}
            </Document>
        </PDFViewer>

    );

};

// ... (styles and export)



const styles = StyleSheet.create({
    page: {
        paddingTop: 10,
        flexDirection: 'row',
        justifyContent: 'center', // Center horizontally
    },
    header: {
        position: 'absolute',
        top: 0, // Adjust this value to change the vertical position
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
    },
    table: {
        display: 'table',
        width: '97%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableone: {
        display: 'table',
        width: '18%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableCellone: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        fontSize: 8,
        fontFamily: 'Lato-Italic',
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },
    tableRowone: {
        flexDirection: 'row',
        width: '100%',
    },
    tableHeaderone: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        border: '1px solid #000',
        padding: 2,
        fontSize: 8,
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },

    tableRow: {
        flexDirection: 'row',

    },
    tableRow2: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
    },

    tableRow3: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 10,
    },
    line: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
        height: 1,
        width: '100vw',
        marginBottom: 5,
    },
    tableHeader: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 0,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        padding: 2,
        flex: 0.5,
        borderColor: 'lightblack',
        textAlign: 'center',
        fontSize: 9,
    },
    tableHeaderlast: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 1,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        borderColor: 'lightblack',
        padding: 2,
        flex: 0.5,
        textAlign: 'center',
        fontSize: 9,
    },
    tableCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontFamily: 'Lato-Italic',
        width: 2,
        textAlign: 'center',
    },
    tableCellgreen: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'green',
        width: 2,
        textAlign: 'center',
    },
    tableCellred: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 9,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'red',
        width: 2,
        textAlign: 'center',
    },
    latoTextlarge: {
        fontFamily: 'Lato-Italic',
        fontSize: 15,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText: {
        fontFamily: 'Lato-Italic',
        fontSize: 14,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText2: {
        fontFamily: 'Lato-Italic',
        fontSize: 11,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
    },
    headtextsmall: {
        fontFamily: 'Lato-Italic',
        fontSize: 10,
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 140,
        paddingRight: 140,
        backgroundColor: 'lightgray',
    },
    column1: {
        display: 'flex',
        flexDirection: 'column',
        width: '19%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column2: {
        display: 'flex',
        flexDirection: 'column',
        width: '15%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,


    },
    column3: {
        display: 'flex',
        flexDirection: 'column',
        width: '17%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column1Row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    column1Rowgray: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 6,
        marginTop: 5,
        border: '0.5px solid black',
        backgroundColor: 'lightgray'
    },
    column1Label: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'left', // Align labels to the left
    },
    column1Value: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'right', // Align values to the right
    },
    pageBreak: {
        pageBreakBefore: 'always',
    },
    totalsRow: {
        flexDirection: 'row',
        borderTop: '1px solid #000',
        paddingTop: 5,
        paddingLeft: 2,
    },
    totalsCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 8,
        width: 2,
        textAlign: 'center',
    },
    imagerow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 80, // Adjust the width as needed
        height: "auto", // Maintain aspect ratio
        marginLeft: -15,
        marginTop: 5

    },
    imgtext: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center', // Align labels to the left
    },
});

export default Sanklanreportprint
