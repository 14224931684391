import React, { useContext, useEffect, useRef, useState } from 'react'
import './Bill30view.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { Co2Sharp, Face6TwoTone, FaxTwoTone, Rowing } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import Globalcontext from '../../Context/Globalcontext';
import Status from "../../imgs/statusmob.jpeg";

const Bill = (props) => {

    const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), knofrom: "", knoto: "", knofromprint: "", knotoprint: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [kapat, setKapat] = useState({});
    const [tableFilter, setTableFilter] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    //const [customerData, setCustomerData] = useState([]);
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    let updatedDataSource = null;
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const [showthevcol, setShowthevcol] = useState("No");
    const [wait, setWait] = useState(false);
    const [wait2, setWait2] = useState(false);
    const [loading, setLoading] = useState(false);
    const { customerData, setCustomerData } = useContext(Globalcontext);
    const refprint = useRef(null);
    const refprintclose = useRef(null);

    const handleDelete = () => {
        refdelete.current.click();
    }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }

    const handlePrint = () => {
        refprint.current.click();
    }
    const handleprintclose = () => {
        refprintclose.current.click();
    }

    const fetchtabledata = () => {

        if (DataSource[0].flag === "oldbill") {
            if (DataSource[0].lastbill && DataSource[0].lastbill === "No") {
                notifyerror("आपण फक्त शेवटचे सेव्ह केले बील अपडेट करू शकता!");
                return;
            }
        }

        try {
            const table = document.getElementById('myTablebill');
            const rows = table.getElementsByTagName('tr');

            const data = [];

            for (let i = 1; i < rows.length; i++) { // Start from 1 to skip header row
                const row = rows[i];
                const cells = row.getElementsByTagName('td');

                const rowData = {
                    // Assuming the order of cells matches your column order
                    date1: input.date1,
                    date2: input.date2,
                    kno: cells[0].textContent,
                    bill: cells[2].textContent,
                    tliter: cells[3].textContent,
                    magilthev: cells[4].textContent,
                    cthev: cells[5].textContent,
                    newthev: cells[6].textContent,
                    madv: cells[7].textContent,
                    cadv: cells[8].textContent,
                    tadv: cells[9].textContent,
                    advcut: cells[10].textContent,
                    newadv: cells[11].textContent,
                    mkhadya: cells[12].textContent,
                    ckhadya: cells[13].textContent,
                    tkhadya: cells[14].textContent,
                    khadyacut: cells[15].textContent,
                    newkhadya: cells[16].textContent,
                    ikapatliter: cells[17].textContent,
                    ikapatbill: cells[18].textContent,
                    vah: cells[19].textContent,
                    ada: cells[20].textContent,


                };
                data.push(rowData);
            }
            const insertQuery = `INSERT INTO bill (date1, date2, kno, billamt,magilthev,cthev,newthev,madv,cadv,tadv,advkapat,newadv,mkhadya,ckhadya,tkhadya,khadyakapat,newkhadya,ikapatliter,ikapatbill,vah,ada,mainuser) VALUES
      ${data.map(row => `('${row.date1}', '${row.date2}', '${row.kno}', '${row.bill}','${row.magilthev}','${row.cthev}','${row.newthev}','${row.madv}', '${row.cadv}', '${row.tadv}', '${row.advcut}','${row.newadv}','${row.mkhadya}', '${row.ckhadya}', '${row.tkhadya}', '${row.khadyacut}','${row.newkhadya}','${row.ikapatliter}','${row.ikapatbill}','${row.vah}','${row.ada}','${localStorage.getItem("contact")}')`).join(', ')}`;

            savebill(insertQuery, data);
        }
        catch {
        }
    }

    const savebill = (insertQuery, data) => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (data.length === 0 || insertQuery === "" || input.date1 === "" || input.date2 === "" || input.knofrom === "" || input.knoto === "") {
            notifyerror("अपूर्ण एंट्री माहिती!");

            return;
        }
        let rawData = {
            insertQuery: insertQuery,
            mainuser: localStorage.getItem("contact"),
            date1: input.date1,
            date2: input.date2,
            mainuser2: mainuser2,

        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/savebill.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {


            if (response.data.trim() === "Saved.") {
                notify("सभासद बील सेव्ह झाले!");
                getbill();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    useEffect(() => {
        const currentDate1 = new Date(input.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));
        }
        setDataSource([]);
        setTableFilter([]);
    }, [input.date1]);


    useEffect(() => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let firstDayOfMonth;
        let lastDayOfMonth;

        if (dayOfMonth >= 1 && dayOfMonth <= 10) {
            firstDayOfMonth = 1;
            lastDayOfMonth = 10;
        } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
            firstDayOfMonth = 11;
            lastDayOfMonth = 20;
        } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
            firstDayOfMonth = 21;
            lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
        }

        if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
            const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
        }

    }, []);

    const showwait = ()=>{
        notifyerror("बिल लोड होत आहे,कृपया प्रतीक्षा करा..")
    }

    const handleCellKeyPress12 = (e, rowIndex) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (rowIndex + 1 < cellRefs.current.length) {
                const nextRow = cellRefs.current[rowIndex + 1];
                if (nextRow && nextRow[1]) { // Use nextRow[1] for column 12
                    nextRow[1].focus();
                }
            }
            e.target.blur(); // Remove focus from the cell
        } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
            // Prevent non-numeric input
            if (isNaN(e.key) && e.key !== '.') {
                e.preventDefault();
            }
        }
    };

    const handleChangeactive = (event) => {
        var checked = event.target.checked;

        if (event.target.checked) {
            setShowthevcol("Yes");
        }
        else {
            setShowthevcol("No");
        }
    }


    const showprint = async () => {
       
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbill30.php', {
                mainuser: localStorage.getItem('contact'),
                date1: input.date1,
                date2: input.date2,
                knofrom:input.knofrom,
                mainuser2: mainuser2,
               
            });
                localStorage.setItem("date1print", input.date1);
                localStorage.setItem("date2print", input.date2);
                localStorage.setItem("customerData", JSON.stringify(response.data));
                localStorage.setItem("kapat", JSON.stringify(kapat));
                const url = "/billprint30";
               
               window.open(url, '_blank');  //'_self'  _blank
          
        } catch (error) {
            notifyerror(error);
            // Handle errors here
            console.log(error);
        } finally {
            setWait(false);

        }
    };

    const addOrUpdateCuchal = (kno, cuchal) => {
        setKapat((prevKapat) => {
            const updatedKapat = { ...prevKapat };

            if (updatedKapat[kno]) {
                updatedKapat[kno].cuchal = cuchal;
            } else {
                updatedKapat[kno] = { cuchal, ckhadya: '' };
            }

            return updatedKapat;
        });
    };

    const addOrUpdateCkhadya = (kno, ckhadya) => {
        setKapat((prevKapat) => {
            const updatedKapat = { ...prevKapat };

            if (updatedKapat[kno]) {
                updatedKapat[kno].ckhadya = ckhadya;
            } else {
                updatedKapat[kno] = { cuchal: '', ckhadya };
            }

            return updatedKapat;
        });
    };

    const handleCellKeyPress = (e, rowIndex) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (rowIndex + 1 < cellRefs.current.length) {
                const nextRow = cellRefs.current[rowIndex + 1];
                if (nextRow[0]) {
                    nextRow[0].focus();
                }
            }
            e.target.blur(); // Remove focus from the cell
        } else if (e.key !== 'Backspace' && e.key !== '-' && !/Arrow(Left|Right)/.test(e.key)) {
            // Prevent non-numeric input
            if (isNaN(e.key) && e.key !== '.') {
                e.preventDefault();
            }
        }
    };

    const handleCellChange = (e, rowIndex, kno) => {
        let newValue = e.target.textContent;
        updatedDataSource = [...DataSource];

        if (updatedDataSource[rowIndex]) {
            updatedDataSource[rowIndex].action = newValue;
            // Calculate the result (column 6 - column 7) and store in column 8
            let column6Value = "";

            if (DataSource[0].flag === "oldbill") {
                column6Value = parseFloat(updatedDataSource[rowIndex].newadv) + parseFloat(updatedDataSource[rowIndex].cuchal);
            }
            else {
                column6Value = (parseFloat(updatedDataSource[rowIndex].bcount) > 0 ? parseFloat(updatedDataSource[rowIndex].newadv) : parseFloat(updatedDataSource[rowIndex].obal)) + parseFloat(updatedDataSource[rowIndex].cuchal);
            }


            const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;
            // console.log("column7Value-", column7Value);
            updatedDataSource[rowIndex].result = column6Value - column7Value;


            // console.log("Bcount-", parseFloat(updatedDataSource[rowIndex].bcount));
            // console.log("newadv-", parseFloat(updatedDataSource[rowIndex].newadv));
            // console.log("obal-", parseFloat(updatedDataSource[rowIndex].obal));
            // console.log("cuchal-", parseFloat(updatedDataSource[rowIndex].cuchal));

            // console.log("Newvalue-", newValue);
            // console.log("column6Value-", column6Value);

            if (newValue) {
                addOrUpdateCuchal(kno, newValue);
                if (DataSource[0].flag === "oldbill") {
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (
                            parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                            parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                            (newValue ? parseFloat(newValue) : 0) +
                            (
                                (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                                    ? parseFloat(updatedDataSource[rowIndex].khedit)
                                    : parseFloat(updatedDataSource[rowIndex].khadyakapat)
                            ) +
                            (updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0) +
                            (updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0) +
                            (updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)
                        );
                    //  console.log(updatedDataSource[rowIndex].adacalculated);
                }
                else {
                    //console.log("khedit - ", parseFloat(updatedDataSource[rowIndex].khedit));
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (
                            parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                            parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                            (parseFloat(newValue) ? parseFloat(newValue) : 0) +
                            (
                                (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                                    ? parseFloat(updatedDataSource[rowIndex].khedit)
                                    :
                                    (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null)
                                        ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
                                        : 0
                            ) +
                            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
                            parseFloat((updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) +
                            parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].tliter) : 0));
                }
            }
            else {
                addOrUpdateCuchal(kno, newValue);
                if (DataSource[0].flag === "oldbill") {

                    const mhliter = updatedDataSource[rowIndex].mhliter;
                    const mhthev = updatedDataSource[rowIndex].mhthev;
                    const gayliter = updatedDataSource[rowIndex].gayliter;
                    const gaythev = updatedDataSource[rowIndex].gaythev;
                    const khedit = parseFloat(updatedDataSource[rowIndex].khedit);
                    const khadyakapat = parseFloat(updatedDataSource[rowIndex].khadyakapat) || 0;
                    const ikapatliter = parseFloat(updatedDataSource[rowIndex].ikapatliter) || 0;
                    const ikapatbill = parseFloat(updatedDataSource[rowIndex].ikapatbill) || 0;
                    const vah = parseFloat(updatedDataSource[rowIndex].vah) || 0;
                    const bill = parseFloat(updatedDataSource[rowIndex].bill);

                    // Calculate adacalculated
                    //console.log("khedit: ", khedit);

                    updatedDataSource[rowIndex].adacalculated = bill - (
                        mhliter * mhthev +
                        gayliter * gaythev +
                        ((!isNaN(khedit) && khedit !== null) ? khedit : khadyakapat) +
                        ikapatliter +
                        ikapatbill +
                        vah
                    );

                    //console.log(updatedDataSource[rowIndex].adacalculated);
                }
                else {
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                            parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                            (
                                (!isNaN(updatedDataSource[rowIndex].khedit) && updatedDataSource[rowIndex].khedit !== null)
                                    ? parseFloat(updatedDataSource[rowIndex].khedit)
                                    :
                                    (!isNaN(updatedDataSource[rowIndex].khadyahpta) && updatedDataSource[rowIndex].khadyahpta !== null)
                                        ? parseFloat(updatedDataSource[rowIndex].khadyahpta)
                                        :
                                        parseFloat(updatedDataSource[rowIndex].khadyakapat)
                            ) +
                            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
                            parseFloat((updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) + parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].tliter) : 0)))
                }
            }

            if (!newValue) {
                newValue = 0;
            }
            updatedDataSource[rowIndex].advedit = newValue;
            setDataSource(updatedDataSource);
        }

        e.target.contentEditable = 'true'; // Re-enable editing
    };

    const handleCellBlurkh = (e, rowIndex) => {
        const newValue = e.target.textContent.trim();

        if (!newValue) {
            e.target.textContent = '0'; // Set to '0' if empty
        }
    };

    const handleCellfocus = (e, rowIndex) => {
        if (e.target.textContent === "0") {
            e.target.textContent = '';
        }
    }

    const handleCellBlur = (e, rowIndex) => {
        const newValue = e.target.textContent.trim();

        if (!newValue) {
            e.target.textContent = '0'; // Set to '0' if empty
        }
    };

    const handleCellChangekh = (e, rowIndex, kno) => {
        let newValue = e.target.textContent;
        updatedDataSource = [...DataSource];

        if (updatedDataSource[rowIndex]) {
            updatedDataSource[rowIndex].action = newValue;

            // Calculate the result (column 6 - column 7) and store in column 8
            const column6Value = parseFloat(updatedDataSource[rowIndex].newkhadya) + parseFloat(updatedDataSource[rowIndex].ckhadya);
            const column7Value = newValue && !isNaN(newValue) && newValue !== null ? parseFloat(newValue) : 0;
            updatedDataSource[rowIndex].result2 = column6Value - column7Value;

            if (newValue) {
                addOrUpdateCkhadya(kno, newValue);
                if (DataSource[0].flag === "oldbill") {
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (
                            parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                            parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                            (
                                (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                                    ? parseFloat(updatedDataSource[rowIndex].advedit)
                                    : parseFloat(updatedDataSource[rowIndex].advkapat)
                            ) +
                            (newValue ? parseFloat(newValue) : 0) +
                            parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
                            parseFloat(updatedDataSource[rowIndex].ikapatbill || 0) +
                            parseFloat(updatedDataSource[rowIndex].vah || 0)
                        );
                }
                else {
                    // console.log("advedit - ", parseFloat(updatedDataSource[rowIndex].advedit));
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (
                            parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                                parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                                (
                                    (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                                        ? parseFloat(updatedDataSource[rowIndex].advedit) :
                                        (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null)
                                            ? parseFloat(updatedDataSource[rowIndex].advhpta)
                                            : 0
                                ) +
                                parseFloat(newValue ? parseFloat(newValue) : 0) +
                                parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
                                parseFloat((updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) +
                                parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].tliter) : 0)));
                }
            }
            else {
                addOrUpdateCkhadya(kno, newValue);
                if (DataSource[0].flag === "oldbill") {
                    //updatedDataSource[rowIndex].adacalculated = parseFloat(updatedDataSource[rowIndex].bill) - (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) + parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) + (parseFloat(updatedDataSource[rowIndex].advedit) ? parseFloat(updatedDataSource[rowIndex].advedit) : parseFloat(updatedDataSource[rowIndex].advkapat ? updatedDataSource[rowIndex].advkapat : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter) : 0)) + parseFloat((updatedDataSource[rowIndex].ikapatbill ? parseFloat(updatedDataSource[rowIndex].ikapatbill) : 0)) + parseFloat(updatedDataSource[rowIndex].vah ? parseFloat(updatedDataSource[rowIndex].vah) : 0)));
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (
                            parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                            parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                            (
                                (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                                    ? parseFloat(updatedDataSource[rowIndex].advedit)
                                    : parseFloat(updatedDataSource[rowIndex].advkapat)
                            ) +
                            parseFloat(updatedDataSource[rowIndex].ikapatliter || 0) +
                            parseFloat(updatedDataSource[rowIndex].ikapatbill || 0) +
                            parseFloat(updatedDataSource[rowIndex].vah || 0)
                        );
                }
                else {
                    updatedDataSource[rowIndex].adacalculated =
                        parseFloat(updatedDataSource[rowIndex].bill) -
                        (parseFloat(parseFloat(updatedDataSource[rowIndex].mhliter * updatedDataSource[rowIndex].mhthev) +
                            parseFloat(updatedDataSource[rowIndex].gayliter * updatedDataSource[rowIndex].gaythev) +
                            (
                                (!isNaN(updatedDataSource[rowIndex].advedit) && updatedDataSource[rowIndex].advedit !== null)
                                    ? parseFloat(updatedDataSource[rowIndex].advedit)
                                    :
                                    (!isNaN(updatedDataSource[rowIndex].advhpta) && updatedDataSource[rowIndex].advhpta !== null)
                                        ? parseFloat(updatedDataSource[rowIndex].advhpta)
                                        : parseFloat(updatedDataSource[rowIndex].advkapat)
                            ) +
                            parseFloat((updatedDataSource[rowIndex].ikapatliter2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].ikapatliter2 * updatedDataSource[rowIndex].tliter) : 0)) +
                            parseFloat((updatedDataSource[rowIndex].ikapatbill2 ? parseFloat(updatedDataSource[rowIndex].ikapatbill2) : 0)) +
                            parseFloat(updatedDataSource[rowIndex].vah2 && updatedDataSource[rowIndex].tliter ? parseFloat(updatedDataSource[rowIndex].vah2 * updatedDataSource[rowIndex].tliter) : 0)));
                }
            }

            if (!newValue) {
                newValue = 0;
            }
            updatedDataSource[rowIndex].khedit = newValue;
            setDataSource(updatedDataSource);
        }

        e.target.contentEditable = 'true'; // Re-enable editing
    };


    const getbill = async () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.knofrom === "") {
            notifyerror("सभासद क्रमांक भरा");
            setWait(false);
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            quin: input.knofrom,
            rate: input.knoto,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getbillnew.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.length > 0) {
                setDataSource(response.data);

                response.data.map((user, index) => {
                    addOrUpdateCuchal(user.kno, user.advhpta);
                    addOrUpdateCkhadya(user.kno, user.khadyahpta);
                });

                setWait(false);
            } else {
                notifyerror("या कालावधीमध्ये संकलन उपलब्ध नाही!");
                setWait(false);
            }
        } catch (error) {
            console.log(error);
            setWait(false);
        }
    };


    const deletebill = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.knofrom === "" || input.knoto === "") {
            notifyerror("सभासद क्रमांक भरा");
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/deletebill.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            if (response.data.trim() === "Deleted") {
                notify("सभासद बील डिलीट झाले!");
                refdeleteclose.current.click();
                setDataSource([]);

            }

        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (sr, date, time, kno, name, type, liter, fat, snf, rate, amt) => {
        // setInput({ date: date, time: time, kno: kno, name: name, type: type, liter: liter, fat: fat, snf: snf, rate: rate, amt: amt })
        // setNewedit("edit");
        // setSredit(sr);
    }
    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));
    }


    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };




    return (
        <>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">प्रिंट साठी खाते क्रमांक निवडा</h5>
                            <div className='helpmain'>
                                <img className='smallimg' src={Status} alt="" />
                                <input onChange={handleChange} type="number" className="form-control" id="knofromprint" name="knofromprint" value={input.knofromprint} autoComplete='off' />
                                <span> ते </span>
                                <input onChange={handleChange} type="number" className="form-control" id="knotoprint" name="knotoprint" value={input.knotoprint} autoComplete='off' />
                            </div>
                            <span className='note'>सूचना : बिल प्रिंट्स देताना पेपर साईज A5 सेट असल्याची खात्री करा!</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={showprint}>ओके</button>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refdelete}
                data-bs-target="#deletebill"
            >
            </button>

            <div className="modal fade" id="deletebill" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">बील डिलीट करायचे?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={deletebill}>होय</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmainbill">
                        <div className="firstrowbill">
                            <div className="rowbill">
                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill se'> || </span>

                                <div className="mb-2 se">
                                    <span className='subheadsank'>खाते क्र.</span>
                                    <input onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                                </div>


                                <div className="mb-2">
                                    {
                                        wait ?
                                            <button onClick={showwait} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">Loading..</button>
                                            :
                                            <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">मासिक बील पहा</button>
                                    }
                                   
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Bill
