import React, { useContext, useEffect, useRef, useState } from 'react'
import './Box.css'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Globalcontext from '../../Context/Globalcontext';
import Status from "../../imgs/statusmob.jpeg";

const Box = (props) => {

  const { newcustsource, setNewcustsource, sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
  const [input, setInput] = useState({ pass: "" });
  const refdelete = useRef(null);
  const refdeleteclose = useRef(null);
  const refchpass = useRef(null);
  const refchpassclose = useRef(null);
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);

  const handleChange = (event) => {
    const name = event.target.name;
    let valuecurrent = "";

    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }))
  }

  const handledeleteclose = () => {
    refdeleteclose.current.click();
  }

  const handledelete = (knodelete) => {
    refdelete.current.click();
  }

  const handlechpassclose = () => {
    refchpassclose.current.click();
  }

  const handlechpass = (knodelete) => {
    refchpass.current.click();
  }

  async function applogout() {
    let status = "";
    if (localStorage.getItem("mobile") === "On") {
      status = "Off";
    }
    else if (localStorage.getItem("mobile") === "Off") {
      status = "On";
    }
    var mainuser2 = jsEncode.encode("mamapr", 125);

    axios.post('https://idairy.co.in/dairy/php/cust/appsettings.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2, status: status }).then(function sample(response) {
      if (response.data.trim() === "updated.") {
        if (localStorage.getItem("mobile") === "On") {
          notify("मोबाईल ऍप बंद झाले!");
          localStorage.setItem("mobile", "Off");
        }
        else if (localStorage.getItem("mobile") == "Off") {
          notify("मोबाईल ऍप चालू झाले!");
          localStorage.setItem("mobile", "On");
        }
        refdeleteclose.current.click();
      }
      else {
        notifyerror("पुन्हा प्रयत्न करा.");
      }
    }).catch(err => {
      console.log(err);
    });
  }

  async function updatepass() {
  
    var mainuser2 = jsEncode.encode("mamapr", 125);

    axios.post('https://idairy.co.in/dairy/php/cust/updatepass.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2,pass:input.pass}).then(function sample(response) {
      if (response.data.trim() === "updated.") {
          notify("पासवर्ड अपडेट झाला!");
          handlechpassclose();
      }
      else {
        notifyerror("पुन्हा प्रयत्न करा.");
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const openanydesk = () => {
    window.open('https://anydesk.com/en/downloads/windows', '_blank');
  };

  const openteam = () => {
    window.open('https://www.teamviewer.com/en-us/content/free-download-with-license-options/', '_blank');
  }

  const handleclickbox = () => {
    props.visibility();
    if (props.head === "सभासद मस्टर") {
      setHeadname("सभासद मस्टर");
      props.setsubmenu("customer");
      setNewcustsource("dash");
    }
    else if (props.head === "संघ मस्टर") {
      setHeadname("संघ मस्टर");
      props.setsubmenu("sangh")
    }
    else if (props.head === "खाद्य डिलर") {
      setHeadname("खाद्य डिलर");
      props.setsubmenu("khparty")
    }
    else if (props.head === "केंद्र मस्टर") {
      setHeadname("केंद्र मस्टर");
      props.setsubmenu("kendra")
    }
    else if (props.head === "केंद्र दरपत्रक") {
      setHeadname("केंद्र दरपत्रक");
      props.setsubmenu("ratetable")
    }
    else if (props.head === "वाहतूक/कपात सेटिंग") {
      setHeadname("वाहतूक/कपात सेटिंग");
      props.setsubmenu("settings")
    }
    else if (props.head === "बँक मस्टर") {
      setHeadname("बँक मस्टर");
      props.setsubmenu("bank")
    }
    else if (props.head === "नवीन दरपत्रक दुरुस्ती") {
      setHeadname("नवीन दरपत्रक दुरुस्ती");
      props.setsubmenu("nrepair")
    }
    else if (props.head === "संकलन") {
      setHeadname("संकलन");
      props.setsubmenu("sanklan")
      setSanksource("tran");
    }
    else if (props.head === "सभासदानुसार संकलन") {
      setHeadname("सभासदानुसार संकलन");
      props.setsubmenu("sanklanbykno")
      setSanksource("tran");
    }
    else if (props.head === "संघ विक्री") {
      setHeadname("संघ विक्री");
      props.setsubmenu("sanghvikri")
    }
    else if (props.head === "रतीब ग्राहक") {
      setHeadname("रतीब ग्राहक");
      props.setsubmenu("ratibmaster")
    }
    else if (props.head === "खाद्य खरेदी") {
      setHeadname("खाद्य खरेदी");
      props.setsubmenu("kpur")
    }
    else if (props.head === "खाद्य विक्री") {
      setHeadname("खाद्य विक्री");
      props.setsubmenu("ksale")
    }
    else if (props.head === "रतीब विक्री") {
      setHeadname("रतीब विक्री");
      props.setsubmenu("ratibvikri")
    }
    else if (props.head === "रतीब जमा") {
      setHeadname("रतीब जमा");
      props.setsubmenu("ratibjma")
    }
    else if (props.head === "देयबाकी जमा") {
      setHeadname("देयबाकी जमा");
      props.setsubmenu("khpurjma")
    }
    else if (props.head === "एडव्हांस/उचल") {
      setHeadname("एडव्हांस/उचल");
      props.setsubmenu("uchal")
    }
    else if (props.head === "सभासद बील") {
      setHeadname("सभासद बील");
      props.setsubmenu("bill")
    }
    else if (props.head === "पेमेंट रजिस्टर") {
      setHeadname("पेमेंट रजिस्टर");
      props.setsubmenu("pregister")
    }
    else if (props.head === "बँक पेमेंट रिपोर्ट") {
      setHeadname("बँक पेमेंट रिपोर्ट");
      props.setsubmenu("bankreport")
    }
    else if (props.head === "वार्षिक ठेव वाटप") {
      setHeadname("वार्षिक ठेव वाटप");
      props.setsubmenu("thevvatap")
    }
    else if (props.head === "वार्षिक बोनस वाटप") {
      setHeadname("वार्षिक बोनस वाटप");
      props.setsubmenu("bonus")
    }
    else if (props.head === "सभासद खाते") {
      setHeadname("सभासद खाते");
      props.setsubmenu("account")
    }
    else if (props.head === "संकलन रिपोर्ट") {
      setHeadname("संकलन रिपोर्ट");
      props.setsubmenu("sanklanreport")
    }
    else if (props.head === "संघ विक्री/रतीब रिपोर्ट") {
      setHeadname("संघ विक्री/रतीब रिपोर्ट");
      props.setsubmenu("vikrireport")
    }
    else if (props.head === "खाद्य/एड्व्हांस रिपोर्ट") {
      setHeadname("खाद्य/एड्व्हांस रिपोर्ट");
      props.setsubmenu("khadyaadvreport")
    }
    else if (props.head === "सभासद बील (मासिक)") {
      setHeadname("सभासद बील (मासिक)");
      props.setsubmenu("bill30view")
    }
    else if (props.head === "Anydesk") {
      setHeadname("Download Anydesk");
      openanydesk();

    }
    else if (props.head === "Teamviewer") {
      setHeadname("Download Teamviewer"); props.setsubmenu("teamviewer");
      openteam();
    }
    else if (props.head === "Mobile App Seetings") {
      setHeadname("Mobile App Seetings");
      props.setsubmenu("appsettings");
      handledelete();
    }
    else if (props.head === "Change Password") {
      setHeadname("Change Password");
      props.setsubmenu("changepass");
      handlechpass();
    }
    else if (props.head === "Mobile User") {
      setHeadname("Mobile User");
      props.setsubmenu("mobileuser")
    }
  }
  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refdelete}
        data-bs-target="#appsettings"
      >
      </button>

      <div className="modal fade" id="appsettings" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">{localStorage.getItem("mobile") === "On" ? "मोबाईल ऍप बंद करायचे?" : "मोबाईल ऍप सुरू करायचे?"}</h4>
              <div className='helpmain'>
                <img className='smallimg' src={Status} alt="" />
                <span className="heading2">सध्याचे स्टेटस - {localStorage.getItem("mobile")}</span>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}>बाहेर</button>
              <button type="button" className="btn btn-danger" onClick={applogout} >ओके</button>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={refchpass}
        data-bs-target="#chngpass"
      >
      </button>

      <div className="modal fade" id="chngpass" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refchpassclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">नवीन पासवर्ड भरा.</h4>
              <div className='helpmain'>
                <img className='smallimg' src={Status} alt="" />
                <input onChange={handleChange} type="number" className="form-control" id="pass" name="pass" value={input.pass} autoComplete='off' />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handlechpassclose}>बाहेर</button>
              <button type="button" className="btn btn-danger" onClick={updatepass} >ओके</button>
            </div>
          </div>
        </div>
      </div>
      <div onClick={handleclickbox} className="aboutiq">
        <div
          style={{
            background: props.background
          }}
          className="box">
          {props.img}
        </div>
        <span>{props.head}</span>

      </div>
    </>
  )

}

export default Box
