import React, { useContext, useRef, useState } from 'react'
import './Reportscontains.css'
import milkcan from "../../imgs/bill.png";
import khate from "../../imgs/khate.webp";
import sanklan from "../../imgs/sanklanreport.png";
import khadya from "../../imgs/khadya.png";
import pregister from "../../imgs/pregister.png";
import bank from "../../imgs/bank.png";
import Box from '../Box/Box';
import Cowleft from "../../imgs/cowleft.gif";
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import Globalcontext from '../../Context/Globalcontext';
import Bill from '../Bill/Bill';
import Pregister from '../Pregister/Pregister';
import Bankreport from '../Bankreport/Bankreport';
import Pregistervar from '../Pregistervar/Pregistervar';
import Customerreport from '../Customerreport/Customerreport';
import Customerreportvar from '../Customerreportvar/Customerreportvar';
import Sanklanreport from '../Sanklanreport/Sanklanreport';
import Khadyaadvreport from '../Khadyaadvreport/Khadyaadvreport';
import Bill30view from '../Bill30view/Bill30view';
import milkvan from "../../imgs/milkvan.png";
import Vikrireport from '../Vikrireport/Vikrireport';
import Billvar from '../Billvar/Billvar';

const Reportscontains = (props) => {
    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const areUSure = (choose) => {

        handleDialog("", false);

    };

    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("रिपोर्ट्स");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };


    return (
        <>
            <div className='line' />
            <div className="maincon">

                <div className="rowreport">
                    <div className="mainconreport">
                        <span className={`heading1 ${mastermenu ? '' : 'hidden'}`}>१० दिवसीय रिपोर्ट्स</span>
                        <div className={`Dashcontents ${mastermenu ? '' : 'hidden'}`} onTransitionEnd={handleTransitionEnd}>
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t4dark), var(--t4dark))"} head={"सभासद बील"} img={<img src={milkcan} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t6dark), var(--t6dark))"} head={"पेमेंट रजिस्टर"} img={<img src={pregister} alt="" />} />
                        </div>
                    </div>

                    <div className="mainconreport2">
                        <span className={`heading1 ${mastermenu ? '' : 'hidden'}`}>इतर रिपोर्ट्स</span>
                        <div className={`Dashcontents ${mastermenu ? '' : 'hidden'}`} onTransitionEnd={handleTransitionEnd}>
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t4dark), var(--t4dark))"} head={"बँक पेमेंट रिपोर्ट"} img={<img src={bank} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t1dark), var(--t1dark))"} head={"सभासद खाते"} img={<img src={khate} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t2dark), var(--t2dark))"} head={"संकलन रिपोर्ट"} img={<img src={sanklan} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t2dark), var(--t2dark))"} head={"संघ विक्री/रतीब रिपोर्ट"} img={<img src={milkvan} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3dark), var(--t3dark))"} head={"खाद्य/एड्व्हांस रिपोर्ट"} img={<img src={khadya} alt="" />} />
                            {
                                localStorage.getItem("bill10type") === "1" ?
                                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t5dark), var(--t5dark))"} head={"सभासद बील (मासिक)"} img={<img src={milkcan} alt="" />} />
                                    : ''
                            }
                        </div>
                    </div>
                </div>

                <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
                    <div className="first">
                    </div>
                    <div className="second">
                        <div className='line70' />
                        <span className='firstspan'>1. येथे आपण १० दिवसीय रिपोर्ट पाहू शकता व प्रिंट घेऊ शकता.</span>
                        <span className='infospan'>2. तसेच येथे आपण वार्षिक व इतर रिपोर्ट ही पाहू शकता.</span>
                        <span className='infospan'>3. उदा.सभासद बिल,पेमेंट रजिस्टर,सभासद खाते,खाद्य,एड्व्हांस व इतर रिपोर्ट्स </span>
                    </div>
                </div>
                <div className={`cust ${mastermenu ? 'hidden' : ''}`}>
                    {transitionEnded && showBackImage && (
                        submenu === "bill" ? localStorage.getItem("bill10type") === "1" ? <Bill setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : localStorage.getItem("bill10type") === "0" ? <Billvar setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : '' :
                            submenu === "pregister" ? localStorage.getItem("bill10type") === "1" ? <Pregister setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : localStorage.getItem("bill10type") === "0" ? <Pregistervar setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : '' :
                                submenu === "bankreport" ? <Bankreport setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded}/> :
                                submenu === "account" ? localStorage.getItem("bill10type") === "1" ? <Customerreport setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : localStorage.getItem("bill10type") === "0" ? <Customerreportvar setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : '' :
                                    submenu === "sanklanreport" ? <Sanklanreport setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                        submenu === "vikrireport" ? <Vikrireport setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                            submenu === "khadyaadvreport" ? <Khadyaadvreport setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                submenu === "bill30view" ? localStorage.getItem("bill10type") === "1" ? <Bill30view setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> : '' :
                                                    ""
                    )}
                </div>
            </div>
            <div className="secrow">
                <div className="leftsecrow">
                </div>
                {
                    submenu !== "sanklan" && submenu !== "bill" && submenu !== "pregister" && submenu !== 'account' && submenu !== 'sanklanreport' && submenu !== 'khadyaadvreport' && submenu !== 'vikrireport'  && submenu !== 'bankreport' ?
                        <div className="imgcon">
                            <img className='imgcowtran' src={Cowleft} alt="" />
                        </div>
                        : ""
                }

            </div>

            {dialog.isLoading && (
                <Dialog
                    //Update
                    submessage={dialog.nameProduct}
                    onDialog={areUSure}
                    message={dialog.message}
                    functionname={openkendra}
                    button1text="नाही"
                    button2text="होय"
                />
            )}
        </>
    )
}

export default Reportscontains
