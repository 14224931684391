import React, { useEffect, useRef, useState } from 'react'
import './Pregistervar.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { Co2Sharp, Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';

const Pregister = (props) => {
    const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), knofrom: "", knoto: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let firstDayOfMonth;
        let lastDayOfMonth;

        if (dayOfMonth >= 1 && dayOfMonth <= 10) {
            firstDayOfMonth = 1;
            lastDayOfMonth = 10;
        } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
            firstDayOfMonth = 11;
            lastDayOfMonth = 20;
        } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
            firstDayOfMonth = 21;
            lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
        }

        if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
            const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
        }

        getmaxno();
    }, []);

    async function getmaxno() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmaxno.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setInput(prevInput => ({
                    ...prevInput,
                    knofrom: "1",
                    knoto: response.data,
                }))
            }
            else {
                setInput(prevInput => ({
                    ...prevInput,
                    knofrom: "0",
                    knoto: "0"
                }))
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        const currentDate1 = new Date(input.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
            lastDayOfMonth1 = 10;
        } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
            lastDayOfMonth1 = 20;
        } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
            lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));
        }
        setDataSource([]);
        setTableFilter([]);
    }, [input.date1]);

    const getpregister = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.knofrom === "" || input.knoto === "") {
            notifyerror("सभासद क्रमांक भरा");
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            knofrom: input.knofrom,
            knoto: input.knoto,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getpregistervar.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
         //    console.log(response.data);
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);
            }
            else {
                notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
            const url = "/pregisterprintvar";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
        }
    }

    const handleCellKeyPress = (e, rowIndex) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (rowIndex + 1 < cellRefs.current.length) {
                const nextRow = cellRefs.current[rowIndex + 1];
                nextRow[0].focus();
            }
            e.target.blur(); // Remove focus from the cell
        } else if (isNaN(e.key) && e.key !== 'Backspace') {
            e.preventDefault(); // Prevent non-numeric input
        }
    };

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <div className="sanklanmain">
            <div className="firstrowsank">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmain">
                    <div className="firstrow2">
                        <div className="rowbill">
                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक</span>
                                <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                            </div>

                            <span className='subheadbill'>ते</span>

                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक</span>
                                <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                            </div>

                            <span className='subheadbill se'> || </span>

                            <div className="mb-2 se">
                                <span className='subheadsank'>खाते क्र.</span>
                                <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                            </div>

                            <span className='subheadbill'>ते</span>

                            <div className="mb-2">
                                <span className='subheadsank'>खाते क्र</span>
                                <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>
                            </div>

                            <div className="mb-2">

                                <button onClick={getpregister} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रजिस्टर पहा</button>
                                <button onClick={showprint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">बील प्रिंट</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="firstrow2">
                <div class="table-containerbill">
                    <table id="myTablebill" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">खाते क्रमांक var</th>
                                <th className='thlarge' scope="col">नाव</th>
                                <th className='thsmall' scope="col">ए.लिटर</th>
                                <th className='thsmall' scope="col">ए.बील</th>
                                <th className='thsmall' scope="col">मागील ठेव</th>
                                <th className='thsmall' scope="col">ठेव कपात</th>
                                <th className='thsmall' scope="col">एकूण ठेव</th>
                                <th className='thsmall' scope="col">मागील बाकी</th>
                                <th className='thsmall' scope="col">चालू खाद्य</th>
                                <th className='thsmall' scope="col">चालू एडव्हान्स</th>
                                <th className='thsmall' scope="col">एकूण बाकी</th>
                                <th className='thsmall' scope="col">प्रत्यक्ष कपात</th>
                                <th className='thsmall' scope="col">येणेबाकी</th>
                                <th className='thsmall' scope="col">इ.कपात/ली</th>
                                <th className='thsmall' scope="col">इ.कपात/बील</th>
                                <th className='thsmall' scope="col">वाहतूक</th>
                                <th className='thsmall' scope="col">अदा</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DataSource && DataSource.map((user, index) =>
                                <tr key={index}>
                                    <td className='thsmall'>{user.kno}</td>
                                    <td className='thsmall'>{user.cname}</td>
                                    <td className='thsmall2'>{parseFloat(user.tliter).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.billamt).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.magilthev).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.cthev).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.newthev).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.magilbaki).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.ckhadya).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.cadv).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.tbaki).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.tkapat).toFixed(2)}</td>
                                    <td className='thsmall2'>{parseFloat(user.yenebaki).toFixed(2)}</td>
                                    <td className='thsmall2'>{user.ikapatliter ? parseFloat(user.ikapatliter).toFixed(2) : 0}</td>
                                    <td className='thsmall2'>{user.ikapatbill ? user.ikapatbill : 0}</td>
                                    <td className='thsmall2'>{user.vah ? parseFloat(user.vah) : 0}</td>
                                    <td className=' thsmall2'>{parseFloat(parseFloat(user.billamt) - parseFloat(user.tkapat)).toFixed(0)}</td>
                                    

                                </tr>
                            )
                            }
                        </tbody>
                        <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                            <td className="thsmall" colSpan="2">एकूण</td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.tliter), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.billamt), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.magilthev), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.cthev), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.newthev), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.magilbaki), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.ckhadya), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.cadv), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.tbaki), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.tkapat), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.yenebaki), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.ikapatliter), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.ikapatbill), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(user.vah), 0).toFixed(2)}
                            </td>
                            <td className="thsmall2">
                                {DataSource.reduce((total, user) => total + parseFloat(parseFloat(user.billamt) - parseFloat(user.tkapat)),0).toFixed(0)}
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Pregister
