import React, { useEffect, useRef, useState,useContext } from 'react'
import './Ratibjma.css'
import back from "../../imgs/backexam.png";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';



const Ratibjma = (props) => {
    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), kno: "", name: "", type: "", amt: "", remark: "-" });
    const { submenu, setsubmenu, newcustsource, setNewcustsource, custlist, setCustlist } = useContext(Globalcontext);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');
    const [mastermenu, setMastermenu] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
    const [transitionEnded, setTransitionEnded] = useState(true);

    function handleEnterKey(event, currentIndex) {

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
              (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
          )) {
            event.preventDefault();
      
            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
              const prevElement = inputRefs.current[prevIndex];
              if (prevElement) {
                if (
                  (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                  prevElement !== event.target
                ) {
                  prevElement.focus();
                  break;
                } else {
                  prevIndex--;
                }
              } else {
                prevIndex--;
              }
            }
          }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

    }

    useEffect(() => {

        if (input.kno === "") {
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                type: "",
                amt: "",
                remark: "",

            }))

            setNewedit("new");
        }
        else {
           // getcustdata(input.kno);
           let custlisttemp = JSON.parse(localStorage.getItem("sanghlist"));
           for (let i = 0; i < custlisttemp.length; i++) {
            setInput(prevInput => ({
              ...prevInput,
              name: ''
            }));
            const user = custlisttemp[i];
            if (user.rno === input.kno) {
              setInput(prevInput => ({
                ...prevInput,
                name: user.rname
              }));
              break; // Exit the loop once the condition is met
            }
           }

        }
    }, [input.kno])


    async function getratibcustomer() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getratibcustomer.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                localStorage.setItem("sanghlist", JSON.stringify(response.data));
            }
            else {
               // setInput({ rno: 1 })
            }

        }).catch(err => {
            console.log(err);
        });
    }

    async function getcustdata(kno) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getcustomer.php', { kno: kno, mainuser: localStorage.getItem("contact"),mainuser2:mainuser2 }).then(function sample(response) {

            if (response.data.length > 0) {
                response.data.map((user, index) =>
                (
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname,
                    }))
                )
                )
            }
            setTimeout(() => {
                props.setProgress(100);
            }, 200);
        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            saveratibjma();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace")
        {
          if(event.target.tagName === "BUTTON")
          {
            if (inputRefs.current[4]) {
              inputRefs.current[4].focus();
            }
          }
        }
    }

    const saveratibjma = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.date === "" || input.kno === "" || input.name === "" || input.amt === "" || input.remark === "") {
            notifyerror("अपूर्ण एंट्री माहिती!");
            //console.log(input);
            return;
        }
        let rawData = {
            date: input.date,
            kno: input.kno,
            type: input.type,
            amt: input.amt,
            remark: input.remark,
            mainuser: localStorage.getItem("contact"),
            mainuser2:mainuser2,
            flag: newedit,
            sredit: sredit,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/saveratibjma.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
          //  console.log(response.data);
            if (newedit === "new") {
                if (response.data.trim() === "Saved.") {
                    notify("एंट्री सेव्ह झाली!");

                }
            }
            else {
                if (response.data.trim() === "updated.") {
                    notify("एंट्री अपडेट झाली!")
                }
            }
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                type: "",
                amt: "",
                remark: "",
            }))
            setSredit("");
            setNewedit("new");
            getratibjma();
            if (inputRefs.current[1]) {
                inputRefs.current[1].focus();
            }

        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getratibjma();
        if (inputRefs.current[1]) {
            inputRefs.current[1].focus();
        }
    }, [])


    async function getratibjma() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getratibjma.php', { date: input.date, time: input.time, mainuser: localStorage.getItem("contact"),mainuser2:mainuser2 }).then(function sample(response) {
          //  console.log(response.data);
            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const handleedit = (sr, date, rno,amt,remark) => {
        setInput({ sr: sr, date: date, kno: rno, amt: amt,remark:remark})
        setNewedit("edit");
        setSredit(sr);
    }

    async function deleteratibjma(sr) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deleteratibjma.php', { sr: sr, mainuser: localStorage.getItem("contact"),mainuser2:mainuser2}).then(function sample(response) {
            if (response.data) {
                notify(response.data);
                setInput(prevInput => ({
                    ...prevInput,
                    kno: "",
                    name: "",
                    type: "",
                    amt: "",
                }))
                setSredit("");
                setNewedit("new");
                getratibjma();
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }

            }

        }).catch(err => {
            console.log(err);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    const openratibvikri = (flag) => {
        setsubmenu("ratibvikri");
        setHeadname("रतीब विक्री");
        <Ratibjma setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} visibility={visibility} /> 
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("दैनंदिन व्यवहार");
    }

    return (
        <div className="khadyamain">
            <div className="firstrowkhadya">
                <img onClick={openratibvikri} className='back' src={back} alt="" />
                <div className="colmainkhadya">
                    <div className="firstrow2">
                        <div className="rowsank">
                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक</span>
                                <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='linekh' />
            <div className="row1ratibjma">
                <div className="mb-2">
                    <span className='subheadsank'>खाते क्रमांक</span>
                    <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                </div>
                <div className="mb-2">
                    <span className='subheadsank'>रतिब ग्राहक नाव</span>
                    <input type="text" disabled className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                </div>
            </div>
            <div className='linekh' />
            <div className="row1ratibjma">
                <div className="mb-2">
                    <span className='subheadsank'>प्रकार</span>
                    <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} value={input.type} name='type' id='type' onChange={handleChange} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} >
                
                        <option value="जमा">जमा</option>
                    
                    </select>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>रक्कम</span>
                    <input onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="amt" name="amt" value={input.amt} onChange={handleChange} ></input>
                </div>

                <div className="mb-2">
                    <span className='subheadsank'>रिमार्क</span>
                    <input type="text" onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="remark" name="remark" value={input.remark} onChange={handleChange} ></input>
                </div>

            </div>
            <div className="row1kh">
                <div className="mb-2">

                    <button onClick={saveratibjma} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[5] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">

                        {newedit === "new" ?
                            "सेव्ह" :
                            "अपडेट"
                        }
                    </button>
                    <button type="submit" className="btn btn-primary mx-1 sanksmall">रिपोर्ट</button>
                </div>
            </div>
            <div className="firstrow2">
                <div class="table-containeruchal">
                    <table id="myTable" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">क्र.</th>
                                <th className='thsmall' scope="col">दिनांक</th>
                                <th className='thsmall' scope="col">खाते क्र</th>
                                <th className='thsmall' scope="col">नाव</th>
                                <th className='thsmall' scope="col">रक्कम</th>
                                <th className='thsmall' scope="col">एक्शन</th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.length > 0 ? tableFilter.map((user, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.date}</td>
                                    <td>{user.rno}</td>
                                    <td>{user.name}</td>
                                    <td>{user.amt}</td>
                                    {/* <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { deletesanklan(user.sr) }} v className="icsank" /></td> */}
                                </tr>
                            )
                                :
                                DataSource.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmall'>{index + 1}</td>
                                        <td className='thsmall'>{user.date}</td>
                                        <td className='thsmall'>{user.rno}</td>
                                        <td className='thsmall'>{user.name}</td>
                                        <td className='thsmall2'>{user.amt}</td>
                                        <td><UilEditAlt onClick={() => {handleedit(user.sr, user.date, user.rno, user.amt,user.remark) }} className="icsank" /><UilTrash onClick={() => { deleteratibjma(user.sr) }} v className="icsank" /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Ratibjma
