import React, { useContext, useState } from 'react'
import { motion } from 'framer-motion'
import TocRoundedIcon from '@mui/icons-material/TocRounded';

import DesktopWindowsRoundedIcon from '@material-ui/icons/DesktopWindowsRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import ComputerRoundedIcon from '@material-ui/icons/ComputerRounded';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import WbIncandescentRoundedIcon from '@material-ui/icons/WbIncandescentRounded';
import TabRoundedIcon from '@material-ui/icons/TabRounded';
import TabletAndroidRoundedIcon from '@material-ui/icons/TabletAndroidRounded';
import { SidebarData0 } from "../../Data/Data";
import { SidebarData1 } from "../../Data/Data";
import { SidebarData2 } from "../../Data/Data";
import { SidebarData3 } from "../../Data/Data";
import Items from '../../componants/Item/Items';
import Logo from "../../imgs/logo.png";
import './Sidebar.css'
//import Body from '../Body/Body';
import Dashboard from '../Dashboard/Dashboard'
import { useLocation, useNavigate } from "react-router-dom";
import Transections from '../Transections/Transections';
import Reports from '../Reports/Reports';
import Other from '../Other/Other';
import Globalcontext from '../../Context/Globalcontext';
import Extra from '../Extra/Extra';
import Help from '../Help/Help';
import Registration from '../Registration/Registration';
import Idairygrow from '../Idairygrow/Idairygrow';

const Sidebar = (props) => {

  const [open, setOpen] = useState(true);
  const [testname, setTestname] = useState();
  const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
  const handleToggle = () => {
    // setOpen(!open);
  }

  const tohome = () => {
    navigate("/Dashboard");
  }

  const location = useLocation();
  const sideContainerVariants = {
    true: {
      width: '14rem',
    },
    false: {
      transition: {
        delay: 0.4
      }
    }
  }

  const sidebarvariants = {
    true: {},
    false: {
      width: '3rem',
      transition: {
        delay: 0.4
      }
    }
  }

  const profilevariants = {
    true: {
      alignSelf: 'center',
      width: '8rem'
    },
    false: {
      alignSelf: 'flex-start',
      left: '0',
      marginTOp: '2rem',
      width: '3.5rem',
      transition: {
        delay: 0.4
      }
    }
  }

  const navigate = useNavigate();
  return (
    <div className='main2'>
      <motion.div
        data-open={open}
        variants={sideContainerVariants}
        initial={`${open}`}
        animate={`${open}`}
        className="sidebar_container">

        <motion.div
          initial={`${open}`}
          animate={`${open}`}
          variants={sidebarvariants}
          className="sidebar">
          <motion.div
            whileHover={
              {
                scale: 1.2,
                rotate: 180,
                backgroundColor: "rgba(255,255,255,0.3)",
                backdrpFilter: "blur(3.5px)",
                WebkitBackdropFilter: "blur(3.5px)",
                border: "1px solid rgba(255,255,255,0.18)",
                cursor: "pointer",
                transition: {
                  delay: 0.2,
                  duration: 0.4
                }
              }
            }
            onClick={handleToggle}
            className="lines_icon">
            <TocRoundedIcon />
          </motion.div>
          <motion.div
            initial={`${open}`}
            animate={`${open}`}
            variants={profilevariants}
            transition={{ duration: 0.4 }}
            whileHover={{
              backdrpFilter: "blur(5.5px)",
              borderRadius: "0px",
              border: "1px solid rgba( 255, 255, 255, 0.18 )"
            }}
            onClick={tohome}
            className="profile">
            <img src={Logo} alt="" />
          </motion.div>
          <div className="groups">
            {/* <div className="group">
              <motion.h3
                animate={{ opacity: open ? 1 : 0, height: open ? 'auto' : 0 }}
              >डॅशबोर्ड</motion.h3>
              {SidebarData0.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setTestname(item.heading2);
                      location.pathname = item.pathname;
                      if (item.pathname === "/idairygrow") {
                        setHeadname("आय डेअरी ग्रो");
                      }
                    }}
                  >
                    <Items icon={<item.icon />} name={item.heading2} />
                  </div>
                );
              })}
            </div> */}
            <div className="group">
              <motion.h3
                animate={{ opacity: open ? 1 : 0, height: open ? 'auto' : 0 }}
              >डेअरी विभाग</motion.h3>
              {SidebarData1.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      // console.log(item.pathname);
                      setTestname(item.heading2);
                      location.pathname = item.pathname;
                      if (item.pathname === "/Dashboard") {
                        setHeadname("मास्टर माहिती");
                      }
                      else if (item.pathname === "/Transections") {
                        setHeadname("दैनंदिन व्यवहार");
                      }
                      else if (item.pathname === "/ivouchers") {
                        setHeadname("इतर व्हाऊचर");
                      }
                      else if (item.pathname === "/reports") {
                        setHeadname("रिपोर्ट्स");
                      }
                    }}
                  >
                    <Items icon={<item.icon />} name={item.heading2} />

                  </div>
                );
              })}
            </div>
            <div className="group">
              <motion.h3
                animate={{ opacity: open ? 1 : 0, height: open ? 'auto' : 0 }}
              >मदत</motion.h3>
              {SidebarData2.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setTestname(item.heading2);
                      location.pathname = item.pathname;
                      if (item.pathname === "/extra") {
                        setHeadname("इतर सुविधा");
                      }
                      else if (item.pathname === "/help") {
                        setHeadname("मदत");
                      }
                    }}
                  >
                    <Items icon={<item.icon />} name={item.heading2} />

                  </div>
                );
              })}
            </div>

            <div className="group">
              <motion.h3
                animate={{ opacity: open ? 1 : 0, height: open ? 'auto' : 0 }}
              >रजिस्ट्रेशन</motion.h3>
              {SidebarData3.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setTestname(item.heading2);
                      location.pathname = item.pathname;
                      if (item.pathname === "/regi") {
                        setHeadname("रजिस्ट्रेशन");
                      }
                    }}
                  >
                    <Items icon={<item.icon />} name={item.heading2} />
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
      </motion.div>

      {location.pathname === '/Dashboard' ?
        <Dashboard setProgress={props.setProgress} open={open} /> :
        location.pathname === '/Transections' ?
          <Transections setProgress={props.setProgress} open={open} /> :
          location.pathname === '/ivouchers' ?
            <Other setProgress={props.setProgress} open={open} /> :
            location.pathname === '/reports' ?
              <Reports setProgress={props.setProgress} open={open} /> :
              location.pathname === '/extra' ?
                <Extra setProgress={props.setProgress} open={open} /> :
                location.pathname === '/help' ?
                  <Help setProgress={props.setProgress} open={open} /> :
                  location.pathname === '/regi' ?
                    <Registration setProgress={props.setProgress} open={open} /> :
                    location.pathname === '/idairygrow' ?
                    <Idairygrow setProgress={props.setProgress} open={open} />
                    : <Dashboard setProgress={props.setProgress} open={open} />}
    </div>
  )
}
export default Sidebar

