import React from 'react'
import './Items.css'
import { motion } from 'framer-motion'

const Items = (props) => {

    const subheading = {
        true: {
            opacity: 1
        },
        false: {
            opacity: 0,
            display: 'none'
        }
    }

    return (
        <motion.div
            whileHover={{
                backgroundColor: "rgba(255,255,255,0.3)",
                backdrpFilter: "blur(3.5px)",
                boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                WebkitBackdropFilter: "blur(3.5px)",
                border: "1px solid rgba(255,255,255,0.18)",
                cursor: "pointer",
                borderRadius: "10px",
                border: "1px solid rgba( 255, 255, 255, 0.18 )",
            }}
            transition={{
                type: 'none', duration: 0.1
            }}
            className='item'>
            <motion.div className="icon">
                {props.icon}
            </motion.div>
            <motion.span
                className='pointname'
                variants={subheading}
            >
                {props.name}
            </motion.span>
        </motion.div>
    )
}

export default Items
