import React, { useEffect, useRef, useState } from 'react'
import './Thevvatap.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { Co2Sharp, Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'

const Thevvatap = (props) => {
    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10) });
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [DataSource, setDataSource] = useState([]);
    const [DataSource2, setDataSource2] = useState([]);
    const refdelete = useRef(null);
    const [wait, setWait] = useState(false);
    const refdeleteclose = useRef(null);

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));
    }

    const handledeleteclose = () => {
        refdeleteclose.current.click();
    }

    useEffect(() => {
        getthevmaxdate();
    }, [])

    const handleDelete = () => {
        refdelete.current.click();
    }

    const getthevmaxdate = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);

        let rawData = {
            mainuser: localStorage.getItem("contact"),
            mainuser2:mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/getthevmaxdate.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
           

            if (response.data && response.data.length > 0) {
                setDataSource2(response.data);
            }


        }).catch(err => {
            console.log(err);
        });
    }

    const deletevatap = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            mainuser: localStorage.getItem("contact"),
            date: DataSource2[0].thevvatapdate,
            date1: DataSource2[0].date1,
            date2: DataSource2[0].date2,
            mainuser2:mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/deletevatap.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
           

            if (response.data && response.data === "Deleted") {
                notify("ठेव वाटप डिलीट झाले!");
                setDataSource2([]);
                getthevmaxdate();
                handledeleteclose();
            }


        }).catch(err => {
            console.log(err);
        });
    }

    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
            const url = "/thevvatapprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे बील उपलब्ध नाही!");
        }
    }

    useEffect(() => {
        const currentDate1 = new Date(input.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
            lastDayOfMonth1 = 10;
        } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
            lastDayOfMonth1 = 20;
        } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
            lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));
        }
        // setDataSource([]);
        // setTableFilter([]);
    }, [input.date1]);

    const getthevreport = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            mainuser: localStorage.getItem("contact"),
            mainuser2:mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/getthevreport.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (response.data === "not found") {
                notifyerror("रजिस्टर मध्ये बील सापडले नाही!");
                setDataSource([]);
                setWait(false);
                return;
            }
            if (response.data !== "not found") {
               // console.log(response.data);
                if (response.data && response.data.length > 0) {
                    setDataSource(response.data);
                    setWait(false);
                }
                else {
                    notifyerror("पुन्हा प्रयत्न करा.");
                    setWait(false);
                }
            }
            else {
                notifyerror("रजिस्टर मध्ये दिनांक सापडली नाही! \n(सूचना- दिनांक १०,२० किंवा महिन्याची शेवटची दिनांक असावी)")
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    const thevvatap = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (DataSource && DataSource.length > 0) {
            let rawData = {
                date1: input.date1,
                date2: input.date2,
                mainuser: localStorage.getItem("contact"),
                mainuser2:mainuser2,
            }
            rawData = JSON.stringify(rawData)
            let formData = new FormData()
            formData.append('data', rawData)

            axios.post('https://idairy.co.in/dairy/php/cust/thevvatap.php', formData, {
                'Content-Type': 'multipart/form-data',

            }).then(function sample(response) {

                if (response.data.trim() === "Updated.") {
                    notify("ठेव वाटप पूर्ण झाले!");
                    getthevmaxdate();
                }
            }).catch(err => {
                console.log(err);
            });
        }
        else {
            notifyerror("बील दिनांक सिलेक्ट करा!");
        }
    }
    function formatDate(inputDate) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(inputDate).toLocaleDateString(undefined, options);
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refdelete}
                data-bs-target="#deletethev"
            >
            </button>

            <div className="modal fade" id="deletethev" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">ठेव वाटप डिलीट करायचे?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={deletevatap}>होय</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmainbill">
                        <div className="firstrowbill">
                            <div className="rowbill">
                                <div className="mb-2">
                                    <span className='subheadsank'>ठेव वाटप अखेर बील</span>
                                    <input type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>.</span>
                                    <input type="date" disabled className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                </div>

                                <div className="mb-2">
                                    {
                                     wait ?
                                     <button type="submit" id='show' className="btn btn-primary mx-1 sanksmall">Loading..</button>    
                                     :
                                        <button onClick={getthevreport} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>

                                    }
                                    <button onClick={showprint} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                                    <button onClick={thevvatap} type="submit" id='show' className="btn btn-success mx-1 sanksmall">या दिनांका पर्यंत ठेव वाटप करा.</button>

                                </div>
                                <div className={DataSource2 && DataSource2.length > 0 && DataSource2[0].thevvatapdate !== null ? "lblthev" : ""}>
                                    <span className='subheadthev'>{
                                        DataSource2 && DataSource2.length > 0 ?
                                            DataSource2[0].thevvatapdate !== null ? "मागील ठेव वाटप दिनांक: " + formatDate(DataSource2[0].thevvatapdate) : "" : ""
                                    }</span>
                                    <span className='subheadthev'>{
                                        DataSource2 && DataSource2.length > 0 && DataSource2[0].date1 !== null && DataSource2[0].date2 !== null ?
                                            "वाटप बील अखेर: " + formatDate(DataSource2[0].date1) + " ते " + formatDate(DataSource2[0].date2) : ""
                                    }</span>
                                    <UilTrash onClick={handleDelete} className={DataSource2 && DataSource2.length > 0 && DataSource2[0].thevvatapdate !== null ? "ic2" : "ic2none"} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="firstrow2">
                    <div class="table-containerthev">
                        <table id="myTablebill" className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className='thsmall' scope="col">खाते क्रमांक</th>
                                    <th className='thlarge' scope="col">नाव</th>
                                    <th className='thsmall' scope="col">एकूण ठेव रक्कम</th>

                                </tr>
                            </thead>
                            <tbody>
                                {DataSource && DataSource.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmall'>{user.kno}</td>
                                        <td className='thsmall'>{user.cname}</td>
                                        <td className='thsmall2'>{user.tthev && user.tthev != null ? parseFloat(user.tthev).toFixed(2) : 0}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                            <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                <td className='thsmallcust'>एकूण</td>
                                <td className='thsmallcust'>-</td>
                                <td className='thsmallcust'>{DataSource.reduce((total, user) => total + (user.tthev && user.tthev != null ? parseFloat(user.tthev) : 0), 0).toFixed(2)}</td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Thevvatap
