import React, { useState } from "react";
import Globalcontext from "./Globalcontext";

const Globalstates = (props) => {

    const [submenu, setsubmenu] = useState("");
    const [newcustsource, setNewcustsource] = useState("");
    const [sanksource, setSanksource] = useState("");
    const [kendrasource, setKendrasource] = useState("");
    const [headname, setHeadname] = useState("डॅशबोर्ड");
    const [custlist, setCustlist] = useState([]);
    const [customerData, setCustomerData] = useState({});

    return (
        <Globalcontext.Provider value={{ submenu,setsubmenu,newcustsource, setNewcustsource,sanksource, setSanksource,kendrasource,setKendrasource,headname,setHeadname,custlist,setCustlist,customerData,setCustomerData}}>

            {props.children}

        </Globalcontext.Provider>
    )
}

export default Globalstates;